import React, { useState, useMemo, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import $ from "jquery";
import { baseUrl } from "../../common/common";
import Loader from "react-js-loader";
import '../../common/components/css/style.css';
import axios from 'axios';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Dropdown } from 'flowbite-react';
import "../../index.css";
import { HiCheck } from "react-icons/hi";
import imgEdit from "./images/Edit.png";
import imgDelete from "./images/trash-2.png";
import Snackbar from '@mui/material/Snackbar';
import ListItemIcon from '@mui/material/ListItemIcon';
import SuccessIcon from "./images/success.png";
import ErrorIcon from "./images/error.png";
import SplImg from "./images/spl.png";
import gift from "./images/gift.svg";
import upar from "./images/upar.svg";
import ques from "./images/ques.svg";
import ImportComponent from "../Import/Import";
import CustomToolbar from './ToolbarComponent';
import { WindmillSpinner } from 'react-spinner-overlay'

export default function StudentCalendar() {

    const navigate = useNavigate();

    const [programsData, setProgramsData] = useState([]);
    const [programsLoading, setProgramsLoading] = useState(false);

    const fetchProgramsData = async () => {
        setProgramsLoading(false);
        try {
            const postData = {
                school_id: localStorage.getItem('schoolId')
                // school_id:24
            }
            const { data } = await axios.post(baseUrl + "/api/get_programs", postData, {
                headers: {
                    "Authorization": localStorage.getItem('access_token')
                }
            });
            setProgramsData(data.data);
            setProgramsLoading(true);
        } catch (error) {
            console.log(error)
        }
    }

    const localizer = momentLocalizer(moment);

    const [program, setProgram] = useState("");
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [category, setCategory] = useState("");
    const [categoryData, setCategoryData] = useState([]);
    const selectedCategory = categoryData.find(data => data.id === category);
    const fetchCategoryData = async () => {
        axios({
            method: "post",
            url: baseUrl + "/api/get_event_category",
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setCategoryData(response.data.data);
                console.log(response);
            }
        });
    }

    const [foodTimeData, setFoodTimeData] = useState([]);
    const selectedStartTime = foodTimeData.find(data => data.id === startTime);
    const selectedEndTime = foodTimeData.find(data => data.id === endTime);
    const fetchFoodTimeData = async () => {
        axios({
            method: "post",
            url: baseUrl + "/api/get_time",
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setFoodTimeData(response.data.data);
                console.log(response);
            }
        });
    }

    const selectedProgram = programsData.find(data => data.id === program);

    const events1 = [
        {
            title: 'Children Day',
            start: new Date(2024, 5, 1, 14, 30), // May 1, 2024 2:30 PM (local time)
            end: new Date(2024, 5, 1, 16, 0),   // May 1, 2024 4:00 PM (local time)
        },
    ];

    const event1 = [
        // {
        //     title: 'My Event',
        //     start: new Date('2024-05-30T18:30:00.000Z'),
        //     end: new Date('2024-05-30T19:30:00.000Z')
        // },
        {
            title: 'Children Day',
            start: new Date(2024, 5, 1, 14, 30), // Apr 1, 2024 2:00 PM
            end: new Date(2024, 5, 1, 16, 0),   // Apr 1, 2024 4:00 PM
        },
        // {
        //     title: 'Fancy Dress Competition For Kids',
        //     start: new Date(2024, 3, 7, 10, 0), // Apr 7, 2024 10:00 AM
        //     end: new Date(2024, 3, 7, 12, 0),   // Apr 7, 2024 12:00 PM
        // },
        // {
        //     title: 'World Sleep Day - Overnight Camping',
        //     start: new Date(2024, 3, 18, 0, 0),  // Apr 18, 2024 12:00 AM
        //     end: new Date(2024, 3, 18, 17, 0),   // Apr 18, 2024 5:00 PM
        // },
        // {
        //     title: 'Annual Day',
        //     start: new Date(2024, 3, 22, 0, 0),  // Apr 22, 2024 12:00 AM
        //     end: new Date(2024, 3, 22, 17, 0),   // Apr 22, 2024 5:00 PM
        // },
        // {
        //     title: 'Art Attack',
        //     start: new Date(2024, 4, 1, 14, 0),  // May 1, 2024 2:00 PM
        //     end: new Date(2024, 4, 1, 16, 0),    // May 1, 2024 4:00 PM
        // }
    ];

    const [events, setEvents] = useState([]);
    const [showEventPopup, setShowEventPopup] = useState(false);
    const [showAddEventPopup, setShowAddEventPopup] = useState(false);
    const [newEvent, setNewEvent] = useState({
        id: '',
        title: '',
        start: '',
        end: '',
        startTime: '',
        endTime: '',
        program: '',
        category: '',
        notify: true,
        description: ''
    });

    const [currentView, setCurrentView] = useState('month');
    const [addUpdateStatus, setAddUpdateStatus] = useState('');

    const handleViewChange = (view) => {
        setCurrentView(view);
    };

    const handleSelectSlot = ({ start, end }) => {
        setEndTimeError(null)
        setStartTimeError(null)
        setShowEventPopup(false);
        setAddUpdateStatus("add")
        setCategory("")
        setEndTime("")
        setStartTime("")
        setProgram("")
        const formatDate = (date) => {
            return date.toLocaleDateString('en-GB', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            }).split('/').reverse().join('-');
        };
        const adjustedEnd = new Date(end);
        adjustedEnd.setDate(adjustedEnd.getDate() - 1);
        const newEvent = {
            id: '',
            title: '',
            start: formatDate(start),
            end: currentView === 'month' ? formatDate(adjustedEnd) : formatDate(end),
            startTime: '',
            endTime: '',
            program: '',
            category: '',
            notify: true,
            description: ''
        };
        setNewEvent(newEvent);
        setShowAddEventPopup(true);
    };

    const [titleError, setTitleError] = useState(null);
    const [categoryError, setCategoryError] = useState(null);
    const [startTimeError, setStartTimeError] = useState(null);
    const [endTimeError, setEndTimeError] = useState(null);
    const [programError, setProgramError] = useState(null);

    const handleAddEvent = () => {
        let hasError = false;

        if (newEvent.title === "") {
            setTitleError("Please Enter Event Name");
            hasError = true;
        } else {
            setTitleError(null);
        }
        // if (!category) {
        //     setCategoryError("Please Select a Category");
        //     hasError = true;
        // } else {
        //     setCategoryError(null);
        // }
        if (startTime === "") {
            setStartTimeError("Please Select Start Time");
            hasError = true;
        } else {
            setStartTimeError(null);
        }
        if (endTime === "") {
            setEndTimeError("Please Select End Time");
            hasError = true;
        } else {
            setEndTimeError(null);
        }

        if (hasError) return;

        const formattedStartDate = `${newEvent.start} ${selectedStartTime ? selectedStartTime.time_name : '00:00'}`;
        const formattedEndDate = `${newEvent.end} ${selectedEndTime ? selectedEndTime.time_name : '00:00'}`;

        const postData = {
            school_id: localStorage.getItem('schoolId'),
            event_type: 1,
            programs_id: localStorage.getItem('programId'),
            start_date: formattedStartDate,
            end_date: formattedEndDate,
            description: newEvent.description,
            title: newEvent.title,
            from_time_id: startTime,
            to_time_id: endTime,
            calendar_status: 0,
            status: 1
        };

        if (newEvent.id && newEvent.id !== "") {
            postData.id = newEvent.id;
            postData.updated_by = localStorage.getItem('loginUserId');
        } else {
            postData.created_by = localStorage.getItem('loginUserId');
        }
        setTitleError(null);
        setStartTimeError(null);
        setEndTimeError(null);
        console.log(postData);
        axios({
            method: "post",
            url: `${baseUrl}/api/timetable_add_edit`,
            data: postData,
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setShowAddEventPopup(false);
                setSnackbarMessage(response.data.message);
                setMessageType('success');
                handleSnackbarOpen();
                fetchCalendarData();
            } else {
                setSnackbarMessage(response.data.message);
                setMessageType('error');
                handleSnackbarOpen();
            }
        }).catch((error) => {
            console.error("There was an error adding/updating the event!", error);
        });
    };

    const handleDeleteEvent = (id) => {
        axios({
            method: "post",
            url: `${baseUrl}/api/timetable_delete`,
            data: {
                id: id,
                updated_by: localStorage.getItem('loginUserId')
            },
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setSnackbarMessage(response.data.message);
                setMessageType('success');
                handleSnackbarOpen();
                fetchCalendarData();
            } else {
                setSnackbarMessage(response.data.message);
                setMessageType('error');
                handleSnackbarOpen();
            }
        }).catch((error) => {
            console.error("There was an error adding/updating the event!", error);
        });
    }

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        setNewEvent(prevEvent => ({
            ...prevEvent,
            [name]: newValue
        }));
    };

    const handleSelectEvent = (event) => {
        setEndTimeError(null)
        setStartTimeError(null)
        setShowAddEventPopup(false)
        setAddUpdateStatus("update")
        setNewEvent({});
        console.log(event)
        // const {start, end } = event;
        setCategory(event.event_type)
        setEndTime(event.to_time_id)
        setStartTime(event.from_time_id)
        setProgram(event.program_id)
        const formatDate = (date) => {
            return date.toLocaleDateString('en-GB', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            }).split('/').reverse().join('-');
        };
        const newEvent = {
            id: event.id,
            title: event.title,
            start: formatDate(event.start),
            end: formatDate(event.end),
            startTime: event.from_time_id,
            endTime: event.to_time_id,
            program: event.program_id,
            category: event.event_type,
            notify: true,
            description: event.description,
            title: event.title,
        };
        setNewEvent(newEvent);
        setShowEventPopup(true);
        // setShowAddEventPopup(true);
    };

    const [calendarData, setCalendarData] = useState([]);
    const [calendarLoading, setCalendarLoading] = useState(false);

    const fetchCalendarData = async () => {
        setCalendarLoading(false);
        try {
            const postData = {
                school_id: localStorage.getItem('schoolId'),
                programs_id: localStorage.getItem('programId')
            };
            const { data } = await axios.post(baseUrl + "/api/get_timetable", postData, {
                headers: {
                    "Authorization": localStorage.getItem('access_token')
                }
            });
            setCalendarData(data.data)
            const fetchedEvents = data.data.map(event => ({
                id: event.id,
                title: event.title,
                event_type: event.event_type,
                program_id: event.programs_id,
                from_time_id: event.from_time_id,
                to_time_id: event.to_time_id,
                description: event.description,
                title: event.title,
                start: new Date(...event.start_date),
                end: new Date(...event.end_date)
            })
            );
            setEvents(fetchedEvents);
        } catch (error) {
            console.log(error);
        } finally {
            setCalendarLoading(true);
        }
    };


    // ------11-06----------------
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [snackbarState, setSnackbarState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center'
    });
    const handleSnackbarClose = () => {
        setSnackbarState({ ...snackbarState, open: false });
    };
    const handleSnackbarOpen = () => {
        setSnackbarState({ ...snackbarState, open: true });
    };
    useEffect(() => { }, [snackbarMessage, messageType]);

    const Event = ({ event }) => {
        const startTime = moment(event.start).format('h:mm A');
        const endTime = moment(event.end).format('h:mm A');
        return (
            <span>
                <strong>{event.title}</strong><br /><br />
                {currentView === 'month' ? `${startTime} - ${endTime}` : null}
            </span>
        );
    };

    // -------------------------19-06-------------------------

    const handleOpenNewProgramPopupVisible = () => {
        setProgramNameError(null);
        setProgramTeacherError(null);
        setTimeError(null);
        setTeacher("");
        setProgramStartTime("");
        setProgramEndTime("");
        setNewProgramPopupVisible(true);
    }


    const [newProgramPopupVisible, setNewProgramPopupVisible] = useState(false);
    const [programStartTime, setProgramStartTime] = useState("");
    const [programEndTime, setProgramEndTime] = useState("");
    const [programTeacher, setTeacher] = useState("");
    const [timeData, setTimeData] = useState([]);
    const [teachersData, setTeachersData] = useState([]);
    const selectedPStartTime = timeData.find(data => data.id === programStartTime);
    const selectedPEndTime = timeData.find(data => data.id === programEndTime);
    const selectedTeachers = teachersData.find(data => data.id === programTeacher);

    const fetchProgramTimeData = async () => {
        axios({
            method: "post",
            url: baseUrl + "/api/get_time",
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setTimeData(response.data.data);
                console.log(response);
            }
        });
    }

    const fetchTeachersData = async () => {
        axios({
            method: "post",
            url: baseUrl + "/api/teacher_list",
            data: {
                school_id: localStorage.getItem("schoolId")
            },
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setTeachersData(response.data.data);
                console.log(response);
            }
        });
    }

    const [newProgramName, setNewProgramName] = useState('');
    const [features, setFeatures] = useState({
        qrCode: { status: true, name: 'QR Code', value: 2 },
        attendance: { status: true, name: 'Attendance', value: 5 },
        food: { status: true, name: 'Food', value: 3 },
        sleep: { status: true, name: 'Sleep', value: 4 },
        leaves: { status: true, name: 'Leaves', value: 6 },
        busTracking: { status: false, name: 'Bus Tracking', value: 7 },
        cctv: { status: false, name: 'CCTV', value: 8 },
        fees: { status: false, name: 'Fees', value: 9 },
    });
    const [additionalInfo, setAdditionalInfo] = useState({
        inviteParents: {
            status: true,
            name: 'Automatically Invite Parents as soon as student profile created',
            value: 1
        },
        addParentChat: {
            status: true,
            name: 'Add Parent in chat as soon as student profile created',
            value: 2
        },
        createStudentID: {
            status: true,
            name: 'Automatically create Student ID as soon as profile created',
            value: 3
        },
    });


    const handleFeatureChange = (feature) => {
        setFeatures({
            ...features,
            [feature]: {
                ...features[feature],
                status: !features[feature].status,
            },
        });
    };
    const handleAdditionalInfoChange = (info) => {
        setAdditionalInfo({
            ...additionalInfo,
            [info]: {
                ...additionalInfo[info],
                status: !additionalInfo[info].status,
            },
        });
    };

    const [programeNameError, setProgramNameError] = useState(null);
    const [programeTeacherError, setProgramTeacherError] = useState(null);
    const [timeError, setTimeError] = useState(null);
    const validateProgramForm = () => {
        let valid = true;
        if (newProgramName === "") {
            setProgramNameError("Please enter program name");
            valid = false;
        } else {
            setProgramNameError(null);
        }
        if (programTeacher === "") {
            setProgramTeacherError("Please select program teacher");
            valid = false;
        } else {
            setProgramTeacherError(null);
        }
        // if (programStartTime === "" || programEndTime === "") {
        //   setTimeError("Please select start and end time");
        //   valid = false;
        // } else {
        //   setTimeError(null);
        // }
        return valid;
    };

    const handleCreateProgram = () => {

        const selectedFeatureValues = Object.keys(features).filter((key) => features[key].status).map((key) => features[key].value);
        const selectedAdditionalInfoValues = Object.keys(additionalInfo).filter((key) => additionalInfo[key].status).map((key) => additionalInfo[key].value);

        console.log({
            school_id: localStorage.getItem("schoolId"),
            program_name: newProgramName,
            teacher_id: programTeacher,
            from_time_id: programStartTime,
            to_time_id: programEndTime,
            features: selectedFeatureValues.join(","),
            additional_info: selectedAdditionalInfoValues.join(","),
            status: 1,
            created_by: localStorage.getItem("loginUserId")
        });
        if (validateProgramForm()) {
            try {
                axios({
                    method: "post",
                    url: baseUrl + "/api/programs_add_edit",
                    data: {
                        school_id: localStorage.getItem("schoolId"),
                        program_name: newProgramName,
                        teacher_id: programTeacher,
                        from_time_id: programStartTime,
                        to_time_id: programEndTime,
                        features: selectedFeatureValues.join(","),
                        additional_info: selectedAdditionalInfoValues.join(","),
                        status: 1,
                        created_by: localStorage.getItem("loginUserId")
                    },
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": localStorage.getItem('access_token')
                    },
                }).then((response) => {
                    console.log(response);
                    if (response.data.status === true) {
                        $('.overlay').removeClass('visible');
                        $('.popup-program-container').removeClass('visible');
                        setNewProgramPopupVisible(false);
                        fetchProgramsData();
                        setSnackbarMessage(response.data.message);
                        setMessageType('success');
                        handleSnackbarOpen();
                    } else {
                        setSnackbarMessage(response.data.message);
                        setMessageType('error');
                        handleSnackbarOpen();
                    }
                });
            } catch (error) {
                console.log(error)
            }
        }

    };

    const [progress, setProgress] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [importstatus, setimportstatus] = useState(false);

    const importStudents1 = () => {
        setimportstatus(true);
    };

    const handleImportStatus = (status) => {
        if (status == true) {
            setimportstatus(false);
        }
    };
    useEffect(() => {
        fetchCategoryData();
        fetchFoodTimeData();
        fetchProgramsData();
        fetchTeachersData();
        fetchProgramTimeData();
        fetchCalendarData();
    }, [])

    const customDayPropGetter = date => {
        // Get today's date
        const today = new Date();

        // Compare if the current date being rendered is today's date
        if (
            date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()
        ) {
            return {
                style: {
                    backgroundColor: 'white', // Set your desired background color
                    color: 'black', // Set text color if needed
                    // Add any other styles as needed
                },
            };
        }

        // Default styling for other dates
        return {};
    };

    return (
        <section className="staff_atten_cus">
            <Snackbar
                anchorOrigin={{ vertical: snackbarState.vertical, horizontal: snackbarState.horizontal }}
                open={snackbarState.open}
                onClose={handleSnackbarClose}
                message={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ListItemIcon>
                            <img src={messageType === 'success' ? SuccessIcon : ErrorIcon} alt="Success" style={{ width: '24px', height: '24px', marginRight: '8px' }} />
                        </ListItemIcon>
                        <div className="success_cuc">
                            <p> {snackbarMessage} </p>
                        </div>
                    </div>
                }
                ContentProps={{
                    sx: {
                        border: "1px solid",
                        opacity: "1",
                        background: messageType === 'success' ? "#40BE61" : "#FD4954",
                        borderColor: messageType === 'success' ? "#40BE61" : "#FD4954",
                        width: '415px'
                    }
                }}
                autoHideDuration={3000}
            />
            <div className="outer-container">
                <div className="overlay" />
                <div id="left-panel" className="sp-left-panel">
                    <div className="sp-top-band">
                        <a className="sp-logo">
                            Scool Panda<sup>Beta</sup>
                        </a>
                        {/* <div className="sp-search-top flexc">
                            <i className="icon-Search" />
                            <input
                                id="search"
                                className="sp-search-btn"
                                placeholder="Quick search..."
                            />
                        </div> */}
                    </div>
                    <div className="sp-middle-band">
                        <div className="sp-nav y-auto">
                            <div className="">
                                <h4 className="sp-title">Quick Actions</h4>
                                <ul>
                                    <li onClick={() => { navigate('/dashboard'); }}>
                                        <span>
                                            <i className="icon-Dashboard-Outline" />
                                            Dashboard
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/activity'); }}>
                                        <span>
                                            <i className="icon-Activity-Outline" />
                                            Activity
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/calendar'); }}>
                                        <span>
                                            <i className="icon-Timetable-Outline" />
                                            Calendar
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/staff_list'); }}>
                                        <span>
                                            <i className="icon-Staff-Outline" />
                                            Staff
                                        </span>
                                    </li>

                                </ul>
                                <h4 className="sp-title flexc justifySp" onClick={() => handleOpenNewProgramPopupVisible()}>
                                    Programs{" "}
                                    <span id="add-programs" className="sp-plus">
                                        +
                                    </span>
                                </h4>
                                <ul>
                                    {programsLoading && programsData && programsData.length > 0 ?
                                        programsData.map((data, index) => {
                                            return (
                                                <li className={data.id === parseInt(localStorage.getItem('programId')) ? "selected" : ""} onClick={() => {
                                                    localStorage.setItem('programId', data.id);
                                                    localStorage.setItem('programName', data.program_name);
                                                    fetchCalendarData();
                                                    navigate('/student_timetable');
                                                }}>
                                                    <span>
                                                        <i className="icon-Program-Outline-1" />
                                                        {data.program_name}
                                                    </span>
                                                </li>
                                            );
                                        }) :
                                        <div className="data-loader extracss_load">
                                            {/* <Loader
                                          type="spinner-cub"
                                          bgColor="#4D6EE3"
                                          color="#6B6F8C"
                                          title="Loading..."
                                          size={35}
                                        /> */}
                                            <WindmillSpinner loading={"loading"} color="#4d6ee3d6" size={30} />
                                            <p>Loading...</p>
                                        </div>
                                    }

                                </ul>
                            </div>
                        </div>
                        <div className="sp-bottom sp-nav">
                            <ul>
                                <li>
                                    <span>
                                        {/* <i className="icon-Import-Outline" /> */}
                                        <div className="noti_sdi">
                                            <img src={gift} alt="" style={{ height: "16px" }} />
                                            <p>Notifications</p>
                                        </div>

                                    </span>
                                </li>
                                <li>
                                    <span style={{ display: "block" }}>
                                        {/* <i className="icon-Invites-Outline" /> */}
                                        <div className="noti_tot">
                                            <div className="noti_sdi">
                                                <img src={upar} alt="" style={{ height: "18px", width: "18px" }} />
                                                <p>Upgrade Pro</p>
                                            </div>
                                            <img src={ques} alt="" style={{ height: "18px", width: "18px" }} />
                                        </div>
                                    </span>
                                </li>
                                <li onClick={() => navigate("/signout")}>
                                    <span>
                                        <i className="icon-Invites-Outline" />
                                        Log Out
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* my code */}
                <div className="sp-right-panel">
                    <div className="sp-top-bar flexc justifySp exta_pad_10">
                        <div className="ft-wt600 flexc pad_ic_ri">
                            <span className="selected-icon">
                                <i className="icon-Program-Outline-1" />
                            </span>
                            {localStorage.getItem('programName')}
                            <i className="icon-More-Icon" />
                        </div>
                        <div className="flexc">
                            <button className="sp-view-btn ft-wt500 flexc"
                                onClick={() => {
                                    importStudents1();
                                }}>
                                <i className="icon-Import-Outline" />
                                Import
                            </button>
                            <button className="createN ft-wt500 mr-lf10 space_icon_pad"><i class="fa-solid fa-plus"></i> New Student</button>
                        </div>
                    </div>
                    <div className="sp-header borderbt">
                        <nav className="flexc">
                            <div className="sp-list-view flexc" onClick={() => { navigate('/student_list'); }}>
                                <i className="icon-Students-Outline" />
                                Student List
                            </div>
                            <div className="sp-list-view flexc" onClick={() => { navigate('/student_attendance'); }}>
                                <i className="icon-Attendance-Outline" />
                                Attendance
                            </div>
                            <div className="sp-list-view flexc" onClick={() => { navigate('/food'); }}>
                                <i className="icon-Food" />
                                Food
                            </div>
                            <div className="sp-list-view flexc" onClick={() => { navigate('/sleep'); }}>
                                <i className="icon-Sleep" />
                                Sleep
                            </div>
                            <div className="sp-list-view flexc selected">
                                <i className="icon-Calendar-Outline" />
                                Timetable
                            </div>
                        </nav>
                    </div>
                    {importstatus ?
                        <ImportComponent
                            importStudent={"Student"}
                            handleImportStatus={handleImportStatus}
                            setProgress={setProgress} setIsLoading={setIsLoading}
                        /> :
                        <div className="he_fix_cus">
                            {calendarLoading ? (
                                <div className="sp-timetab-inr">
                                    <Calendar
                                        // localizer={localizer}
                                        // events={events}
                                        // onView={handleViewChange}
                                        // startAccessor="start"
                                        // endAccessor="end"
                                        // selectable
                                        // views={['month', 'week']}
                                        // components={{
                                        //     month: {
                                        //         event: Event,
                                        //     },
                                        //     week: {
                                        //         event: Event,
                                        //     }
                                        // }}
                                        // defaultView='month'
                                        // onSelectSlot={handleSelectSlot}
                                        // onSelectEvent={handleSelectEvent}
                                        localizer={localizer}
                                        events={events}
                                        onView={handleViewChange}
                                        startAccessor="start"
                                        endAccessor="end"
                                        selectable
                                        views={['month', 'week']}
                                        components={{
                                            month: {
                                                event: Event,
                                            },
                                            week: {
                                                event: Event,
                                            },
                                            toolbar: CustomToolbar,
                                        }}
                                        defaultView='week'
                                        dayPropGetter={customDayPropGetter}
                                        onSelectSlot={handleSelectSlot}
                                        onSelectEvent={handleSelectEvent}

                                    />
                                </div>
                            ) : <div className="data-loader extracss_load">
                                {/* <Loader
                                type="spinner-cub"
                                bgColor="#4D6EE3"
                                color="#6B6F8C"
                                title="Loading..."
                                size={35}
                                /> */}
                                <WindmillSpinner loading={"loading"} color="#4d6ee3d6" size={30} />
                                <p>Loading...</p>
                            </div>
                            }

                        </div>
                    }
                </div>
                {showAddEventPopup && (
                    <div className="dialog-model absolute" style={newEvent.start && { display: "block" }}>
                        <div className="sp-tt ft-14 flexc ft-wt600">{addUpdateStatus === 'add' ? "Create New" : "Update"} Event</div>
                        <div className="form-section-default flexc justifySp fwrap">
                            <div className="field-label fullsz-field error_mes_cus">
                                <label>Name</label>
                                <div className="field-value-holder margin_top10p">
                                    <input
                                        type="text"
                                        className="sp-field-input"
                                        placeholder="Type name.."
                                        name="title"
                                        value={newEvent.title}
                                        onChange={handleChange}
                                        style={{ fontSize: '13px', height: "40px" }}
                                    />
                                </div>
                                {titleError && <p className="error">{titleError}</p>}
                            </div>
                            {/* <div className="field-label fullsz-field">
                                <label>Category</label>
                                <div className="field-value-holder margin_top10p dropbutton" style={{ width: '100%' }}>
                                    <Dropdown
                                        label={<span style={{ color: 'black', whiteSpace: 'nowrap' }}>{selectedCategory ? selectedCategory.event_category_name : "Select Category"}</span>}
                                        style={{ width: '100%' }}
                                    >
                                        {categoryData.map(item => (
                                            <Dropdown.Item
                                                className={selectedCategory && selectedCategory.id === item.id ? "dd_select" : null}
                                                icon={selectedCategory && selectedCategory.id === item.id ? HiCheck : null}
                                                onClick={() => setCategory(item.id)}
                                                key={item.id}
                                            >
                                                {item.event_category_name}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown>
                                </div>
                            </div> */}
                            <div className="field-label half-field">
                                <label>Start Date</label>
                                <div className="field-value-holder margin_top10p flexc pos_icon_date_st">
                                    <input
                                        type="date"
                                        className="sp-field-input"
                                        placeholder="Start Date"
                                        name="start"
                                        value={moment(newEvent.start).format('YYYY-MM-DD')}
                                        onChange={handleChange}
                                        readOnly
                                        style={{ fontSize: '13px', fontWeight: "600", height: "40px" }}
                                    />
                                    <i class="fa-regular fa-clock"></i>
                                </div>
                            </div>
                            <div className="field-label half-field error_mes_cus">
                                <label>Start Time</label>
                                <div className="field-value-holder margin_top10p flexc">
                                    <Dropdown
                                        label={
                                            <span
                                                className={`${selectedStartTime ? 'selected-item' : ''}`}
                                                style={{
                                                    fontSize: "13px",
                                                    color: selectedStartTime ? '#1B252F' : '#A9AFC3'
                                                }}
                                            >
                                                {selectedStartTime ? selectedStartTime.time_name : "Select a Time"}
                                            </span>
                                        }
                                        // label={<span style={{ color: 'black' }}>{selectedStartTime ? selectedStartTime.time_name : "Select Time"}</span>}
                                        style={{ width: '100%' }}
                                    >
                                        {foodTimeData.map(item => (
                                            <Dropdown.Item
                                                className={`${selectedStartTime && selectedStartTime.id === item.id ? "selected-item dd_select" : ""}`}
                                                // className={selectedStartTime && selectedStartTime.id === item.id ? "dd_select" : null}
                                                icon={selectedStartTime && selectedStartTime.id === item.id ? HiCheck : null}
                                                onClick={() => setStartTime(item.id)}
                                                key={item.id}
                                            >
                                                {item.time_name}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown>
                                </div>
                                {startTimeError && <p className="error">{startTimeError}</p>}
                            </div>
                            <div className="field-label half-field">
                                <label>End Date</label>
                                <div className="field-value-holder margin_top10p flexc pos_icon_date_st">
                                    <input
                                        type="date"
                                        className="sp-field-input"
                                        placeholder="End Date"
                                        name="end"
                                        value={moment(newEvent.end).format('YYYY-MM-DD')}
                                        onChange={handleChange}
                                        readOnly
                                        style={{ fontSize: '13px', fontWeight: "600", height: "40px" }}
                                    />
                                    <i class="fa-regular fa-clock"></i>
                                </div>
                            </div>
                            <div className="field-label half-field error_mes_cus">
                                <label>End Time</label>
                                <div className="field-value-holder margin_top10p flexc">
                                    <Dropdown
                                        label={
                                            <span
                                                className={`${selectedEndTime ? 'selected-item' : ''}`}
                                                style={{
                                                    fontSize: "13px",
                                                    color: selectedEndTime ? '#1B252F' : '#A9AFC3'
                                                }}
                                            >
                                                {selectedEndTime ? selectedEndTime.time_name : "Select a Time"}
                                            </span>
                                        }
                                        // label={<span style={{ color: 'black' }}>{selectedEndTime ? selectedEndTime.time_name : "Select Time"}</span>}
                                        style={{ width: '100%', height: "40px", }}
                                    >
                                        {foodTimeData.map(item => (
                                            <Dropdown.Item
                                                className={`${selectedEndTime && selectedEndTime.id === item.id ? "selected-item dd_select" : ""}`}
                                                // className={selectedEndTime && selectedEndTime.id === item.id ? "dd_select" : null}
                                                icon={selectedEndTime && selectedEndTime.id === item.id ? HiCheck : null}
                                                onClick={() => setEndTime(item.id)}
                                                key={item.id}
                                            >
                                                {item.time_name}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown>
                                </div>
                                {endTimeError && <p className="error">{endTimeError}</p>}
                            </div>

                            <div className="field-label fullsz-field">
                                <label>Programs</label>
                                <div className="field-value-holder margin_top10p flexc">
                                    <input
                                        type="text"
                                        className="sp-field-input"
                                        placeholder="program"
                                        name="program"
                                        value={localStorage.getItem('programName')}
                                        readOnly
                                        style={{ fontSize: '13px', fontWeight: "600", color: "#1B252F", height: "40px" }}
                                    />
                                </div>
                            </div>
                            {/* <div className="notify-cont flexc">
                                <label className="label-container">
                                    <input
                                        type="checkbox"
                                        defaultChecked={newEvent.notify}
                                        name="notify"
                                        onChange={handleChange}
                                    />
                                    <span className="tick-ele" />
                                </label>
                                <span className="ft-13">
                                    Notify Staff and Parents in selected programs
                                </span>
                            </div> */}
                            <div className="field-label full-field">
                                <label>Description</label>
                                <div className="field-value-holder margin_top10p">
                                    <textarea
                                        className="sp-field-input"
                                        placeholder="Description"
                                        name="description"
                                        value={newEvent.description}
                                        onChange={handleChange}

                                    />
                                </div>
                            </div>
                            <div className="flexc mt-4">
                                <div className="flexc">
                                    <button className="createN ft-wt500 mr-2" onClick={handleAddEvent}>
                                        {addUpdateStatus === 'add' ? "Create" : "Update"}
                                    </button>
                                    <button
                                        className="sp-view-btn ft-wt500 flexc cancel-btn"
                                        onClick={() => { setTitleError(null); setShowAddEventPopup(false); }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {showEventPopup && (
                    <div className="dialog-model absolute edit_cus_style" style={{ display: "block" }}>
                        <div className="flexc justifySp">
                            <div className="sp-tt ft-14 ft-wt600 head_cus_cal">Event Information</div>
                            <div>
                                <span className="selected-icon" style={{ border: "unset", cursor: "pointer" }} onClick={() => { setShowEventPopup(false); setShowAddEventPopup(true) }}>
                                    <img
                                        src={imgEdit}
                                        className="mr-rt10"
                                        alt="avatar"
                                    />
                                    {/* <i className="icon-Timetable-Outline" /> */}
                                </span>
                                <span className="selected-icon" style={{ border: "unset", cursor: "pointer" }} onClick={() => { setShowEventPopup(false); handleDeleteEvent(newEvent.id) }}>
                                    <img
                                        src={imgDelete}
                                        className="mr-rt10"
                                        alt="avatar"
                                    />
                                    {/* <i className="icon-Timetable-Outline" /> */}
                                </span>
                            </div>
                        </div>
                        <div className="form-section-default flexc justifySp fwrap para_cus_sty ">
                            <div className="field-label full-field">
                                <label>Name</label>
                                <div className="sp-tt ft-14 flexc ft-wt600 mt-4 field_cus_cal_sty">{newEvent.title}</div>
                            </div>
                            <div className="field-label full-field">
                                <label>Category</label>
                                <div className="sp-tt ft-14 flexc ft-wt600 mt-4 field_cus_cal_sty">{categoryData.find(data => data.id === category).event_category_name}</div>
                            </div>
                            <div className="field-label half-field">
                                <label>Start Date</label>
                                <div className="sp-tt ft-14 flexc ft-wt600 mt-4 field_cus_cal_sty">{newEvent.start}</div>
                            </div>
                            <div className="field-label half-field">
                                <label>Start Time</label>
                                <div className="sp-tt ft-14 flexc ft-wt600 mt-4 field_cus_cal_sty">{foodTimeData.find(data => data.id === newEvent.startTime).time_name}</div>
                            </div>
                            <div className="field-label half-field">
                                <label>End Date</label>
                                <div className="sp-tt ft-14 flexc ft-wt600 mt-4 field_cus_cal_sty">{newEvent.end}</div>
                            </div>
                            <div className="field-label half-field">
                                <label>End Time</label>
                                <div className="sp-tt ft-14 flexc ft-wt600 mt-4 field_cus_cal_sty">{foodTimeData.find(data => data.id === newEvent.endTime).time_name}</div>
                            </div>
                            <div className="field-label full-field">
                                <label>Programs</label>
                                <div className="sp-tt ft-14 flexc ft-wt600 mt-4 field_cus_cal_sty all_pro_cus_sty"> <p> {newEvent.program === 0 ? "All Programs" : programsData.find(data => data.id === newEvent.program).program_name}</p> </div>
                            </div>
                            <div className="field-label full-field">
                                <label>Description</label>
                                <div className="sp-tt ft-14 flexc ft-wt600 mt-4 field_cus_cal_sty">{newEvent.description}</div>
                            </div>
                            <div className="flexc mt-4">
                                <button
                                    className="sp-view-btn ft-wt500 flexc cancel-btn"
                                    onClick={() => { setTitleError(null); setShowEventPopup(false); }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {/* Create New Program */}
                {
                    newProgramPopupVisible && (
                        <div className="popup-program-container">
                            <div className="popup-wrap">
                                <h2 className="ft-wt700">Create New Program</h2>
                                <div className="sp-pop-header prl_1">
                                    <div className="form-section-default flexc justifySp fwrap">
                                        <div className="field-label full-field error_mes_cus">
                                            <label>Program Name</label>
                                            <div className="field-value-holder margin_top10p">
                                                <input
                                                    className="sp-field-input"
                                                    type="text"
                                                    placeholder="Enter Program name"
                                                    value={newProgramName}
                                                    onChange={(e) => setNewProgramName(e.target.value)}
                                                />
                                            </div>
                                            {programeNameError && <p className="error">{programeNameError}</p>}
                                        </div>
                                        <div className="field-label half-field error_mes_cus">
                                            <label>Program Teachers</label>
                                            <div className="field-value-holder margin_top10p dropbutton" >
                                                <Dropdown


                                                    label={
                                                        <span
                                                            className={`${selectedTeachers ? 'selected-item' : ''}`}
                                                            style={{
                                                                fontSize: "13px",
                                                                justifyContent: "flex-start",
                                                                color: selectedTeachers ? '#1B252F' : '#A9AFC3'
                                                            }}
                                                        >
                                                            {selectedTeachers ? selectedTeachers.first_name : "Select Teacher"}</span>}
                                                    style={{ width: '100%' }}
                                                >

                                                    {teachersData.map(item => (
                                                        <Dropdown.Item
                                                            // className={selectedTeachers && selectedTeachers.id === item.id ? "dd_select" : null}
                                                            className={`${selectedTeachers && selectedTeachers.id === item.id ? "selected-item dd_select" : ""
                                                                }`}
                                                            icon={selectedTeachers && selectedTeachers.id === item.id ? HiCheck : null}
                                                            onClick={() => setTeacher(item.id)}
                                                            key={item.id}
                                                        >
                                                            {item.first_name}
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown>

                                            </div>
                                            {programeTeacherError && <p className="error">{programeTeacherError}</p>}
                                        </div>
                                        <div className="field-label half-field error_mes_cus">
                                            <label>Class Timing</label>
                                            <div className="flexc justifySp">
                                                <div className="half-field">
                                                    <div className="field-value-holder margin_top10p dropbutton cus_drop_siz" >
                                                        <Dropdown
                                                            label={


                                                                <span
                                                                    className={`${selectedStartTime ? 'selected-item' : ''}`}
                                                                    style={{
                                                                        fontSize: "12px",
                                                                        justifyContent: "flex-start",
                                                                        color: selectedStartTime ? '#1B252F' : '#A9AFC3'
                                                                    }}
                                                                >

                                                                    {/* <span style={{ color: 'black', fontSize: '12px' }}> */}



                                                                    <i class="fa-regular fa-clock" style={{ marginRight: '6PX' }}></i>
                                                                    {selectedStartTime ? selectedStartTime.time_name : "Start Time"}</span>}
                                                            style={{
                                                                width: "100%"
                                                            }}
                                                        >
                                                            {timeData.map(item => (
                                                                <Dropdown.Item

                                                                    //className={selectedStartTime && selectedStartTime.id === item.id ? "dd_select" : null}

                                                                    className={`${selectedStartTime && selectedStartTime.id === item.id ? "selected-item dd_select" : ""
                                                                        }`}
                                                                    icon={selectedStartTime && selectedStartTime.id === item.id ? HiCheck : null}
                                                                    onClick={() => setProgramStartTime(item.id)}
                                                                    key={item.id}
                                                                >
                                                                    {item.time_name}
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                <div className="half-field">
                                                    <div className="field-value-holder margin_top10p dropbutton cus_drop_siz" >
                                                        <Dropdown
                                                            label={


                                                                //  <span style={{ color: 'black', fontSize: '12px' }}>

                                                                <span
                                                                    className={`${selectedEndTime ? 'selected-item' : ''}`}
                                                                    style={{


                                                                        fontSize: "13px",
                                                                        fontWeight: "400",
                                                                        lineHeight: "18.2px",
                                                                        textAlign: "left",

                                                                        justifyContent: "flex-start",
                                                                        color: selectedEndTime ? '#1B252F' : '#A9AFC3'
                                                                    }}
                                                                >



                                                                    <i class="fa-regular fa-clock" style={{ marginRight: '6PX' }}>
                                                                    </i>{selectedEndTime ? selectedEndTime.time_name : "End Time"}</span>}
                                                            style={{
                                                                width: "100%"
                                                            }}
                                                        >
                                                            {timeData.map(item => (
                                                                <Dropdown.Item
                                                                    // className={selectedEndTime && selectedEndTime.id === item.id ? "dd_select" : null}
                                                                    className={`${selectedEndTime && selectedEndTime.id === item.id ? "selected-item dd_select" : ""
                                                                        }`}

                                                                    icon={selectedEndTime && selectedEndTime.id === item.id ? HiCheck : null}
                                                                    onClick={() => setProgramEndTime(item.id)}
                                                                    key={item.id}
                                                                >
                                                                    {item.time_name}
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            {timeError && <p className="error">{timeError}</p>}
                                        </div>
                                    </div>

                                    <h3 className="ft-wt600 mt-30">Customise Features</h3>
                                    <div className="full-field mb-6">
                                        {Object.keys(features).map((feature) => (
                                            <div className="flex items-center mb-4 custom-checkbox dis_chek_cus mt-5" key={feature}>
                                                <input
                                                    type="checkbox"
                                                    className="w-4 h-4 text-blue-600 rounded"
                                                    checked={features[feature].status}

                                                    disabled={
                                                        (['qrCode', 'attendance', 'food', 'sleep', 'leaves'].includes(feature))
                                                        // features[feature].status=== true
                                                    }
                                                    onChange={() => handleFeatureChange(feature)}
                                                />
                                                <label className="ms-2 text-sm">{features[feature].name}</label>
                                                {['busTracking', 'cctv', 'fees'].includes(feature) && <img src={SplImg} alt="spl" style={{ width: '25px', height: '15px', marginLeft: "5px" }} />}
                                            </div>
                                        ))}
                                    </div>
                                    <h3 className="ft-wt600 mt-30">Additional Informations</h3>
                                    <div className="full-field">
                                        {Object.keys(additionalInfo).map((info) => (
                                            <div className="flex items-center mb-4 custom-checkbox mt-5" key={info}>
                                                <input
                                                    type="checkbox"
                                                    className="w-4 h-4 text-blue-600 rounded"
                                                    checked={additionalInfo[info].status}
                                                    onChange={() => handleAdditionalInfoChange(info)}
                                                />
                                                <label className="ms-2 text-sm">{additionalInfo[info].name}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="sp-bottom-sticky">
                                    <button className="sp-btn-create crt_div" onClick={() => handleCreateProgram()}>Create Program</button>
                                    <button className="sp-btn-cancel">Cancel</button>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </section>
    );
}
