import React, { useState, useRef, useEffect } from "react";
import $ from "jquery";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Avatar1 from "../school/images/avatar1.png";
import Avatar2 from "../school/images/avatar2.png";
import Avatar3 from "../school/images/avatar3.png";
import Edit from "../school/images/edit.jpeg";
import "../../common/components/css/style1.css";
import { baseUrl } from "../../common/common";
import Avatarprofile from "../school/images/profile1.png";
import Avatardata from "../school/images/data-show.png";
import Loader from "react-js-loader";
import validator from "validator";
import { Dropdown } from "flowbite-react";
import "../../index.css";
import { HiCheck } from "react-icons/hi";
import { DatePicker, Space } from "antd";
import ImportComponent from "../Import/Import";
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import ListItemIcon from '@mui/material/ListItemIcon';
import SuccessIcon from "./images/success.png";
import ErrorIcon from "./images/error.png";
import SplImg from "./images/spl.png";
import dayjs from 'dayjs';
import recy from "./images/del.svg";
import de from "./images/de.svg";
import invite from "./images/invite.png";
import plus from "./images/pluss.svg";
import calc from "./images/Calendar.svg";
import gift from "./images/gift.svg";
import upar from "./images/upar.svg";
import ques from "./images/ques.svg";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

import { WindmillSpinner } from 'react-spinner-overlay'


export default function Student_list() {

  const location = useLocation();
  const navigate = useNavigate();
  const formData = new FormData();

  const gender1 = [
    {
      id: 1,
      value: "Male",
    },
    {
      id: 2,
      value: "Female",
    },
  ];

  const Blood1 = [
    {
      id: 1,
      value: "A+",
    },
    {
      id: 2,
      value: "A-",
    },
    {
      id: 3,
      value: "O+",
    },
    {
      id: 4,
      value: "B-",
    },
    {
      id: 5,
      value: "B+",
    },
    {
      id: 6,
      value: "B+",
    },
  ];

  const [user, setUser] = useState({
    school_id: localStorage.getItem("schoolId"),
    studentID: "",
    first_name: "",
    last_name: "",
    gender: "",
    programs_id: "",
    dob: "",
    joining_date: "",
    blood_group: "",
    address: "",
    father_name: "",
    mother_name: "",
    primary_email: "",
    secondary_email: "",
    primary_phone: "",
    secondary_phone: "",
    student_qr_code: "",
    status: 1,
    created_by: "",
    updated_by: "",
    profile_image: null,
  });

  const [displayUser, displaySetUser] = useState({
    id: "",
    school_id: localStorage.getItem("schoolId"),
    studentID: "",
    first_name: "",
    last_name: "",
    gender: "",
    programs_id: "",
    dob: "",
    joining_date: "",
    blood_group: "",
    address: "",
    father_name: "",
    mother_name: "",
    primary_email: "",
    secondary_email: "",
    primary_phone: "",
    secondary_phone: "",
    student_qr_code: "",
    status: 1,
    created_by: "",
    updated_by: "",
    profile_image: null,
  });

  const [selectedImage, setSelectedImage] = useState(null);
  const [importstatus, setimportstatus] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setUser({ ...user, profile_image: file });
    setSelectedImage(file);
  };

  const convertDBDateFormat = (dateString) => {
    const [day, month, year] = dateString.split('-');
    return `${year}-${month}-${day}`;
  };

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  const dateFormatList = 'DD-MM-YYYY';

  const ondobChange = (date, dateString) => {
    setUser({ ...user, dob: dateString });
    console.log(date, dateString);
  };

  const ondojChange = (date, dateString) => {
    setUser({ ...user, joining_date: dateString });
    console.log(date, dateString);
  }

  // ------------------------------
  const handleImportStatus = (status) => {
    if (status == true) {
      fetchStudentData(programId);
      setimportstatus(false);
    }
  };

  //------------------- import students -----------
  const [selectedImport, setSelectedImport] = useState("");
  const importStudents = (event) => {
    const files = event.target.files;
    //setUser({ ...user, profile_image: files[0] });
    const importFile = event.target.files[0];
    setimportstatus(true);

    axios({
      method: "post",
      url: baseUrl + "/api/student_import",
      data: {
        school_id: localStorage.getItem("schoolId"),
        programs_id: programId,
        file: importFile,
      },
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": localStorage.getItem('access_token')
      },
    }).then((response) => {
      console.log(response);
      if (response["data"]["status"] === true) {
        fetchStudentData(programId);
        console.log(response);
      }
    });
    //setSelectedImport(file);
  };

  const importStudents1 = () => {
    setimportstatus(true);
  };
  // ------------------------------

  const onSubmit = (ev) => {
    // ev.preventDefault();
    // Object.entries(user).forEach(([key, value]) => {
    //   formData.append(key, value);
    //   console.log(key, value);
    // });
    console.log("formData", formData)
    if (validateForm()) {
      console.log(user);
      axios({
        method: "post",
        url: baseUrl + "/api/student_add",
        data: {
          school_id: localStorage.getItem("schoolId"),
          first_name: user.first_name,
          last_name: "",
          gender: user.gender,
          dob: convertDBDateFormat(user.dob),
          programs_id: user.programs_id,
          joining_date: convertDBDateFormat(user.joining_date),
          blood_group: user.blood_group,
          address: user.address,
          father_name: user.father_name,
          mother_name: user.mother_name,
          primary_email: user.primary_email,
          secondary_email: user.secondary_email,
          primary_phone: user.primary_phone,
          secondary_phone: user.secondary_phone,
          profile_image: user.profile_image,
          student_qr_code: "",
          status: 1,
          created_by: localStorage.getItem('loginUserId'),
          studentID: user.studentID,
        },
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": localStorage.getItem('access_token')
        },
      }).then((response) => {
        console.log(response);
        if (response["data"]["status"] === true) {
          setSnackbarMessage(response.data.message);
          setMessageType('success');
          handleSnackbarOpen();
          displaySetUser({
            // school_id: 1,
            first_name: response["data"]["data"]["first_name"],
            //last_name: '',
            studentID: response["data"]["data"]["studentID"],
            gender: response["data"]["data"]["gender"],
            dob: response["data"]["data"]["dob"],
            joining_date: response["data"]["data"]["joining_date"],
            blood_group: response["data"]["data"]["blood_group"],
            address: response["data"]["data"]["address"],
            father_name: response["data"]["data"]["father_name"],
            mother_name: response["data"]["data"]["mother_name"],
            primary_email: response["data"]["data"]["primary_email"],
            secondary_email: response["data"]["data"]["secondary_email"],
            primary_phone: response["data"]["data"]["primary_phone"],
            secondary_phone: response["data"]["data"]["secondary_phone"],
            profile_image: response["data"]["data"]["profile_image"],
            programs_id: response["data"]["data"]["programs_id"],
          });
          $(".overlay").removeClass("visible");
          $(".popup-modal-container").removeClass("visible");
          setTimeout(function () {
            $(".popup-student-details").addClass("visible");
          }, 500);
          setUser({
            school_id: localStorage.getItem("schoolId"),
            first_name: "",
            last_name: "",
            gender: "",
            programs_id: "",
            dob: "",
            joining_date: "",
            blood_group: "",
            address: "",
            father_name: "",
            mother_name: "",
            primary_email: "",
            secondary_email: "",
            primary_phone: "",
            secondary_phone: "",
            student_qr_code: "",
            status: 1,
            created_by: "",
            updated_by: "",
            studentID: "",
            profile_image: null,
          });
          fetchStudentData(programId);
          console.log(response);
        } else {
          setSnackbarMessage(response.data.message);
          setMessageType('error');
          handleSnackbarOpen();
        }
      });
    }
  };

  const updateUser = () => {
    setError(null);
    if (validateForm()) {
      try {
        axios({
          method: "post",
          url: baseUrl + "/api/student_add",
          data: {
            id: studentId,
            school_id: localStorage.getItem("schoolId"),
            first_name: user.first_name,
            last_name: "",
            gender: user.gender,
            dob: convertDBDateFormat(user.dob),
            programs_id: user.programs_id,
            joining_date: convertDBDateFormat(user.joining_date),
            blood_group: user.blood_group,
            address: user.address,
            father_name: user.father_name,
            mother_name: user.mother_name,
            primary_email: user.primary_email,
            secondary_email: user.secondary_email,
            primary_phone: user.primary_phone,
            secondary_phone: user.secondary_phone,
            profile_image: user.profile_image,
            student_qr_code: "",
            status: 1,
            updated_by: localStorage.getItem('loginUserId'),
            studentID: user.studentID,
          },
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": localStorage.getItem('access_token')
          },
        }).then((response) => {
          console.log(response);
          if (response["data"]["status"] === true) {
            setSnackbarMessage(response.data.message);
            setMessageType('success');
            handleSnackbarOpen();
            displaySetUser({
              // school_id: 1,
              first_name: response["data"]["data"]["first_name"],
              //last_name: '',
              studentID: response["data"]["data"]["studentID"],
              gender: response["data"]["data"]["gender"],
              dob: response["data"]["data"]["dob"],
              joining_date: response["data"]["data"]["joining_date"],
              blood_group: response["data"]["data"]["blood_group"],
              address: response["data"]["data"]["address"],
              father_name: response["data"]["data"]["father_name"],
              mother_name: response["data"]["data"]["mother_name"],
              primary_email: response["data"]["data"]["primary_email"],
              secondary_email: response["data"]["data"]["secondary_email"],
              primary_phone: response["data"]["data"]["primary_phone"],
              secondary_phone: response["data"]["data"]["secondary_phone"],
              profile_image: response["data"]["data"]["profile_image"],
              programs_id: response["data"]["data"]["programs_id"],
            });
            $(".overlay").removeClass("visible");
            $(".popup-modal-container").removeClass("visible");
            setTimeout(function () {
              $(".popup-student-details").addClass("visible");
            }, 2500);
            setUser({
              school_id: localStorage.getItem("schoolId"),
              first_name: "",
              last_name: "",
              gender: "",
              programs_id: "",
              dob: "",
              joining_date: "",
              blood_group: "",
              address: "",
              father_name: "",
              mother_name: "",
              primary_email: "",
              secondary_email: "",
              primary_phone: "",
              secondary_phone: "",
              student_qr_code: "",
              status: 1,
              created_by: "",
              updated_by: "",
              studentID: "",
              profile_image: null,
            });
            fetchStudentData(programId);
            console.log(response);
          } else {
            setSnackbarMessage(response.data.message);
            setMessageType('error');
            handleSnackbarOpen();
          }
        });
      } catch (error) {
        console.log(error)
      }
    }
  };

  const [studentData, setStudentData] = useState({});
  const [loading, setLoading] = useState(false);
  const [programId, setProgramId] = useState(false);
  const [activeStatus, setActiveStatus] = useState(1);
  const [filterValue, setFilterValue] = useState('');

  const handleInputChange = async (event) => {
    setimportstatus(false);
    setFilterValue(event.target.value);
    try {
      const postData = {
        school_id: localStorage.getItem("schoolId"),
        search: event.target.value.trim(),
        programs_id: programId,
        status: activeStatus,
      };
      const { data } = await axios.post(baseUrl + "/api/get_student", postData, {
        headers: {
          "Authorization": localStorage.getItem('access_token')
        }
      });
      console.log(data.data, "d");
      setStudentData(data.data);
      // setLoading(true);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchStudentData = async (programsId) => {
    setimportstatus(false);
    setLoading(false);
    setProgramId(programsId);
    try {
      const postData = {
        school_id: localStorage.getItem("schoolId"),
        search: "",
        programs_id: programsId,
        status: activeStatus,
      };
      const { data } = await axios.post(baseUrl + "/api/get_student", postData, {
        headers: {
          "Authorization": localStorage.getItem('access_token')
        }
      });
      console.log(data.data, "d");
      setStudentData(data.data);
      setLoading(true);
    } catch (error) {
      console.log(error);
    }
  };

  const [programsData, setProgramsData] = useState([]);
  const [programsLoading, setProgramsLoading] = useState(false);
  const [programs, setPrograms] = useState([]); //
  const [programName, setProgramName] = useState(""); //
  const fetchProgramsData = async () => {
    setProgramsLoading(false);
    try {
      const postData = {
        school_id: localStorage.getItem("schoolId"),
        // school_id:1
      };
      const { data } = await axios.post(
        baseUrl + "/api/get_programs",
        postData,
        {
          headers: {
            "Authorization": localStorage.getItem('access_token')
          }
        }
      );
      setProgramsData(data.data);
      setProgramsLoading(true);
      if (localStorage.getItem("programId") === "" || localStorage.getItem("programId") === null) {
        localStorage.setItem("programId", data.data[0].id);
        localStorage.setItem("programName", data.data[0].program_name);
      }
      // setProgramName(data.data[0].program_name);
      // fetchStudentData(data.data[0].id);
      setProgramName(localStorage.getItem("programName"));
      fetchStudentData(localStorage.getItem("programId"));
    } catch (error) {
      console.log(error);
    }
  };

  const openStudentPopup = (studentData) => {
    displaySetUser({
      // school_id: 1,
      id: studentData.id,
      first_name: studentData.first_name,
      //last_name: '',
      studentID: studentData.studentID,
      gender: studentData.gender,
      dob: studentData.dob,
      joining_date: studentData.joining_date,
      blood_group: studentData.blood_group,
      address: studentData.address,
      father_name: studentData.father_name,
      mother_name: studentData.mother_name,
      primary_email: studentData.primary_email,
      secondary_email: studentData.secondary_email,
      primary_phone: studentData.primary_phone,
      secondary_phone: studentData.secondary_phone,
      profile_image: studentData.profile_image,
      programs_id: studentData.programs_id,
    });
    $(".overlay").removeClass("visible");
    $(".popup-modal-container").removeClass("visible");
    setTimeout(function () {
      $(".popup-student-details").addClass("visible");
    }, 500);
  };

  const [studentId, setStudentId] = useState(null);
  const openStudentUpdatePopup = (studentData) => {
    $(".popup-student-details").removeClass("visible");
    setError(null);
    setSelectedImage(null);
    setValidationErrors({
      first_name: "",
      gender: "",
      dob: "",
      programs_id: "",
      profile_image: "",
      joining_date: "",
      studentID: "",
      blood_group: "",
      address: "",
      father_name: "",
      primary_phone: "",
      primary_email: "",
      mother_name: "",
      secondary_phone: "",
      secondary_email: "",
    });
    setStudentId(studentData.id);
    setUser({
      school_id: localStorage.getItem("schoolId"),
      first_name: studentData.first_name,
      last_name: studentData.first_name,
      gender: studentData.gender,
      programs_id: studentData.programs_id,
      dob: formatDate(studentData.dob),
      joining_date: formatDate(studentData.joining_date),
      blood_group: studentData.blood_group,
      address: studentData.address,
      father_name: studentData.father_name,
      mother_name: studentData.mother_name,
      primary_email: studentData.primary_email,
      secondary_email: studentData.secondary_email,
      primary_phone: studentData.primary_phone,
      secondary_phone: studentData.secondary_phone,
      student_qr_code: "",
      status: 1,
      created_by: "",
      updated_by: "",
      studentID: studentData.studentID,
      profile_image: studentData.profile_image,
    });
    $(".overlay").addClass("visible");
    $(".popup-modal-container").addClass("visible");
  };

  const fetchPrograms = async () => {
    try {
      const response = await axios.post(baseUrl + "/api/get_programs", {
        school_id: localStorage.getItem("schoolId")
      }, {
        headers: {
          "Authorization": localStorage.getItem('access_token')
        }
      });
      setPrograms(response.data.data);
    } catch (error) { }
  };

  const selectedProgram = programs.find(
    (program) => program.id === user.programs_id
  );

  const selectedGender = gender1.find(
    (gender) => gender.value === user.gender
  );

  const selectedBlood = Blood1.find(
    (blood_group) => blood_group.value === user.blood_group
  );

  const firstNameInputRef = useRef(null);
  const genderInputRef = useRef(null);
  const dobInputRef = useRef(null);
  const programInputRef = useRef(null);
  const imageInputRef = useRef(null);
  const joingInputRef = useRef(null);
  const studentInputRef = useRef(null);
  const bloodInputRef = useRef(null);
  const adressInputRef = useRef(null);
  const fathernameInputRef = useRef(null);
  const fatherphoneInputRef = useRef(null);
  const fatheremailInputRef = useRef(null);
  const mathernameInputRef = useRef(null);
  const matherphoneInputRef = useRef(null);
  const matheremailInputRef = useRef(null);
  const submitButtonRef = useRef(null);

  const handleDropdownKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      imageInputRef.current.focus(); // Move focus to the next input field
    }
  };

  const handleKeyPress = (event, nextInputRef) => {
    if (event.key === "Enter") {
      event.preventDefault();
      console.log("Next input ref:", nextInputRef); // Log the next input ref
      if (nextInputRef && nextInputRef.current) {
        nextInputRef.current.focus();
      } else {
        console.log("Next input ref is null");
      }
    }
  };

  const [error, setError] = useState(null);
  const [validationErrors, setValidationErrors] = useState({
    first_name: "",
    gender: "",
    dob: "",
    programs_id: "",
    profile_image: "",
    joining_date: "",
    studentID: "",
    blood_group: "",
    address: "",
    father_name: "",
    primary_phone: "",
    primary_email: "",
    mother_name: "",
    secondary_phone: "",
    secondary_email: "",
  });

  const [validationDone, setValidationDone] = useState(false);
  // const validateForm = () => {
  //   let errors = {};

  //   if (user.first_name === "") {
  //     errors.first_name = "Student name cannot be empty";
  //   }
  //   if (user.gender === "") {
  //     errors.gender = "Student gender cannot be empty";
  //   }
  //   if (user.dob === "") {
  //     errors.dob = "Student date of birth cannot be empty";
  //   }
  //   if (user.programs_id === "") {
  //     errors.programs_id = "Student programs cannot be empty";
  //   }
  //   if (!user.profile_image) {
  //     errors.profile_image = "Student profile image cannot be empty";
  //   }
  //   if (user.joining_date === "") {
  //     errors.joining_date = "Student joining date cannot be empty";
  //   }
  //   if (user.studentID === "") {
  //     errors.studentID = "Student studentID cannot be empty";
  //   }
  //   if (user.blood_group === "") {
  //     errors.blood_group = "Student blood group cannot be empty";
  //   }
  //   if (user.address === "") {
  //     errors.address = "Student address cannot be empty";
  //   }
  //   if (user.father_name === "") {
  //     errors.father_name = "Student father name cannot be empty";
  //   }
  //   if (user.primary_phone.trim() === "") {
  //     errors.primary_phone = "Student father mobile number cannot be empty";
  //   } else if (
  //     !validator.isMobilePhone(user.primary_phone.trim()) ||
  //     user.primary_phone.trim().length !== 10
  //   ) {
  //     errors.primary_phone = "Please enter valid mobile number";
  //   }
  //   if (user.primary_email.trim() === "") {
  //     errors.primary_email = "Student father email cannot be empty";
  //   } else if (!validator.isEmail(user.primary_email.trim())) {
  //     errors.primary_email = "Please enter valid email";
  //   }
  //   if (user.mother_name === "") {
  //     errors.mother_name = "Student mother name cannot be empty";
  //   }
  //   if (user.secondary_phone.trim() === "") {
  //     errors.secondary_phone = "Student mother mobile number cannot be empty";
  //   } else if (
  //     !validator.isMobilePhone(user.secondary_phone.trim()) ||
  //     user.secondary_phone.trim().length !== 10
  //   ) {
  //     errors.secondary_phone = "Please enter valid mobile number";
  //   }
  //   if (user.secondary_email.trim() === "") {
  //     errors.secondary_email = "Student mother email cannot be empty";
  //   } else if (!validator.isEmail(user.secondary_email.trim())) {
  //     errors.secondary_email = "Please enter valid email";
  //   }

  //   setValidationErrors(errors);
  //   setValidationDone(true);
  //   return Object.keys(errors).length === 0;
  // };
  const validateForm = () => {
    let errors = {};

    if (user.first_name === "") {
      errors.first_name = "Student name cannot be empty";
    }
    if (user.gender === "") {
      errors.gender = "Student gender cannot be empty";
    }
    if (user.dob === "") {
      errors.dob = "Student date of birth cannot be empty";
    }
    if (user.programs_id === "") {
      errors.programs_id = "Student programs cannot be empty";
    }
    // if (!user.profile_image) {
    //   errors.profile_image = "Student profile image cannot be empty";
    // }
    // if (user.joining_date === "") {
    //   errors.joining_date = "Student joining date cannot be empty";
    // }
    // if (user.studentID === "") {
    //   errors.studentID = "Student studentID cannot be empty";
    // }
    // if (user.blood_group === "") {
    //   errors.blood_group = "Student blood group cannot be empty";
    // }
    if (user.address === "") {
      errors.address = "Student address cannot be empty";
    }
    if (user.father_name === "") {
      errors.father_name = "Student father name cannot be empty";
    }
    if (user.primary_phone.trim() === "") {
      errors.primary_phone = "Student father mobile number cannot be empty";
    } else if (
      !validator.isMobilePhone(user.primary_phone.trim()) ||
      user.primary_phone.trim().length !== 10
    ) {
      errors.primary_phone = "Please enter valid mobile number";
    }
    // if (user.primary_email.trim() === "") {
    //   errors.primary_email = "Student father email cannot be empty";
    // } else if (!validator.isEmail(user.primary_email.trim())) {
    //   errors.primary_email = "Please enter valid email";
    // }
    if (user.mother_name === "") {
      errors.mother_name = "Student mother name cannot be empty";
    }
    if (user.secondary_phone.trim() === "") {
      errors.secondary_phone = "Student mother mobile number cannot be empty";
    } else if (
      !validator.isMobilePhone(user.secondary_phone.trim()) ||
      user.secondary_phone.trim().length !== 10
    ) {
      errors.secondary_phone = "Please enter valid mobile number";
    }
    // if (user.secondary_email.trim() === "") {
    //   errors.secondary_email = "Student mother email cannot be empty";
    // } else if (!validator.isEmail(user.secondary_email.trim())) {
    //   errors.secondary_email = "Please enter valid email";
    // }

    setValidationErrors(errors);
    setValidationDone(true);
    return Object.keys(errors).length === 0;
  };
  const handleTabSelect = (event) => {
    document.querySelectorAll(".select_field_point").forEach((element) => {
      element.style.backgroundColor = "";
    });
    event.currentTarget.style.backgroundColor = "#F4F6FF";
  };

  // ------------------------11-06----------------
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });
  const handleSnackbarClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };
  const handleSnackbarOpen = () => {
    setSnackbarState({ ...snackbarState, open: true });
  };
  useEffect(() => { }, [snackbarMessage, messageType]);

  // ----------------------12-06------------------
  const [selectedStudent, setselectedStudent] = useState([]);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allStudentIds = studentData.map(student => student.id);
      setselectedStudent(allStudentIds);
    } else {
      setselectedStudent([]);
    }
  };

  const handleSelectStudent = (studentId) => {
    setselectedStudent(prevSelected =>
      prevSelected.includes(studentId)
        ? prevSelected.filter(id => id !== studentId)
        : [...prevSelected, studentId]
    );
  };

  // ----------------------13-06-----------------------
  const [isRecycleBinVisible, setRecycleBinVisible] = useState(false);

  const handleRecycleBinClick = () => {
    if (selectedStudent.length !== 0) {
      setRecycleBinVisible(true);
    } else {
      setSnackbarMessage("Please Select Student");
      setMessageType('error');
      handleSnackbarOpen();
    }
  };

  const handleRecycleBinClose = () => {
    setRecycleBinVisible(false);
  };

  const handleSaveRecycleBin = () => {
    if (selectedStudent.length !== 0) {
      try {
        axios({
          method: "post",
          url: baseUrl + "/api/student_delete",
          data: {
            student_ids: selectedStudent,
            programs_id: localStorage.getItem("programId"),
            updated_by: localStorage.getItem('loginUserId')
          },
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": localStorage.getItem('access_token')
          },
        }).then((response) => {
          console.log(response);
          if (response.data.status === true) {
            setRecycleBinVisible(false);
            setselectedStudent([])
            setSnackbarMessage(response.data.message);
            setMessageType('success');
            handleSnackbarOpen();
            fetchStudentData(localStorage.getItem("programId"));
          } else {
            setSnackbarMessage(response.data.message);
            setMessageType('error');
            handleSnackbarOpen();
          }
        });
      } catch (error) {
        console.log(error)
      }
    }
  }

  const [isNewProgramVisible, setNewProgramVisible] = useState(false);
  const [newProgram, setNewProgram] = useState(null);
  const [newProgramError, setNewProgramError] = useState("");
  const selectedNewProgram = programs.find(
    (program) => program.id === newProgram
  );


  const handleNewProgramClick = () => {
    if (selectedStudent.length !== 0) {
      setNewProgramVisible(true);
    } else {
      setSnackbarMessage("Please Select Student");
      setMessageType('error');
      handleSnackbarOpen();
    }
  };

  const handleNewProgramClose = () => {
    setNewProgram(null);
    setNewProgramVisible(false);
  };

  const handleSaveNewProgram = async () => {
    if (newProgram === null) {
      setNewProgramError("Please select Program")
    } else {
      setNewProgramError(null)
    }
    if (newProgram !== null && selectedStudent.length !== 0) {
      try {
        axios({
          method: "post",
          url: baseUrl + "/api/student_move_program",
          data: {
            student_ids: selectedStudent,
            programs_id: newProgram,
            updated_by: localStorage.getItem('loginUserId')
          },
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": localStorage.getItem('access_token')
          },
        }).then((response) => {
          console.log(response);
          if (response.data.status === true) {
            setNewProgramVisible(false);
            setselectedStudent([]);
            setNewProgram(null);
            setSnackbarMessage(response.data.message);
            setMessageType('success');
            handleSnackbarOpen();
            fetchStudentData(localStorage.getItem("programId"));
          } else {
            setSnackbarMessage(response.data.message);
            setMessageType('error');
            handleSnackbarOpen();
          }
        });
      } catch (error) {
        console.log(error)
      }
    }
  }

  const handleActiveClick = async (activeStatus) => {
    setActiveStatus(activeStatus)
    setimportstatus(false);
    setLoading(false);
    try {
      const postData = {
        school_id: localStorage.getItem("schoolId"),
        search: "",
        programs_id: programId,
        status: activeStatus,
      };
      const { data } = await axios.post(baseUrl + "/api/get_student", postData, {
        headers: {
          "Authorization": localStorage.getItem('access_token')
        }
      });
      console.log(data.data, "d");
      setStudentData(data.data);
      setLoading(true);
    } catch (error) {
      console.log(error);
    }
  };

  // -------------------------18-06----------------------

  // -------------------------19-06-------------------------

  const handleOpenNewProgramPopupVisible = () => {
    setProgramNameError(null);
    setProgramTeacherError(null);
    setTimeError(null);
    setTeacher("");
    setProgramStartTime("");
    setProgramEndTime("");
    setNewProgramPopupVisible(true);
  }

  const [newProgramPopupVisible, setNewProgramPopupVisible] = useState(false);
  const [programStartTime, setProgramStartTime] = useState("");
  const [programEndTime, setProgramEndTime] = useState("");
  const [programTeacher, setTeacher] = useState("");
  const [timeData, setTimeData] = useState([]);
  const [teachersData, setTeachersData] = useState([]);
  const selectedStartTime = timeData.find(data => data.id === programStartTime);
  const selectedEndTime = timeData.find(data => data.id === programEndTime);
  const selectedTeachers = teachersData.find(data => data.id === programTeacher);

  const fetchProgramTimeData = async () => {
    axios({
      method: "post",
      url: baseUrl + "/api/get_time",
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": localStorage.getItem('access_token')
      },
    }).then((response) => {
      console.log(response);
      if (response.data.status === true) {
        setTimeData(response.data.data);
        console.log(response);
      }
    });
  }

  const fetchAllTeachersData = async () => {
    axios({
      method: "post",
      url: baseUrl + "/api/teacher_list",
      data: {
        school_id: localStorage.getItem("schoolId")
      },
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": localStorage.getItem('access_token')
      },
    }).then((response) => {
      console.log(response);
      if (response.data.status === true) {
        setTeachersData(response.data.data);
        console.log(response);
      }
    });
  }
  const [newProgramName, setNewProgramName] = useState('');
  const [features, setFeatures] = useState({
    qrCode: { status: true, name: 'QR Code', value: 2 },
    attendance: { status: true, name: 'Attendance', value: 5 },
    food: { status: true, name: 'Food', value: 3 },
    sleep: { status: true, name: 'Sleep', value: 4 },
    leaves: { status: true, name: 'Leaves', value: 6 },
    busTracking: { status: false, name: 'Bus Tracking', value: 7 },
    cctv: { status: false, name: 'CCTV', value: 8 },
    fees: { status: false, name: 'Fees', value: 9 },

  });
  const [additionalInfo, setAdditionalInfo] = useState({
    inviteParents: {
      status: true,
      name: 'Automatically Invite Parents as soon as student profile created',
      value: 1
    },
    addParentChat: {
      status: true,
      name: 'Add Parent in chat as soon as student profile created',
      value: 2
    },
    createStudentID: {
      status: true,
      name: 'Automatically create Student ID as soon as profile created',
      value: 3
    },
  });


  const handleFeatureChange = (feature) => {
    setFeatures({
      ...features,
      [feature]: {
        ...features[feature],
        status: !features[feature].status,
      },
    });
  };
  const handleAdditionalInfoChange = (info) => {
    setAdditionalInfo({
      ...additionalInfo,
      [info]: {
        ...additionalInfo[info],
        status: !additionalInfo[info].status,
      },
    });
  };

  const [programeNameError, setProgramNameError] = useState(null);
  const [programeTeacherError, setProgramTeacherError] = useState(null);
  const [timeError, setTimeError] = useState(null);
  const validateProgramForm = () => {

    let valid = true;
    if (newProgramName === "") {
      setProgramNameError("Please enter program name");
      valid = false;
    } else {
      setProgramNameError(null);
    }
    if (programTeacher === "") {
      setProgramTeacherError("Please select program teacher");
      valid = false;
    } else {
      setProgramTeacherError(null);
    }
    // if (programStartTime === "" || programEndTime === "") {
    //   setTimeError("Please select start and end time");
    //   valid = false;
    // } else {
    //   setTimeError(null);
    // }
    return valid;
  };

  const handleCreateProgram = () => {

    const selectedFeatureValues = Object.keys(features).filter((key) => features[key].status).map((key) => features[key].value);
    const selectedAdditionalInfoValues = Object.keys(additionalInfo).filter((key) => additionalInfo[key].status).map((key) => additionalInfo[key].value);

    console.log({
      school_id: localStorage.getItem("schoolId"),
      program_name: newProgramName,
      teacher_id: programTeacher,
      from_time_id: programStartTime,
      to_time_id: programEndTime,
      features: selectedFeatureValues.join(","),
      additional_info: selectedAdditionalInfoValues.join(","),
      status: 1,
      created_by: localStorage.getItem("loginUserId")
    });
    if (validateProgramForm()) {
      try {
        axios({
          method: "post",
          url: baseUrl + "/api/programs_add_edit",
          data: {
            school_id: localStorage.getItem("schoolId"),
            program_name: newProgramName,
            teacher_id: programTeacher,
            from_time_id: programStartTime,
            to_time_id: programEndTime,
            features: selectedFeatureValues.join(","),
            additional_info: selectedAdditionalInfoValues.join(","),
            status: 1,
            created_by: localStorage.getItem("loginUserId")
          },
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": localStorage.getItem('access_token')
          },
        }).then((response) => {
          console.log(response);
          if (response.data.status === true) {
            $('.overlay').removeClass('visible');
            $('.popup-program-container').removeClass('visible');
            setNewProgramPopupVisible(false);
            fetchProgramsData();
            setSnackbarMessage(response.data.message);
            setMessageType('success');
            handleSnackbarOpen();
          } else {
            setSnackbarMessage(response.data.message);
            setMessageType('error');
            handleSnackbarOpen();
          }
        });
      } catch (error) {
        console.log(error)
      }
    }


  };

  const assignStyle = {
    // position: 'absolute',
    // inset: '0px auto auto 0px',
    // margin: '0px',
    // transform: 'translate(1520px, 170px)'
    position: "absolute",
    right: "26px",
    top: "154px",
    margin: "0px",
    maxWidth: "400px",

  }
  const [assignTeacherVisible, setAssignTeacherVisible] = useState(false);

  const toggleAssignTeacher = () => {
    setIsAddTeacherFocused(false);
    setSearchAssignTeacherQuery('');
    setFilteredUsers(teachersData);
    setAssignTeacherVisible(true);
  };

  const [searchAssignTeacherQuery, setSearchAssignTeacherQuery] = useState('');
  const [filteredAssignTeacher, setFilteredUsers] = useState([]);

  const handleAssignTeacherSearch = (event) => {
    const query = event.target.value;
    setSearchAssignTeacherQuery(query.trim());
    setFilteredUsers(teachersData.filter(user => user.first_name.toLowerCase().includes(query.toLowerCase())));
  };

  const handleAssignTeachers = async (staffId, checked) => {
    if (checked || (teachersData.filter(user => user.assign_classes !== null && user.assign_classes.split(",").includes(localStorage.getItem("programId"))).length > 1)) {
      axios({
        method: "post",
        url: baseUrl + "/api/add_class_teacher",
        data: {
          staff_ids: [staffId],
          programs_id: checked ? localStorage.getItem("programId") : "",
          updated_by: localStorage.getItem("loginUserId")
        },
        headers: {
          "Content-Type": "multipart/form-data",
          "Authorization": localStorage.getItem('access_token')
        },
      }).then((response) => {
        console.log(response);
        if (response.data.status === true) {
          setAssignTeacherVisible(!assignTeacherVisible);
          fetchAllTeachersData();
          setSnackbarMessage(response.data.message);
          setMessageType('success');
          handleSnackbarOpen();
          console.log(response);
        } else {
          setAssignTeacherVisible(!assignTeacherVisible);
          setSnackbarMessage(response.data.message);
          setMessageType('error');
          handleSnackbarOpen();
        }
      });
    } else {
      setAssignTeacherVisible(!assignTeacherVisible);
      setSnackbarMessage("At least one class teacher is required");
      setMessageType('error');
      handleSnackbarOpen();
    }
  }

  const popupRef = useRef(null);

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setAssignTeacherVisible(false);
    }
  };

  useEffect(() => {
    if (assignTeacherVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [assignTeacherVisible]);



  //


  const initialBorderColor = {
    genderBorder: "",
    programsBorder: "",
    bloodGroupBorder: "",
    active: "",
    moveTo: "",
    newProgram: ""
  };

  const [borderColor, setBorderColor] = useState(initialBorderColor);

  const handleFocus = (borderColor) => {
    if (borderColor === "gender") {
      setBorderColor({ ...borderColor, genderBorder: "#4D6EE3" });
    } else if (borderColor === "programs") {
      setBorderColor({ ...borderColor, programsBorder: "#4D6EE3" });
    } else if (borderColor === "bloodGroup") {
      setBorderColor({ ...borderColor, bloodGroupBorder: "#4D6EE3" });
    } else if (borderColor === "active") {
      setBorderColor({ ...borderColor, active: "#4D6EE3" });
    } else if (borderColor === "moveTo") {
      setBorderColor({ ...borderColor, moveTo: "#4D6EE3" });
    } else if (borderColor === "newProgram") {
      setBorderColor({ ...borderColor, newProgram: "#4D6EE3" });
    }
  };

  const handleBlur = () => {
    setBorderColor(initialBorderColor);
  };
  // live
  const [isFocused, setIsFocused] = useState(false);
  const [isAddTeacherFocused, setIsAddTeacherFocused] = useState(false);
  const [isOpen, setIsOpen] = useState(false); // State to manage calendar open/close

  const handleImageClick = () => {
    setIsOpen(!isOpen); // Toggle calendar open/close
  };

  const genderRef = useRef(null);
  const programsRef = useRef(null);
  const bloodGroupRef = useRef(null);
  const activeRef = useRef(null);
  const moveToRef = useRef(null);
  const newProgramRef = useRef(null);
  const handleClickOutside1 = (event) => {
    if (genderInputRef.current && !genderInputRef.current.contains(event.target)) {
      setBorderColor(initialBorderColor);
    }

    if (programsRef.current && !programsRef.current.contains(event.target)) {
      setBorderColor(initialBorderColor);
    }
    if (bloodGroupRef.current && !bloodGroupRef.current.contains(event.target)) {
      setBorderColor(initialBorderColor);
    }
    if (activeRef.current && !activeRef.current.contains(event.target)) {
      setBorderColor(initialBorderColor);
    }
    if (moveToRef.current && !moveToRef.current.contains(event.target)) {
      setBorderColor(initialBorderColor);
    }
    if (newProgramRef.current && !newProgramRef.current.contains(event.target)) {
      setBorderColor(initialBorderColor);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside1);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside1);
    };
  }, []);

  useEffect(() => {
    fetchPrograms();
    fetchProgramsData();
    fetchProgramTimeData();
    fetchAllTeachersData();
    $(document).on("click", "#newStudentCreate", function () {
      setStudentId(null);
      setBorderColor(initialBorderColor);
      setSelectedImage(null);
      setUser({
        school_id: localStorage.getItem("schoolId"),
        first_name: "",
        last_name: "",
        gender: "",
        programs_id: "",
        dob: "",
        joining_date: "",
        blood_group: "",
        address: "",
        father_name: "",
        mother_name: "",
        primary_email: "",
        secondary_email: "",
        primary_phone: "",
        secondary_phone: "",
        student_qr_code: "",
        status: 1,
        created_by: "",
        updated_by: "",
        studentID: "",
        profile_image: null,
      });
      setValidationErrors({
        first_name: "",
        gender: "",
        dob: "",
        programs_id: "",
        profile_image: "",
        joining_date: "",
        studentID: "",
        blood_group: "",
        address: "",
        father_name: "",
        primary_phone: "",
        primary_email: "",
        mother_name: "",
        secondary_phone: "",
        secondary_email: "",
      });
      $(".overlay").addClass("visible");
      $(".popup-modal-container").addClass("visible");
    });
    $(document).on("click", ".sp-btn-cancel", function () {
      setError(null);
      setBorderColor(initialBorderColor);
      $(".overlay").removeClass("visible");
      $(".popup-modal-container").removeClass("visible");
    });

  }, []);

  return (
    <section>
      <Snackbar
        anchorOrigin={{ vertical: snackbarState.vertical, horizontal: snackbarState.horizontal }}
        open={snackbarState.open}
        onClose={handleSnackbarClose}
        message={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ListItemIcon>
              <img src={messageType === 'success' ? SuccessIcon : ErrorIcon} alt="Success" style={{ width: '24px', height: '24px', marginRight: '8px' }} />
            </ListItemIcon>
            <div className="success_cuc">
              <p> {snackbarMessage} </p>
            </div>
          </div>
        }
        ContentProps={{
          sx: {
            border: "1px solid",
            opacity: "1",
            background: messageType === 'success' ? "#40BE61" : "#FD4954",
            borderColor: messageType === 'success' ? "#40BE61" : "#FD4954",
            width: '415px'
          }
        }}
        autoHideDuration={3000}
      />
      <div className="outer-container student_input input_error_bor">
        <div className="overlay" />
        <div id="left-panel" className="sp-left-panel">
          <div className="sp-top-band">
            <a className="sp-logo">
              Scool Panda<sup>Beta</sup>
            </a>
            {/* <div className="sp-search-top flexc search_input_">
              <i className="icon-Search" />
              <input
                id="search"
                className="sp-search-btn"
                placeholder="Quick search..."
              />
            </div> */}
          </div>
          <div className="sp-middle-band">
            <div className="sp-nav y-auto">
              <div className="">
                <h4 className="sp-title">Quick Actions</h4>
                <ul>
                  <li
                    onClick={() => {
                      navigate("/dashboard");
                    }}
                  >
                    <span>
                      <i className="icon-Dashboard-Outline" />
                      Dashboard
                    </span>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/activity");
                    }}
                  >
                    <span>
                      <i className="icon-Activity-Outline" />
                      Activity
                    </span>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/calendar");
                    }}
                  >
                    <span>
                      <i className="icon-Timetable-Outline" />
                      Calendar
                    </span>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/staff_list");
                    }}
                  >
                    <span>
                      <i className="icon-Staff-Outline" />
                      Staff
                    </span>
                  </li>
                  {/* <li>
                    <span>
                      <i className="icon-Message-Outline" />
                      Messages
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Fees-Outline" />
                      Fees Management
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Enquiries-Outline" />
                      Enquiries
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Invites-Outline" />
                      Invites
                    </span>
                  </li> */}
                </ul>
                <h4 className="sp-title flexc justifySp" onClick={() => handleOpenNewProgramPopupVisible()}>
                  Programs{" "}
                  <span id="add-programs" className="sp-plus ">
                    +
                  </span>
                </h4>
                <div>
                  <ul>
                    {programsLoading &&
                      programsData &&
                      programsData.length > 0 ? (
                      programsData.map((data, index) => {
                        return (
                          <li
                            className={
                              data.id === parseInt(localStorage.getItem("programId")) ? "selected" : ""
                            }
                            onClick={() => {
                              localStorage.setItem("programId", data.id);
                              localStorage.setItem("programName", data.program_name);
                              setProgramName(data.program_name);
                              fetchStudentData(data.id);
                            }}
                          >
                            <span>
                              <i className="icon-Program-Outline-1" />
                              {data.program_name}
                            </span>
                          </li>
                        );
                      })
                    ) : (
                      <div className="data-loader extracss_load">
                        {/* <Loader
                        type="spinner-cub"
                        bgColor="#4D6EE3"
                        color="#6B6F8C"
                        title="Loading..."
                        size={35}
                      /> */}
                        <WindmillSpinner loading={"loading"} color="#4d6ee3d6" size={30} />
                        <p>Loading...</p>
                      </div>
                    )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="sp-bottom sp-nav">
              <ul>
                <li>
                  <span>
                    {/* <i className="icon-Import-Outline" /> */}
                    <div className="noti_sdi">
                      <img src={gift} alt="" style={{ height: "16px" }} />
                      <p>Notifications</p>
                    </div>

                  </span>
                </li>
                <li>
                  <span style={{ display: "block" }}>
                    {/* <i className="icon-Invites-Outline" /> */}
                    <div className="noti_tot">
                      <div className="noti_sdi">
                        <img src={upar} alt="" style={{ height: "18px", width: "18px" }} />
                        <p>Upgrade Pro</p>
                      </div>
                      <img src={ques} alt="" style={{ height: "18px", width: "18px" }} />
                    </div>
                  </span>
                </li>
                <li onClick={() => navigate("/signout")}>
                  <span>
                    <i className="icon-Invites-Outline" />
                    Log Out
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="sp-right-panel">
          <div className="sp-top-bar flexc justifySp top_bar_cus exta_pad_10">
            <div className="ft-wt600 flexc">
              <span className="selected-icon">
                <i className="icon-Program-Outline-1" />
              </span>
              {localStorage.getItem("programName")}
              <i className="icon-More-Icon" />

            </div>
            <div className="flexc">
              <div className="file-input-container">
                <input
                  //   type="button"
                  //   id="fileInput"
                  style={{ display: "none" }}
                // accept="image/*"
                />
                <label
                  className="sp-view-btn ft-wt500 flexc"
                  //   htmlFor="fileInput"
                  onClick={() => {
                    importStudents1();
                  }}
                >
                  <i className="icon-Import-Outline" />
                  Import
                </label>
              </div>
              <button
                className="createN ft-wt500 mr-lf10 space_icon_pad"
                id="newStudentCreate"
              >
                <i class="fa-solid fa-plus"></i> New Student
              </button>
            </div>
          </div>
          {isLoading && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
          )}
          <div className="sp-header bor_bot_stucus">
            <nav className="flexc">
              <div className="sp-list-view flexc selected first_list_mar_cus">
                <i className="icon-Students-Outline" />
                Student List
              </div>
              <div
                className="sp-list-view flexc"
                onClick={() => {
                  navigate("/student_attendance");
                }}
              >
                <i className="icon-Attendance-Outline" />Attendance</div>
              <div
                className="sp-list-view flexc"
                onClick={() => {
                  navigate("/food");
                }}
              >
                <i className="icon-Food" />
                Food
              </div>
              <div
                className="sp-list-view flexc"
                onClick={() => {
                  navigate("/sleep");
                }}
              >
                <i className="icon-Sleep" />
                Sleep
              </div>
              <div
                className="sp-list-view flexc"
                onClick={() => {
                  navigate("/student_timetable");
                }}
              >
                <i className="icon-Calendar-Outline" />
                Timetable
              </div>
            </nav>
            <>
              {!importstatus && (
                <div className="sp-menu-bar  flexc justifySp extra_pad_men">
                  <div className="sp-menugroup">
                    <div className="flexc">
                      {selectedStudent.length === 0 ? (
                        <>
                          <div className="drop_move_cus"
                            style={{
                              width: "100%", height: "40px",
                              borderColor: borderColor.active,
                            }}
                            onKeyPress={handleDropdownKeyPress}
                            onFocus={() => handleFocus("active")}
                            onBlur={handleBlur}                          >
                            <Dropdown
                              label={
                                <span style={{ color: "#1B252F", fontSize: "0.8825em" }}>
                                  {activeStatus === 1 ? "Active Students" : "Inactive Students"}
                                </span>
                              }
                              style={{ width: "100%" }}
                            >
                              <Dropdown.Item
                                className={`${activeStatus === 1 ? "selected-item dd_select pad_progr" : "pad_progr"}`}
                                icon={activeStatus === 1 ? HiCheck : null}
                                onClick={() => handleActiveClick(1)}
                              >
                                Active Students
                              </Dropdown.Item>
                              <Dropdown.Item
                                className={`${activeStatus === 0 ? "selected-item dd_select pad_progr" : "pad_progr"}`}
                                icon={activeStatus === 0 ? HiCheck : null}
                                onClick={() => handleActiveClick(0)}
                              >
                                Inactive Students
                              </Dropdown.Item>
                            </Dropdown>

                          </div>
                          <div className={`btn-search-bar flexc ${isFocused ? 'focused' : ''}`}>
                            <i className="icon-Search" />
                            <input
                              type="search"
                              placeholder="Search Students"
                              value={filterValue}
                              onChange={handleInputChange}
                              onFocus={() => setIsFocused(true)}
                              onBlur={() => setIsFocused(false)}
                              className="search-input"
                            />
                          </div>
                          {/* <div className="btn-search-bar flexc">
                            <i className="icon-Search" />
                            <input
                              type="search"
                              placeholder="Search Students"
                              value={filterValue}
                              onChange={handleInputChange}
                            />
                          </div> */}
                        </>
                      ) : (
                        <div style={{ display: "flex" }}>
                          <div className="drop_move_cus"
                            style={{
                              width: "100%",
                              height: "40px",
                              display: "flex",
                              alignItems: "center",
                              borderColor: borderColor.moveTo
                            }}
                            onKeyPress={handleDropdownKeyPress}
                            onFocus={() => handleFocus("moveTo")}
                            onBlur={handleBlur}
                          >
                            <Dropdown
                              label={
                                <span
                                  style={{
                                    color: "#707070", fontSize: "13px", justifyContent: "flex-start",
                                    fontWight: "500",
                                    textAlign: "left",
                                    color: "#1B252F"
                                  }}
                                >
                                  <i className="fa-solid fa-arrow-right-to-bracket"></i> Move to
                                </span>
                              }
                              style={{ width: "100%" }}
                            >
                              <Dropdown.Item onClick={handleNewProgramClick} className="pad_progr1">
                                <i className="fa-solid fa-arrows-rotate fa-flip-horizontal"></i> New Program
                              </Dropdown.Item>
                              <Dropdown.Item onClick={handleRecycleBinClick} className="pad_progr1">
                                <i className="fa-regular fa-trash-can"></i> Recycle Bin
                              </Dropdown.Item>
                            </Dropdown>
                          </div>
                          <div className="invite_cus_div">
                            <button className="sp_mbtn flexc ">
                              <img src={invite} alt="" style={{ width: "14px" }} />
                              <span>Invite all Parents</span>
                            </button>

                          </div>
                        </div>

                      )}
                    </div>

                  </div>
                  <div className="sp-menu hidden">
                    <div className="flexc"></div>
                  </div>
                  <div className="flexc sp-menugroup">
                    <div className="sp-menubar-clslist flexc" style={{ gap: "9px", alignItems: "center" }}>
                      <span className="ft-wt500 font_13">Class Teachers</span>
                      <div style={{
                        display: "flex", gap: "5px", alignItems: "baseline"
                      }}>
                        <ul className="flexc">
                          {teachersData
                            .filter(user => user.assign_classes !== null && user.assign_classes.split(",").includes(localStorage.getItem("programId")))
                            .slice(0, 5)
                            .map(user => (
                              <li key={user.id}>
                                <span className="text-xsmall small-tag">{user.first_name.slice(0, 1).toUpperCase()}</span>
                              </li>
                            ))}
                          {teachersData.filter(user => user.assign_classes !== null && user.assign_classes.split(",").includes(localStorage.getItem("programId"))).length > 5 && (
                            <li>
                              <div className="more-images" style={{ width: "unset" }}>
                                <span className="more-text ft-17" style={{ fontSize: "8px", padding: "0px 4px" }}>
                                  +{teachersData.filter(user => user.assign_classes !== null && user.assign_classes.split(",").includes(localStorage.getItem("programId"))).length - 5}
                                </span>
                              </div>
                            </li>
                          )}
                        </ul>
                        <span className="sp-plus clas_plu_icon bor_rad" id="assign-teacher" style={{ background: assignTeacherVisible && "#4D6EE3" }} onClick={toggleAssignTeacher}><i class="fa-solid fa-plus" style={{ color: assignTeacherVisible && "#ffffff" }}></i></span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
          <div className="load-container">
            <div className="sp-table-load overxy-auto">
              <div className="sp-table-container">
                {!studentData ? (
                  <ImportComponent
                    importStudent={"Student"}
                    handleImportStatus={handleImportStatus}
                    setProgress={setProgress} setIsLoading={setIsLoading}
                  />
                ) : importstatus ? (
                  <ImportComponent
                    importStudent={"Student"}
                    handleImportStatus={handleImportStatus}
                    setProgress={setProgress} setIsLoading={setIsLoading}
                  />
                ) : (
                  <>
                    <div className="sp-table-header extra_pad_men flexc hei_4px">
                      <div className="table_input_sty">
                        {studentData.length !== 0 &&
                          <input
                            type="checkbox"
                            id="check-all"
                            onChange={handleSelectAll}
                            checked={selectedStudent.length === studentData.length}
                          />
                        }
                        {/* <input type="checkbox" id="check-all" /> */}
                      </div>
                      <div className="sp-title flex-2 extra_flex_cusname">
                        <span>Student name</span>
                      </div>
                      <div className="sp-title flex-2 extra_flex2_cusname">
                        <span>Father Name</span>
                      </div>
                      <div className="sp-title flex-2 extra_flex2_cusname">
                        <span>Mother Name</span>
                      </div>
                      <div className="sp-title flex-2 extra_flex2_cusname">
                        <span>Father Number</span>
                      </div>
                      <div className="sp-title flex-2 extra_flex2_cusname">
                        <span> Mother Number</span>
                      </div>
                      <div className="sp-title flex-2">Parents Email</div>
                    </div>
                    <div className="sp-table-body" >
                      {loading && studentData && studentData.length > 0 ? (
                        studentData.map((student) => (
                          <div
                            className="select_field_point"
                            style={{ cursor: "pointer" }}
                            onClick={(event) => handleTabSelect(event)}
                          >
                            <div className="row flexc extra_side_padcus">
                              <div className="table_input_sty">
                                <input
                                  type="checkbox"
                                  className="sub_select"
                                  onChange={() => handleSelectStudent(student.id)}
                                  checked={selectedStudent.includes(student.id)}
                                />
                                {/* <input type="checkbox" className="sub_select" /> */}
                              </div>
                              <div
                                className="sp-td-content flex-2 extra_flex_cusname truncate click-popup"
                                onClick={() => {
                                  openStudentPopup(student);
                                }}
                              >
                                <span className="ft-500 flexc img_wid_cu ">
                                  <img
                                    src={
                                      student?.profile_image !== null
                                        ? baseUrl + "/api/" + student?.profile_image
                                        : Avatar1
                                    }
                                    width={40}
                                    alt={student?.first_name}
                                    style={{ borderRadius: "5px" }}
                                  />
                                  <span
                                    className="mr-lf10 truncate name_bold mar_ri_name "
                                    data-tooltip-id={`name-tooltip-${student?.id}`}
                                    data-tooltip-content={student?.first_name}
                                    data-tooltip-place="bottom"
                                  >
                                    {student?.first_name} {student?.last_name}
                                  </span>
                                  {
                                    student?.first_name.length > 18 &&
                                    <Tooltip id={`name-tooltip-${student?.id}`} />
                                  }

                                </span>
                              </div>
                              <div
                                className="sp-td-content flex-2 extra_flex2_cusname truncate click-popup padr_16"
                                onClick={() => {
                                  openStudentPopup(student);
                                }}
                              >
                                <span
                                  className="ft-400 truncate "
                                  data-tooltip-id={`father-tooltip-${student?.id}`}
                                  data-tooltip-content={student?.father_name}
                                  data-tooltip-place="bottom"
                                >
                                  {student?.father_name}
                                </span>
                                {
                                  student?.father_name.length > 18 &&
                                  <Tooltip id={`father-tooltip-${student?.id}`} />
                                }
                                {/* <Tooltip id={`father-tooltip-${student?.id}`} /> */}
                              </div>

                              <div
                                className="sp-td-content flex-2 extra_flex2_cusname truncate click-popup padr_16"
                                onClick={() => {
                                  openStudentPopup(student);
                                }}
                              >
                                <span
                                  className="ft-400 click-popup truncate "
                                  data-tooltip-id={`mother-tooltip-${student?.id}`}
                                  data-tooltip-content={student?.mother_name}
                                  data-tooltip-place="bottom"
                                >
                                  {student?.mother_name}
                                </span>
                                {
                                  student?.mother_name.length > 18 &&
                                  <Tooltip id={`mother-tooltip-${student?.id}`} />
                                }
                                {/* <Tooltip id={`mother-tooltip-${student?.id}`} /> */}
                              </div>
                              <div
                                className="sp-td-content flex-2 click-popup extra_flex2_cusname"
                                onClick={() => {
                                  openStudentPopup(student);
                                }}
                              >
                                <span className="ft-400">
                                  {student?.primary_phone}
                                </span>
                              </div>
                              <div
                                className="sp-td-content flex-2 click-popup extra_flex2_cusname"
                                onClick={() => {
                                  openStudentPopup(student);
                                }}
                              >
                                <span className="ft-400">
                                  {student?.secondary_phone}
                                </span>
                              </div>
                              <div
                                className="sp-td-content flex-3 click-popup"
                                onClick={() => {
                                  openStudentPopup(student);
                                }}
                              >
                                <span className="ft-400 text-ellp">
                                  <span className="sp-tag-selected">
                                    {student?.primary_email}
                                  </span>
                                  <span className="sp-tag-selected mr-lf10">
                                    {student?.secondary_email}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : studentData.length === 0 ? (
                        <center><div className="sp-title flex-3">Data Not Found</div></center>
                      ) : (
                        <div className="data-loader extracss_load">
                          {/* <Loader
                          type="spinner-cub"
                          bgColor="#4D6EE3"
                          color="#6B6F8C"
                          title="Loading..."
                          size={35}
                        /> */}
                          <WindmillSpinner loading={"loading"} color="#4d6ee3d6" size={30} />
                          <p>Loading...</p>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="popup-modal-container" onSubmit={onSubmit}>
          <div className="popup-wrap">
            {studentId !== null ? (
              <h2 className="ft-wt700">Update Student</h2>
            ) : (
              <h2 className="ft-wt700">Create New Student</h2>
            )}
            <div className="sp-pop-header">
              <h3 className="ft-wt600">Student Details</h3>
              <div className="form-section-default flexc justifySp fwrap">
                <div className="field-label half-field error_mes_cus pos_stu_cus">
                  <label>Name<span className="col_red">*</span></label>
                  <div className="">
                    <input
                      ref={firstNameInputRef}
                      type="text"
                      value={user.first_name}
                      onChange={(ev) =>
                        setUser({ ...user, first_name: ev.target.value })
                      }
                      onKeyPress={(event) =>
                        handleKeyPress(event, genderInputRef)
                      }
                      className={`field-value-holder custamize_color_place  extra_bor_clr  sp-field-input ${validationErrors.first_name ? 'error' : ''}`}
                      placeholder="Enter full name"
                    />
                  </div>
                  {validationErrors.first_name && (
                    <p className="error pos_abo_stu">{validationErrors.first_name}</p>
                  )}
                </div>
                <div className="field-label half-field error_mes_cus pos_stu_cus" style={{ padding: "0px" }}>
                  <label>Gender<span className="col_red">*</span></label>
                  <div
                    className="field-value-holder dropbutton"
                    ref={genderInputRef}
                    style={{
                      width: "100%",
                      borderColor: validationErrors.gender ? "red" : borderColor.genderBorder,
                    }}
                    onKeyPress={(event) =>
                      handleKeyPress(event, programInputRef)
                    }
                    onFocus={() => handleFocus("gender")}
                    onBlur={handleBlur}
                    tabIndex="0"
                  >
                    <Dropdown
                      label={
                        <span
                          className={`${selectedGender ? 'selected-item' : ''}`}
                          style={{ fontSize: "13px", justifyContent: "flex-start", color: selectedGender ? '#1B252F' : '#A9AFC3' }}
                        >
                          {selectedGender ? selectedGender.value : "Select Gender"}
                        </span>
                      }
                      style={{ width: "100%" }}
                    >
                      {gender1.map((item) => (
                        <Dropdown.Item
                          key={item.id}
                          className={`${selectedGender && selectedGender.id === item.id ? "selected-item dd_select" : ""
                            }`}
                          icon={selectedGender && selectedGender.id === item.id ? HiCheck : null}
                          onClick={() => setUser({ ...user, gender: item.value })}
                        >
                          {item.value}
                        </Dropdown.Item>
                      ))}
                    </Dropdown>
                  </div>
                  {validationErrors.gender && (
                    <p className="error pos_abo_stu">{validationErrors.gender}</p>
                  )}
                </div>
                <div className="field-label half-field error_mes_cus pos_stu_cus date_pic_img">
                  <label>
                    Date of Birth<span className="col_red">*</span>
                  </label>
                  <div
                    className={`field-value-holder ${validationErrors.dob ? 'error' : ''}`}>
                    <div>
                      <DatePicker
                        ref={dobInputRef}
                        placeholder="DD/MM/YYYY"
                        format={dateFormatList}
                        onChange={ondobChange}
                        value={user.dob ? dayjs(user.dob, dateFormatList) : null}
                      />

                    </div>
                  </div>
                  {validationErrors.dob && <p className="error pos_abo_stu">{validationErrors.dob}</p>}
                </div>
                <div className="field-label half-field error_mes_cus pos_stu_cus">
                  <label>Programs<span className="col_red">*</span></label>
                  <div
                    ref={programInputRef}
                    className="field-value-holder dropbutton"
                    style={{
                      width: "100%",
                      borderColor: validationErrors.programs_id ? "red" : borderColor.programsBorder,
                    }}
                    onKeyPress={(event) =>
                      handleKeyPress(event, studentInputRef)
                    }
                    onFocus={() => handleFocus("programs")}
                    onBlur={handleBlur}
                    tabIndex="0"
                  >
                    <Dropdown
                      label={
                        <span
                          className={`${selectedProgram ? 'selected-item' : ''}`}
                          style={{
                            fontSize: "13px",
                            color: selectedProgram ? '#1B252F' : '#A9AFC3'
                          }}
                        >
                          {selectedProgram ? selectedProgram.program_name : "Select a Program"}
                        </span>
                      }
                      style={{ width: "100%" }}
                    >
                      {programs.map((item) => (
                        <Dropdown.Item
                          key={item.id}
                          className={`${selectedProgram && selectedProgram.id === item.id ? "selected-item dd_select" : ""}`}
                          icon={selectedProgram && selectedProgram.id === item.id ? HiCheck : null}
                          onClick={() => setUser({ ...user, programs_id: parseInt(item.id) })}
                        >
                          {item.program_name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown>
                  </div>
                  {validationErrors.programs_id && (
                    <p className="error pos_abo_stu">
                      {validationErrors.programs_id}
                    </p>
                  )}
                </div>
                <div className="field-label half-field error_mes_cus ">
                  <label>Student Photo</label>
                  <div className="field-value-holder">
                    <span className="sp-upload-btn flexc justifySp" style={{
                      color: " #A9AFC3",
                      fontWeight: "500",
                      fontSize: "13px"
                    }}>
                      {selectedImage === null ? (
                        "Upload"
                      ) : (
                        <p style={{
                          color: " #1B252F",
                          fontWeight: "600",
                          fontSize: "13px"
                        }} className="truncate"> {selectedImage.name}</p>
                      )}
                      <button className="sp-file-browse">Browse</button>
                    </span>
                    <input
                      className="input-placeholder1"
                      style={{
                        cursor: "pointer"
                      }}
                      ref={imageInputRef}
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      onKeyPress={(event) =>
                        handleKeyPress(event, joingInputRef)
                      }
                    />
                  </div>
                  {/* {validationErrors.profile_image && (
                    <p className="error">
                      {validationErrors.profile_image}
                    </p>
                  )} */}
                </div>
                <div className="field-label half-field error_mes_cus date_pic_img">
                  <label>Joining Date</label>
                  <div className="field-value-holder">
                    <DatePicker
                      ref={joingInputRef}
                      placeholder="DD/MM/YYYY"
                      value={user.joining_date ? dayjs(user.joining_date, dateFormatList) : null}
                      format={dateFormatList}
                      onChange={ondojChange}
                    >
                    </DatePicker>
                  </div>
                  {/* {validationErrors.joining_date && (
                    <p className="error">
                      {validationErrors.joining_date}
                    </p>
                  )} */}
                </div>
                <div className="field-label half-field error_mes_cus ">
                  <label>Student ID</label>
                  <div className="field-value-holder custamize_color_place">
                    <input
                      ref={studentInputRef}
                      type="text"
                      value={user.studentID}
                      onChange={(ev) =>
                        setUser({ ...user, studentID: ev.target.value })
                      }
                      onKeyPress={(event) =>
                        handleKeyPress(event, bloodInputRef)
                      }
                      className={`sp-field-input`}
                      placeholder="001"
                    />
                  </div>
                </div>
                <div className="field-label half-field error_mes_cus " style={{ padding: "0px" }}>
                  <label>Blood Group</label>
                  <div className="field-value-holder dropbutton" ref={bloodInputRef}
                    style={{
                      width: "100%",
                      borderColor: borderColor.bloodGroupBorder,
                    }}
                    onKeyPress={(event) =>
                      handleKeyPress(event, adressInputRef)
                    }
                    onFocus={() => handleFocus("bloodGroup")}
                    onBlur={handleBlur}
                    tabIndex="0"
                  >
                    <Dropdown
                      label={
                        <span
                          style={{
                            fontSize: "0.8825em",
                            color: selectedBlood ? '#1B252F' : '#A9AFC3',
                            justifyContent: "flex-start"
                          }}
                        >
                          {selectedBlood ? selectedBlood.value : "Select Blood Group"}
                        </span>
                      }
                      style={{ width: "100%" }}
                    >
                      {Blood1.map((item) => (
                        <Dropdown.Item
                          key={item.id}
                          className={`${selectedBlood && selectedBlood.id === item.id ? "selected-item dd_select" : ""
                            }`}
                          icon={selectedBlood && selectedBlood.id === item.id ? HiCheck : null}
                          onClick={() => setUser({ ...user, blood_group: item.value })}
                        >
                          {item.value}
                        </Dropdown.Item>
                      ))}

                    </Dropdown>
                  </div>
                  {/* {validationErrors.blood_group && (
                    <p className="error">
                      {validationErrors.blood_group}
                    </p>
                  )} */}
                </div>
                <div className="field-label full-field error_mes_cus">
                  <label>Address<span className="col_red">*</span></label>
                  <div className="">
                    <input
                      type="text"
                      ref={adressInputRef}
                      value={user.address}
                      onChange={(ev) =>
                        setUser({ ...user, address: ev.target.value })
                      }
                      className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.address ? 'error' : ''}`}
                      placeholder="Address"
                      defaultValue={""}
                      onKeyPress={(event) =>
                        handleKeyPress(event, fathernameInputRef)
                      }
                    />
                  </div>
                  {validationErrors.address && (
                    <p className="error">{validationErrors.address}</p>
                  )}
                </div>
              </div>
              <h3 className="ft-wt600 mt-30">Father Details</h3>
              <div className="form-section-default flexc justifySp fwrap">
                <div className="field-label half-field error_mes_cus pos_stu_cus">
                  <label>Name<span className="col_red">*</span></label>
                  <div className="">
                    <input
                      ref={fathernameInputRef}
                      type="text"
                      value={user.father_name}
                      onChange={(ev) =>
                        setUser({ ...user, father_name: ev.target.value })
                      }
                      className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.father_name ? 'error' : ''}`}
                      placeholder="Enter Full name"
                      onKeyPress={(event) =>
                        handleKeyPress(event, fatherphoneInputRef)
                      }
                    />
                  </div>
                  {validationErrors.father_name && (
                    <p className="error pos_abo_stu">
                      {validationErrors.father_name}
                    </p>
                  )}
                </div>
                <div className="field-label half-field error_mes_cus pos_stu_cus">
                  <label>Phone Number<span className="col_red">*</span></label>
                  <div className="">
                    <input
                      ref={fatherphoneInputRef}
                      type="text"
                      value={user.primary_phone}
                      onChange={(ev) =>
                        setUser({ ...user, primary_phone: ev.target.value })
                      }
                      className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.primary_phone ? 'error' : ''}`}
                      placeholder="Phone Number"
                      onKeyPress={(event) =>
                        handleKeyPress(event, fatheremailInputRef)
                      }
                    />
                  </div>
                  {validationErrors.primary_phone && (
                    <p className="error pos_abo_stu">
                      {validationErrors.primary_phone}
                    </p>
                  )}
                </div>
                <div className="field-label half-field error_mes_cus ">
                  <label>Email</label>
                  <div className="">
                    <input
                      ref={fatheremailInputRef}
                      type="text"
                      value={user.primary_email}
                      onChange={(ev) =>
                        setUser({ ...user, primary_email: ev.target.value })
                      }
                      onKeyPress={(event) =>
                        handleKeyPress(event, mathernameInputRef)
                      }
                      className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input`}
                      placeholder="Enter your email"
                    />
                  </div>
                  {/* {validationErrors.primary_email && (
                    <p className="error">
                      {validationErrors.primary_email}
                    </p>
                  )} */}
                </div>
              </div>
              <h3 className="ft-wt600 mt-30">Mother Details</h3>
              <div className="form-section-default flexc justifySp fwrap">
                <div className="field-label half-field error_mes_cus pos_stu_cus">
                  <label>Name<span className="col_red">*</span></label>
                  <div className="">
                    <input
                      ref={mathernameInputRef}
                      type="text"
                      value={user.mother_name}
                      onChange={(ev) =>
                        setUser({ ...user, mother_name: ev.target.value })
                      }
                      onKeyPress={(event) =>
                        handleKeyPress(event, matherphoneInputRef)
                      }
                      className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.mother_name ? 'error' : ''}`}
                      placeholder="Enter Full name"
                    />
                  </div>
                  {validationErrors.mother_name && (
                    <p className="error pos_abo_stu">
                      {validationErrors.mother_name}
                    </p>
                  )}
                </div>
                <div className="field-label half-field error_mes_cus pos_stu_cus">
                  <label>Phone Number<span className="col_red">*</span></label>
                  <div className="">
                    <input
                      ref={matherphoneInputRef}
                      type="text"
                      value={user.secondary_phone}
                      onChange={(ev) =>
                        setUser({ ...user, secondary_phone: ev.target.value })
                      }
                      onKeyPress={(event) =>
                        handleKeyPress(event, matheremailInputRef)
                      }
                      className={`field-value-holder custamize_color_place extra_bor_clr sp-field-input ${validationErrors.secondary_phone ? 'error' : ''}`}
                      placeholder="Phone Number"
                    />
                  </div>
                  {validationErrors.secondary_phone && (
                    <p className="error pos_abo_stu">
                      {validationErrors.secondary_phone}
                    </p>
                  )}
                </div>
                <div className="field-label half-field error_mes_cus ">
                  <label>Email</label>
                  <div className="field-value-holder custamize_color_place">
                    <input
                      ref={matheremailInputRef}
                      type="text"
                      value={user.secondary_email}
                      onChange={(ev) =>
                        setUser({ ...user, secondary_email: ev.target.value })
                      }
                      className={`sp-field-input`}
                      placeholder="Enter your email"
                      onKeyDown={(event) =>
                        handleKeyPress(event, submitButtonRef)
                      }
                    />
                  </div>
                  {/* {validationErrors.secondary_email && (
                    <p className="error">
                      {validationErrors.secondary_email}
                    </p>
                  )} */}
                </div>
              </div>
            </div>
            <div className="sp-bottom-sticky">
              {studentId === null ? (
                <button
                  className="sp-btn-create"
                  ref={submitButtonRef}
                  onClick={onSubmit}
                >
                  Create
                </button>
              ) : (
                <button className="sp-btn-create" onClick={updateUser}>
                  Update
                </button>
              )}
              <button className="sp-btn-cancel">Cancel</button>
            </div>
          </div>
        </div>
        {/* view New Student */}
        <div className="popup-student-details">
          <span className="close icon-Dropdown_Close flexc cp" />
          <div className="student-details-page">
            <div className="sp-top-details flexc">
              <div className="sp-profile-head">
                <img
                  src={
                    displayUser.profile_image !== null
                      ? baseUrl + "/api/" + displayUser.profile_image
                      : Avatarprofile
                  }
                  alt={displayUser.first_name}
                  width={62}
                  height={62}
                  style={{ borderRadius: '30%' }}
                />
                <span className="flexc margin_20p">
                  <em className="ft-wt600 ft-18">{displayUser.first_name}</em>
                  <label className="sp-status">check In</label>
                </span>
              </div>
              <div className="sp-profile-rt flexc">
                <span
                  className="sp-accbox mr-lf10 flexc"
                  style={{ borderRadius: "50%" }}
                  onClick={() => {
                    openStudentUpdatePopup(displayUser);
                  }}
                ><img style={{ borderRadius: "50%" }} src={Edit} /></span>
              </div>
            </div>
            <div className="sp-details-box">
              <div className="sp-tt ft-14 flexc ft-wt600">
                <i className="icon-Invites-Outline" />
                Student Details
              </div>
              <div className="form-section-default flexc justifySp fwrap">
                <div className="field-label half-field">
                  <label className="label-title">Gender</label>
                  <div className="label-filled">{displayUser.gender}</div>
                </div>
                <div className="field-label half-field">
                  <label className="label-title">Student ID</label>
                  <div className="label-filled">{displayUser.studentID}</div>
                </div>
                <div className="field-label half-field">
                  <label className="label-title">Admission Date</label>
                  <div className="label-filled">
                    {convertDBDateFormat(String(displayUser.joining_date).split("T")[0])}
                  </div>
                </div>
                <div className="field-label half-field">
                  <label className="label-title">Date of Birth</label>
                  <div className="label-filled">
                    {convertDBDateFormat(String(displayUser.dob).split("T")[0])}
                  </div>
                </div>
                <div className="field-label half-field">
                  <label className="label-title">Blood Group</label>
                  <div className="label-filled">{displayUser.blood_group}</div>
                </div>
                <div className="field-label full-field">
                  <label className="label-title">Address</label>
                  <div className="label-filled">{displayUser.address}</div>
                </div>
              </div>
            </div>
            {/* <div className="sp-details-box">
              <div className="sp-tt ft-14 flexc ft-wt600">
                <i className="icon-Invites-Outline" />
                Contact Detail
              </div>
              <div className="form-section-default flexc justifySp fwrap">
                <div className="field-label full-field">
                  <label className="label-title">
                    <b>Father Details :</b>
                  </label>
                  <div className="form-section-default flexc justifySp fwrap">
                    <div className="field-label half-field">
                      <label className="label-title">Father Name</label>
                      <div className="label-filled">
                        {displayUser.father_name}
                      </div>
                    </div>
                    <div className="field-label half-field">
                      <label className="label-title">Father Number</label>
                      <div className="ft-500 ft-14 label-filled">
                        {displayUser.primary_phone}
                      </div>
                    </div>
                    <div className="field-label half-field">
                      <label className="label-title">Father Email</label>
                      <div className="ft-400 ft-13 dis-block margin_top10p label-filled">
                        {displayUser.primary_email}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field-label full-field">
                  <label className="label-title">
                    <b>Mother Details :</b>
                  </label>
                  <div className="form-section-default flexc justifySp fwrap">
                    <div className="field-label half-field">
                      <label className="label-title">Mother Name</label>
                      <div className="label-filled">
                        {displayUser.mother_name}
                      </div>
                    </div>
                    <div className="field-label half-field">
                      <label className="label-title">Mother Number</label>
                      <div className="ft-500 ft-14 label-filled">
                        {displayUser.secondary_phone}
                      </div>
                    </div>
                    <div className="field-label half-field">
                      <label className="label-title">Mother Email</label>
                      <div className="ft-400 ft-13 dis-block margin_top10p label-filled">
                        {displayUser.secondary_email}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div class="sp-details-box">
              <div class="sp-tt ft-14 flexc ft-wt600"><i class="icon-Invites-Outline"></i>Contact Detail</div>
              <div class="form-section-default flexc justifySp fwrap">
                <div class="field-label full-field">
                  <label class="label-title">Father</label>
                  <div class="flexc alignT mt-10 label-fill-value">
                    <span class="label-prf-img"><img src={Avatarprofile} /></span>
                    <span class="mr-lf10">
                      <em class="ft-500 ft-14 update_list_det"> {displayUser.father_name}</em>
                      <em class="ft-400 ft-13 dis-block mt-10 update_list_det1">{displayUser.primary_phone}</em>
                    </span>
                  </div>
                </div>
                <div class="field-label full-field">
                  <label class="label-title">Mother</label>
                  <div class="flexc alignT mt-10 label-fill-value">
                    <span class="label-prf-img"><img src={Avatarprofile} /></span>
                    <span class="mr-lf10">
                      <em class="ft-500 ft-14 update_list_det">{displayUser.mother_name}</em>
                      <em class="ft-400 ft-13 dis-block mt-10 update_list_det1">{displayUser.secondary_phone}</em>
                    </span>
                  </div>
                </div>

              </div>

            </div>
            <div className="sp-details-box">
              <div className="sp-tt ft-14 flexc ft-wt600">
                <i className="icon-Invites-Outline" />
                Attendance Detail
              </div>
              <div className="sp-dashboard">
                <img src={Avatardata} width={100} alt="panda" />
              </div>
              <div className="field-label-op">
                <div className="flexc justifySp  margin_20p">
                  <span className="flexc ft-13">
                    <span className="sp-lpdr icon-sp-present mr-rt10" />
                    Present
                  </span>
                  <span className="ft-13">130 Days</span>
                </div>
                <div className="flexc justifySp  margin_20p">
                  <span className="flexc ft-13">
                    <span className="cross-ele mr-rt10" />
                    Leave
                  </span>
                  <span className="ft-13">20 Days</span>
                </div>
                <div className="flexc justifySp  margin_20p">
                  <span className="flexc ft-13">
                    <span className="notapp-ele mr-rt10" />
                    Leave
                  </span>
                  <span className="ft-13">20 Days</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Recycle Bin Modal */}
        {
          isRecycleBinVisible && (
            <div
              id="recycle-popup"
              tabIndex={-1}
              className="back_overlay fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full  max-h-full res_tot_div"
            >
              <div className="relative w-full max-w-xs max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-4">
                  {/* <button
                    type="button"
                    onClick={handleRecycleBinClose}
                    className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-hide="recycle-popup"
                  >
                    <svg
                      className="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button> */}
                  <div className="p-4 md:p-5 text-center">
                    {/* <img src={DeleteIcon} alt="Delete" style={{ width: '24px', height: '24px', marginRight: '8px' }} /> */}
                    <div className="round-btn rounded inline-flex alignC justify-center">
                      {/* <i className="icon-delete" /> */}
                      <img src={de} alt="" />
                    </div>
                    <p className="ft-wt600 ft-16 mb-4 mt-3">Recycle Bin</p>
                    <h3 className="mb-5 font-normal text-sm">
                      Are you sure you want to move this student to Recycle Bin?
                    </h3>
                    <button
                      onClick={handleSaveRecycleBin}
                      type="button"
                      className="yes_button_sta_cus sp-red-color text-white  hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 rounded-md  inline-flex items-center px-5 py-2.5 text-center"
                    >
                      Yes, Move
                    </button>
                    <button
                      onClick={handleRecycleBinClose}
                      type="button"
                      style={{
                        border: "1px solid #D7D9DD",
                      }}
                      className="can_cus_rec border-full ms-3 py-2.5 px-5  text-gray-900 focus:outline-none bg-white rounded-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700"
                    >
                      Cancel
                    </button>

                  </div>
                </div>
              </div>
            </div>
          )
        }
        {/* New Program Popup */}
        {
          isNewProgramVisible && (
            <div
              id="newprogram-popup"
              tabIndex={-1}
              className="back_overlay fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full max-h-full res_tot_div"
            >
              <div className="relative w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 p-4">
                  <div className="p-4 md:p-5">
                    <p className="ft-wt600 ft-16 mb-4">New Program</p>
                    <div className="mb-5">
                      <label className="mb-5 font-normal text-sm">Programs</label>
                      <div
                        className="field-value-holder dropbutton"
                        style={{
                          width: "100%",
                          borderColor: newProgramError ? "red" : borderColor.newProgram,
                        }}
                        onKeyPress={handleDropdownKeyPress}
                        onFocus={() => handleFocus("newProgram")}
                        onBlur={handleBlur}
                      >
                        <Dropdown
                          label={
                            <span
                              style={{ color: "#707070", fontSize: "0.8825em" }}
                            >
                              {selectedNewProgram
                                ? selectedNewProgram.program_name
                                : "Select a Program"}
                            </span>
                          }
                          style={{ width: "100%" }}
                        >
                          {programs.map((item) => (
                            <Dropdown.Item
                              // className={
                              //   selectedNewProgram && selectedNewProgram.id === item.id
                              //     ? "dd_select"
                              //     : null
                              // }
                              className={`${selectedNewProgram && selectedNewProgram.id === item.id ? "selected-item dd_select" : ""}`}
                              icon={
                                selectedNewProgram && selectedNewProgram.id === item.id
                                  ? HiCheck
                                  : null
                              }
                              onClick={() =>
                                setNewProgram(parseInt(item.id))
                              }
                              key={item.id}
                            >
                              {item.program_name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown>
                      </div>
                      {newProgramError && (
                        <span className="error">
                          {newProgramError}
                        </span>
                      )}
                    </div>
                    <div className="text-right but_new_cu">
                      <button
                        onClick={handleNewProgramClose}
                        type="button"
                        style={{
                          border: "1px solid #D7D9DD",
                        }}
                        className="can_pro_but border-full py-2.5 px-5 ft-13 text-gray-900 focus:outline-none bg-white rounded-md border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 cancel_div_di "
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleSaveNewProgram}
                        type="button"
                        className="save_button_cus sp-blue-color text-white ms-3 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 rounded-md ft-13 inline-flex items-center px-5 py-2.5 text-center save_cus_di"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }

        {/* Assign Teacher */}
        {
          assignTeacherVisible && (
            <div id="assign-teacher" ref={popupRef} className="z-10 bg-white rounded-lg shadow w-full dark:bg-gray-700 border-full media_style_pop" style={assignStyle}>
              <div className="icon_clr_ser" style={{ paddingBottom: "10px" }}>
                <h3 className="ft-14 ft-wt600 p-4">Assign Teacher</h3>
                <div className={`btn-search-bar flexc  ${isAddTeacherFocused ? 'focused' : ''}`}>
                  <i className="icon-Search" />
                  <input
                    type="search"
                    placeholder="Search user..."
                    value={searchAssignTeacherQuery}
                    onChange={handleAssignTeacherSearch}
                    onFocus={() => setIsAddTeacherFocused(true)}
                    onBlur={() => setIsAddTeacherFocused(false)}
                    className="search-input"
                  />
                </div>
              </div>
              <ul className="search-items overflow-y-auto cus_div_inputas">
                {filteredAssignTeacher.map(user => {
                  const isDisabled = teachersData.filter(teacher =>
                    teacher.assign_classes !== null &&
                    teacher.assign_classes.split(",").includes(localStorage.getItem("programId"))
                  ).length === 1 &&
                    user.assign_classes !== null &&
                    user.assign_classes.split(",").includes(localStorage.getItem("programId"));

                  return (
                    <li key={user.id}
                      style={{
                        cursor: isDisabled ? 'not-allowed' : 'pointer',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                      onClick={() => {
                        if (!isDisabled) {
                          handleAssignTeachers(user.id, !user.assign_classes?.split(",").includes(localStorage.getItem("programId")));
                        }
                      }}>
                      <div
                        className="table_input_sty"

                      >
                        <input
                          style={{ pointerEvents: 'none' }} // To prevent checkbox from interfering with div click
                          type="checkbox"
                          className="sub_select"
                          disabled={isDisabled}
                          checked={user.assign_classes !== null && user.assign_classes.split(",").includes(localStorage.getItem("programId"))}
                        />
                        <span className="text-xsmall small-tag">{user.first_name.slice(0, 1).toUpperCase()}</span>
                        <span className="ft-12 ft-wt500 size_div_pad fir_size_div">{user.first_name}</span>
                        <em>.</em>
                        <span className="ft-12 size_div_pad sec_size_div">{user.email}</span>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          )
        }
        {/* Create New Program */}
        {
          newProgramPopupVisible && (
            <div className="popup-program-container">
              <div className="popup-wrap">
                <h2 className="ft-wt700">Create New Program</h2>
                <div className="sp-pop-header ">
                  <div className="form-section-default flexc justifySp fwrap">
                    <div className="field-label full-field error_mes_cus">
                      <label>Program Name</label>
                      <div className="field-value-holder margin_top10p">
                        <input
                          className="sp-field-input"
                          type="text"
                          placeholder="Enter Program name"
                          value={newProgramName}
                          onChange={(e) => setNewProgramName(e.target.value)}
                        />
                      </div>
                      {programeNameError && <p className="error">{programeNameError}</p>}
                    </div>
                    <div className="field-label half-field error_mes_cus">
                      <label>Program Teachers</label>
                      <div className="field-value-holder margin_top10p dropbutton" >
                        <Dropdown


                          label={
                            <span
                              className={`${selectedTeachers ? 'selected-item' : ''}`}
                              style={{
                                fontSize: "13px",
                                justifyContent: "flex-start",
                                color: selectedTeachers ? '#1B252F' : '#A9AFC3'
                              }}
                            >
                              {selectedTeachers ? selectedTeachers.first_name : "Select Teacher"}</span>}
                          style={{ width: '100%' }}
                        >

                          {teachersData.map(item => (
                            <Dropdown.Item
                              // className={selectedTeachers && selectedTeachers.id === item.id ? "dd_select" : null}
                              className={`${selectedTeachers && selectedTeachers.id === item.id ? "selected-item dd_select" : ""
                                }`}
                              icon={selectedTeachers && selectedTeachers.id === item.id ? HiCheck : null}
                              onClick={() => setTeacher(item.id)}
                              key={item.id}
                            >
                              {item.first_name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown>

                      </div>
                      {programeTeacherError && <p className="error">{programeTeacherError}</p>}
                    </div>
                    <div className="field-label half-field error_mes_cus">
                      <label>Class Timing</label>
                      <div className="flexc justifySp">
                        <div className="half-field">
                          <div className="field-value-holder margin_top10p dropbutton cus_drop_siz" >
                            <Dropdown
                              label={


                                <span
                                  className={`${selectedStartTime ? 'selected-item' : ''}`}
                                  style={{
                                    fontSize: "12px",
                                    justifyContent: "flex-start",
                                    color: selectedStartTime ? '#1B252F' : '#A9AFC3'
                                  }}
                                >

                                  {/* <span style={{ color: 'black', fontSize: '12px' }}> */}



                                  <i class="fa-regular fa-clock" style={{ marginRight: '6PX' }}></i>
                                  {selectedStartTime ? selectedStartTime.time_name : "Start Time"}</span>}
                              style={{
                                width: "100%"
                              }}
                            >
                              {timeData.map(item => (
                                <Dropdown.Item

                                  //className={selectedStartTime && selectedStartTime.id === item.id ? "dd_select" : null}

                                  className={`${selectedStartTime && selectedStartTime.id === item.id ? "selected-item dd_select" : ""
                                    }`}
                                  icon={selectedStartTime && selectedStartTime.id === item.id ? HiCheck : null}
                                  onClick={() => setProgramStartTime(item.id)}
                                  key={item.id}
                                >
                                  {item.time_name}
                                </Dropdown.Item>
                              ))}
                            </Dropdown>
                          </div>
                        </div>
                        <div className="half-field">
                          <div className="field-value-holder margin_top10p dropbutton cus_drop_siz" >
                            <Dropdown
                              label={


                                //  <span style={{ color: 'black', fontSize: '12px' }}>

                                <span
                                  className={`${selectedEndTime ? 'selected-item' : ''}`}
                                  style={{


                                    fontSize: "13px",
                                    fontWeight: "400",
                                    lineHeight: "18.2px",
                                    textAlign: "left",

                                    justifyContent: "flex-start",
                                    color: selectedEndTime ? '#1B252F' : '#A9AFC3'
                                  }}
                                >



                                  <i class="fa-regular fa-clock" style={{ marginRight: '6PX' }}>
                                  </i>{selectedEndTime ? selectedEndTime.time_name : "End Time"}</span>}
                              style={{
                                width: "100%"
                              }}
                            >
                              {timeData.map(item => (
                                <Dropdown.Item
                                  // className={selectedEndTime && selectedEndTime.id === item.id ? "dd_select" : null}
                                  className={`${selectedEndTime && selectedEndTime.id === item.id ? "selected-item dd_select" : ""
                                    }`}

                                  icon={selectedEndTime && selectedEndTime.id === item.id ? HiCheck : null}
                                  onClick={() => setProgramEndTime(item.id)}
                                  key={item.id}
                                >
                                  {item.time_name}
                                </Dropdown.Item>
                              ))}
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                      {timeError && <p className="error">{timeError}</p>}
                    </div>
                  </div>

                  <h3 className="ft-wt600 mt-30">Customise Features</h3>
                  <div className="full-field mb-6">
                    {Object.keys(features).map((feature) => (
                      <div className="flex items-center mb-4 custom-checkbox dis_chek_cus mt-5" key={feature}>
                        <input
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 rounded"
                          checked={features[feature].status}

                          disabled={
                            (['qrCode', 'attendance', 'food', 'sleep', 'leaves'].includes(feature))
                            // features[feature].status=== true
                          }
                          onChange={() => handleFeatureChange(feature)}
                        />
                        <label className="ms-2 text-sm">{features[feature].name}</label>
                        {['busTracking', 'cctv', 'fees'].includes(feature) && <img src={SplImg} alt="spl" style={{ width: '25px', height: '15px', marginLeft: "5px" }} />}
                      </div>
                    ))}
                  </div>
                  <h3 className="ft-wt600 mt-30">Additional Informations</h3>
                  <div className="full-field">
                    {Object.keys(additionalInfo).map((info) => (
                      <div className="flex items-center mb-4 custom-checkbox mt-5" key={info}>
                        <input
                          type="checkbox"
                          className="w-4 h-4 text-blue-600 rounded"
                          checked={additionalInfo[info].status}
                          onChange={() => handleAdditionalInfoChange(info)}
                        />
                        <label className="ms-2 text-sm">{additionalInfo[info].name}</label>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="sp-bottom-sticky">
                  <button className="sp-btn-create crt_div" onClick={() => handleCreateProgram()}>Create Program</button>
                  <button className="sp-btn-cancel">Cancel</button>
                </div>
              </div>
            </div>
          )
        }
      </div >
    </section >
  );
}
