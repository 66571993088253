import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'; // Import icons from FontAwesome library
import { Toolbar } from 'react-big-calendar';

class CustomToolbar extends React.Component {
  
  state = {
    activeView: 'week', // State to track the active view
    isFocused: false,
    searchValue: ""
  };

  handleViewChange = (view) => {
    this.setState({ activeView: view }); // Update active view on button click
    this.props.onView(view); // Call parent handler for view change
  };

  render() {
    const { localizer: { messages }, label } = this.props;
    const { activeView } = this.state;

    const handleInputChange = async (event) => {
      this.setState({ searchValue: event.target.value });
    }

    return (
      <div className="rbc-toolbar">
        <div className='d-flex'>
          <div className={`btn-search-bar mr-10 flexc ${this.state.isFocused ? 'focused' : ''}`}>
            <i className="icon-Search" />
            <input
              type="search"
              placeholder="Search Event"
              value={this.state.searchValue}
              onChange={handleInputChange}
              onFocus={() => this.setState({ isFocused: true })}
              onBlur={() => this.setState({ isFocused: false })}
              className="search-input"
            />
          </div>
          <span className="rbc-btn-group">
            <button type="button" onClick={() => this.props.onNavigate('PREV')}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <button type="button" onClick={() => this.props.onNavigate('TODAY')}>
              {messages.today}
            </button>
            <button type="button" onClick={() => this.props.onNavigate('NEXT')}>
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </span>
        </div>
        <div className='d-flex'>
          <span className="rbc-toolbar-label">
            {label}
          </span>
          <span className="rbc-btn-group view-rbc-btn-group">
            <button type="button" className={activeView === 'week' ? 'rbc-active' : ''}
              onClick={() => this.handleViewChange('week')}>
              {messages.week}
            </button>
            <button type="button" className={activeView === 'month' ? 'rbc-active' : ''}
              onClick={() => this.handleViewChange('month')}>
              {messages.month}
            </button>
          </span>
        </div>
      </div>
    );
  }
}

export default CustomToolbar;
