import avatar from "./images/avatar1.png";
import React, { useState, useEffect } from "react";
import axios from 'axios';
import $ from "jquery";
import { useLocation, useNavigate } from "react-router-dom";
import { baseUrl } from "../../common/common";
import Loader from "react-js-loader";
import '../../common/components/css/style.css';
import avatar1 from "./images/avatar1.png";
import "../../index.css";
import Snackbar from '@mui/material/Snackbar';
import ListItemIcon from '@mui/material/ListItemIcon';
import SuccessIcon from "./images/success.png";
import ErrorIcon from "./images/error.png";
import SplImg from "./images/spl.png";
import { Dropdown } from "flowbite-react";
import { HiCheck } from "react-icons/hi";
import ImportComponent from "../Import/Import";
import tik from "./images/tik.svg";
import cross from "./images/cross.svg";
import leave from "./images/leave.svg";
import leave1 from "./images/leave1.svg";
import tik1 from "./images/tik1.svg";
import cross1 from "./images/cross1.svg";
import gift from "./images/gift.svg";
import upar from "./images/upar.svg";
import ques from "./images/ques.svg";
import { WindmillSpinner } from 'react-spinner-overlay';
import moment from 'moment';

export default function Staffattendance() {

    const navigate = useNavigate();
    const [programsData, setProgramsData] = useState({});
    const [programsLoading, setProgramsLoading] = useState(false);
    const fetchProgramsData = async () => {
        setProgramsLoading(false);
        try {
            const postData = {
                school_id: localStorage.getItem('schoolId')
                // school_id:24
            }
            const { data } = await axios.post(baseUrl + "/api/get_programs", postData, {
                headers: {
                    "Authorization": localStorage.getItem('access_token')
                }
            });
            setProgramsData(data.data);
            setProgramsLoading(true);
        } catch (error) {
            console.log(error)
        }
    }

    const [foodData, setFoodData] = useState({});
    const [dataHeader, setDataHeader] = useState([]);
    const [foodHeaderdata, setFoodHeaderData] = useState([]);
    const [foodLoading, setFoodLoading] = useState(false);

    const [filterValue, setFilterValue] = useState('');
    const handleInputChange = async (event) => {
        setFilterValue(event.target.value);
        try {
            axios({
                method: "post",
                url: baseUrl + "/api/staff_attendance_list_admin",
                data: {
                    school_id: localStorage.getItem('schoolId'),
                    search: event.target.value.trim(),
                    // programs_id: localStorage.getItem('programId'),
                    // list_status: 1
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                console.log(response);
                if (response['data']['status'] === true) {
                    setFoodData(response.data);
                    setDataHeader(response.data.dateresult);
                    setFoodHeaderData(response.data.data);
                    setFoodLoading(true);
                } else {
                    setFoodData(response.data);
                    setFoodLoading(true);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };
    const fetchAttendanceData = () => {
        setFoodLoading(false);
        try {
            axios({
                method: "post",
                url: baseUrl + "/api/staff_attendance_list_admin",
                data: {
                    school_id: localStorage.getItem('schoolId'),
                    search: ""
                    // programs_id: localStorage.getItem('programId'),
                    // list_status: 1
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                console.log(response);
                if (response['data']['status'] === true) {
                    setFoodData(response.data);
                    setDataHeader(response.data.dateresult);
                    setFoodHeaderData(response.data.data);
                    setFoodLoading(true);
                } else {
                    setFoodData(response.data);
                    setFoodLoading(true);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    const [selectedStaff, setselectedStaff] = useState([]);

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const allStudentIds = foodHeaderdata.map(student => student.id);
            setselectedStaff(allStudentIds);
        } else {
            setselectedStaff([]);
        }
    };

    const handleSelectStudent = (studentId) => {
        setselectedStaff(prevSelected =>
            prevSelected.includes(studentId)
                ? prevSelected.filter(id => id !== studentId)
                : [...prevSelected, studentId]
        );
    };

    const [error, setError] = useState(null);
    const addAttendance = (attendanceStatus) => {
        const today = new Date();
        if (selectedStaff.length === 0) {
            // setError("Please Select Student")
            // setOpen(true);
            setSnackbarMessage("Please Select Student");
            setMessageType('error');
            handleSnackbarOpen();
        } else {
            const postData = {
                school_id: localStorage.getItem('schoolId'),
                attendance_date: today.toISOString().split('T')[0],
                programs_id: localStorage.getItem('programId'),
                staff_ids: selectedStaff,
                attendance_status: attendanceStatus,
                status: 1,
                created_by: localStorage.getItem('schoolId'),
                updated_by: localStorage.getItem('schoolId')
            }
            console.log(postData)
            axios({
                method: "post",
                url: baseUrl + "/api/staff_attendance_add_edit",
                data: postData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                console.log(response);
                if (response.data.status === true) {
                    setselectedStaff([])
                    setSnackbarMessage(response.data.message);
                    setMessageType('success');
                    handleSnackbarOpen();
                    fetchAttendanceData();
                } else {
                    setSnackbarMessage(response.data.message);
                    setMessageType('error');
                    handleSnackbarOpen();
                }
            });
        }

    }

    const [open, setOpen] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    // ------11-06----------------
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [snackbarState, setSnackbarState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center'
    });
    const handleSnackbarClose = () => {
        setSnackbarState({ ...snackbarState, open: false });
    };
    const handleSnackbarOpen = () => {
        setSnackbarState({ ...snackbarState, open: true });
    };
    useEffect(() => { }, [snackbarMessage, messageType]);

    // -------------------------19-06-------------------------

    const handleOpenNewProgramPopupVisible = () => {
        setProgramNameError(null);
        setProgramTeacherError(null);
        setTimeError(null);
        setTeacher("");
        setProgramStartTime("");
        setProgramEndTime("");
        setNewProgramPopupVisible(true);
    }

    const [newProgramPopupVisible, setNewProgramPopupVisible] = useState(false);
    const [programStartTime, setProgramStartTime] = useState("");
    const [programEndTime, setProgramEndTime] = useState("");
    const [programTeacher, setTeacher] = useState("");
    const [timeData, setTimeData] = useState([]);
    const [teachersData, setTeachersData] = useState([]);
    const selectedStartTime = timeData.find(data => data.id === programStartTime);
    const selectedEndTime = timeData.find(data => data.id === programEndTime);
    const selectedTeachers = teachersData.find(data => data.id === programTeacher);

    const fetchProgramTimeData = async () => {
        axios({
            method: "post",
            url: baseUrl + "/api/get_time",
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setTimeData(response.data.data);
                console.log(response);
            }
        });
    }

    const fetchTeachersData = async () => {
        axios({
            method: "post",
            url: baseUrl + "/api/teacher_list",
            data: {
                school_id: localStorage.getItem("schoolId")
            },
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setTeachersData(response.data.data);
                console.log(response);
            }
        });
    }

    const [newProgramName, setNewProgramName] = useState('');
    const [features, setFeatures] = useState({
        qrCode: { status: true, name: 'QR Code', value: 2 },
        attendance: { status: true, name: 'Attendance', value: 5 },
        food: { status: true, name: 'Food', value: 3 },
        sleep: { status: true, name: 'Sleep', value: 4 },
        leaves: { status: true, name: 'Leaves', value: 6 },
        busTracking: { status: false, name: 'Bus Tracking', value: 7 },
        cctv: { status: false, name: 'CCTV', value: 8 },
        fees: { status: false, name: 'Fees', value: 9 },
    });
    const [additionalInfo, setAdditionalInfo] = useState({
        inviteParents: {
            status: true,
            name: 'Automatically Invite Parents as soon as student profile created',
            value: 1
        },
        addParentChat: {
            status: true,
            name: 'Add Parent in chat as soon as student profile created',
            value: 2
        },
        createStudentID: {
            status: true,
            name: 'Automatically create Student ID as soon as profile created',
            value: 3
        },
    });


    const handleFeatureChange = (feature) => {
        setFeatures({
            ...features,
            [feature]: {
                ...features[feature],
                status: !features[feature].status,
            },
        });
    };
    const handleAdditionalInfoChange = (info) => {
        setAdditionalInfo({
            ...additionalInfo,
            [info]: {
                ...additionalInfo[info],
                status: !additionalInfo[info].status,
            },
        });
    };

    const [programeNameError, setProgramNameError] = useState(null);
    const [programeTeacherError, setProgramTeacherError] = useState(null);
    const [timeError, setTimeError] = useState(null);
    const validateProgramForm = () => {
        let valid = true;
        if (newProgramName === "") {
            setProgramNameError("Please enter program name");
            valid = false;
        } else {
            setProgramNameError(null);
        }
        if (programTeacher === "") {
            setProgramTeacherError("Please select program teacher");
            valid = false;
        } else {
            setProgramTeacherError(null);
        }
        // if (programStartTime === "" || programEndTime === "") {
        //   setTimeError("Please select start and end time");
        //   valid = false;
        // } else {
        //   setTimeError(null);
        // }
        return valid;
    };

    const handleCreateProgram = () => {

        const selectedFeatureValues = Object.keys(features).filter((key) => features[key].status).map((key) => features[key].value);
        const selectedAdditionalInfoValues = Object.keys(additionalInfo).filter((key) => additionalInfo[key].status).map((key) => additionalInfo[key].value);

        console.log({
            school_id: localStorage.getItem("schoolId"),
            program_name: newProgramName,
            teacher_id: programTeacher,
            from_time_id: programStartTime,
            to_time_id: programEndTime,
            features: selectedFeatureValues.join(","),
            additional_info: selectedAdditionalInfoValues.join(","),
            status: 1,
            created_by: localStorage.getItem("loginUserId")
        });
        if (validateProgramForm()) {
            try {
                axios({
                    method: "post",
                    url: baseUrl + "/api/programs_add_edit",
                    data: {
                        school_id: localStorage.getItem("schoolId"),
                        program_name: newProgramName,
                        teacher_id: programTeacher,
                        from_time_id: programStartTime,
                        to_time_id: programEndTime,
                        features: selectedFeatureValues.join(","),
                        additional_info: selectedAdditionalInfoValues.join(","),
                        status: 1,
                        created_by: localStorage.getItem("loginUserId")
                    },
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": localStorage.getItem('access_token')
                    },
                }).then((response) => {
                    console.log(response);
                    if (response.data.status === true) {
                        $('.overlay').removeClass('visible');
                        $('.popup-program-container').removeClass('visible');
                        setNewProgramPopupVisible(false);
                        fetchProgramsData();
                        setSnackbarMessage(response.data.message);
                        setMessageType('success');
                        handleSnackbarOpen();
                    } else {
                        setSnackbarMessage(response.data.message);
                        setMessageType('error');
                        handleSnackbarOpen();
                    }
                });
            } catch (error) {
                console.log(error)
            }
        }

    };


    // -------------27-06-----------

    const [progress, setProgress] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [importstatus, setimportstatus] = useState(false);

    const importStudents1 = () => {
        setimportstatus(true);
    };

    const handleImportStatus = (status) => {
        if (status == true) {
            setimportstatus(false);
        }
    };

    // ----- 04-07  active date----------
    const inputDateObj = moment("Thu Jul 04 2024", 'ddd MMM DD YYYY').toDate();
    const currentDateObj = new Date();
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const formattedCurrentDate = formatDate(currentDateObj);


    useEffect(() => {
        fetchProgramsData();
        fetchTeachersData();
        fetchProgramTimeData();
        fetchAttendanceData();
        // $(document).ready(function() {
        //     let $checkAll = $('#check-all');
        //     let $otherCheckboxes = $('input[type=checkbox]:not(#check-all)');        
        //     $checkAll.on('change', onCheckAllChange);
        //     $otherCheckboxes.on('change', onOtherCheckboxChange);        
        //     function onCheckAllChange() {
        //         $otherCheckboxes.prop('checked', $checkAll.prop('checked'));
        //     }        
        //     function onOtherCheckboxChange() {
        //         let allChecked = $otherCheckboxes.toArray().every(function(input) {
        //             return $(input).prop('checked');
        //         });
        //         $checkAll.prop('checked', allChecked);
        //     }
        // });
    }, []);

    return (
        <section className="staff_atten_cus">
            <Snackbar
                anchorOrigin={{ vertical: snackbarState.vertical, horizontal: snackbarState.horizontal }}
                open={snackbarState.open}
                onClose={handleSnackbarClose}
                message={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ListItemIcon>
                            <img src={messageType === 'success' ? SuccessIcon : ErrorIcon} alt="Success" style={{ width: '24px', height: '24px', marginRight: '8px' }} />
                        </ListItemIcon>
                        <div className="success_cuc">
                            <p> {snackbarMessage} </p>
                        </div>
                    </div>
                }
                ContentProps={{
                    sx: {
                        border: "1px solid",
                        opacity: "1",
                        background: messageType === 'success' ? "#40BE61" : "#FD4954",
                        borderColor: messageType === 'success' ? "#40BE61" : "#FD4954",
                        width: '415px'
                    }
                }}
                autoHideDuration={3000}
            />
            <div className="outer-container">
                <div className="overlay" />
                <div id="left-panel" className="sp-left-panel">
                    <div className="sp-top-band">
                        <a className="sp-logo">
                            Scool Panda<sup>Beta</sup>
                        </a>
                        {/* <div className="sp-search-top flexc">
                            <i className="icon-Search" />
                            <input
                                id="search"
                                className="sp-search-btn"
                                placeholder="Quick search..."
                            />
                        </div> */}
                    </div>
                    <div className="sp-middle-band">
                        <div className="sp-nav y-auto">
                            <div className="">
                                <h4 className="sp-title">Quick Actions</h4>
                                <ul>
                                    <li onClick={() => { navigate('/dashboard'); }}>
                                        <span>
                                            <i className="icon-Dashboard-Outline" />
                                            Dashboard
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/activity'); }}>
                                        <span>
                                            <i className="icon-Activity-Outline" />
                                            Activity
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/calendar'); }}>
                                        <span>
                                            <i className="icon-Timetable-Outline" />
                                            Calendar
                                        </span>
                                    </li>
                                    <li className="selected">
                                        <span>
                                            <i className="icon-Staff-Outline" />
                                            Staff
                                        </span>
                                    </li>
                                    {/* <li>
                    <span>
                      <i className="icon-Message-Outline" />
                      Messages
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Fees-Outline" />
                      Fees Management
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Enquiries-Outline" />
                      Enquiries
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Invites-Outline" />
                      Invites
                    </span>
                  </li> */}
                                </ul>
                                <h4 className="sp-title flexc justifySp" onClick={() => handleOpenNewProgramPopupVisible()}>
                                    Programs{" "}
                                    <span id="add-programs" className="sp-plus">
                                        +
                                    </span>
                                </h4>
                                <ul>
                                    {programsLoading && programsData && programsData.length > 0 ?
                                        programsData.map((data) => {
                                            return (
                                                <li onClick={() => {
                                                    localStorage.setItem("programId", data.id);
                                                    localStorage.setItem("programName", data.program_name);
                                                    navigate('/student_list');
                                                }}>
                                                    <span>
                                                        <i className="icon-Program-Outline-1" />
                                                        {data.program_name}
                                                    </span>
                                                </li>
                                            );
                                        }) :
                                        <div className="data-loader extracss_load">
                                            {/* <Loader
                                                type="spinner-cub"
                                                bgColor="#4D6EE3"
                                                color="#6B6F8C"
                                                title="Loading..."
                                                size={35}
                                            /> */}
                                            <WindmillSpinner loading={"loading"} color="#4d6ee3d6" size={30} />
                                            <p>Loading...</p>
                                        </div>
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="sp-bottom sp-nav">
                            <ul>
                                <li>
                                    <span>
                                        {/* <i className="icon-Import-Outline" /> */}
                                        <div className="noti_sdi">
                                            <img src={gift} alt="" style={{ height: "16px" }} />
                                            <p>Notifications</p>
                                        </div>

                                    </span>
                                </li>
                                <li>
                                    <span style={{ display: "block" }}>
                                        {/* <i className="icon-Invites-Outline" /> */}
                                        <div className="noti_tot">
                                            <div className="noti_sdi">
                                                <img src={upar} alt="" style={{ height: "18px", width: "18px" }} />
                                                <p>Upgrade Pro</p>
                                            </div>
                                            <img src={ques} alt="" style={{ height: "18px", width: "18px" }} />
                                        </div>
                                    </span>
                                </li>
                                <li onClick={() => navigate("/signout")}>
                                    <span>
                                        <i className="icon-Invites-Outline" />
                                        Log Out
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="sp-right-panel">
                    <div className="sp-top-bar flexc justifySp exta_pad_10">
                        <div className="ft-wt600 flexc pad_ic_ri">
                            <span className="selected-icon">
                                <i className="icon-Staff-Outline" />
                            </span>
                            Staff
                            <i className="icon-More-Icon" />
                        </div>
                        <div className="flexc">
                            <button className="sp-view-btn ft-wt500 flexc"
                                onClick={() => {
                                    importStudents1();
                                }}>
                                <i className="icon-Import-Outline" />
                                Import
                            </button>
                            <button className="createN ft-wt500 mr-lf10 space_icon_pad"><i class="fa-solid fa-plus"></i> New Staff</button>
                        </div>
                    </div>
                    <div className="sp-header">
                        <nav className="flexc">
                            {/* <div class="sp-list-view flexc hidden"><i class="icon-Attendance-Outline"></i>Student List</div> */}
                            <div className="sp-list-view flexc" onClick={() => { navigate('/staff_list'); }}>
                                <i className="icon-Students-Outline" />
                                Staff List
                            </div>
                            <div className="sp-list-view flexc selected">
                                <i className="icon-Attendance-Outline" />
                                Attendance
                            </div>
                            {/* <div className="sp-list-view flexc">
                                <i className="icon-Calendar-Outline" />
                                Timetable
                            </div> */}
                        </nav>
                        {importstatus === false &&
                            <div className="sp-menu-bar flexc justifySp">
                                <div className="sp-menu icon_div_space pad_30_ex">
                                    {/* <Snackbar
                                    open={open}
                                    onClose={handleClose}
                                    autoHideDuration={3000}
                                    message={error}
                                /> */}
                                    <div className="flexc">
                                        {
                                            selectedStaff.length === 0 ?
                                                <div className="btn-search-bar flexc">
                                                    <i className="icon-Search" />
                                                    <input
                                                        type="search"
                                                        placeholder="Search Staff"
                                                        value={filterValue}
                                                        onChange={handleInputChange}
                                                    />
                                                </div> :
                                                <>
                                                    <button className="sp_mbtn flexc excla_div" onClick={() => { addAttendance(1); }}>
                                                        <span className="flexc ft-13">
                                                            <img src={tik} alt="" />
                                                            {/* <span className="sp-lpdr  icon-Tick color_cha_whi" /> */}
                                                        </span>
                                                        <span>Present</span>
                                                    </button>
                                                    <button className="sp_mbtn flexc excla_div" onClick={() => { addAttendance(2); }}>
                                                        <img src={cross} alt="" />
                                                        {/* <span className="notapp-ele color_cha_whi   " /> */}
                                                        <span>Absent</span>
                                                    </button>
                                                    <button className="sp_mbtn flexc excla_div" onClick={() => { addAttendance(5); }}>
                                                        <img src={leave} alt="" />
                                                        {/* <span className="cross-ele color_cha_whi leave_ic" /> */}
                                                        <span>Leave</span>
                                                    </button>
                                                </>
                                        }
                                    </div>
                                    {/* <div className="flexc">
                                    <button className="sp_mbtn flexc" onClick={() => { addAttendance(1); }}>
                                        <span className="flexc ft-13">
                                            <span className="sp-lpdr  icon-Tick color_cha_whi" />
                                        </span>
                                        <span>Present</span>
                                    </button>
                                    <button className="sp_mbtn flexc excla_div" onClick={() => { addAttendance(2); }}>
                                        <span className="notapp-ele color_cha_whi   " />
                                        <span>Absent</span>
                                    </button>
                                    <button className="sp_mbtn flexc" onClick={() => { addAttendance(5); }}>
                                        <span className="cross-ele color_cha_whi leave_ic" />
                                        <span>Leave</span>
                                    </button>
                                    <div className="btn-search-bar flexc">
                                        <i className="icon-Search" />
                                        <input
                                            type="search"
                                            placeholder="Search Staff"
                                            value={filterValue}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div> */}
                                </div>
                            </div>
                        }
                    </div>

                    {importstatus ?
                        <>

                            <ImportComponent
                                importStudent={"staff"}
                                handleImportStatus={handleImportStatus}
                                setProgress={setProgress} setIsLoading={setIsLoading}
                            />
                        </>
                        :
                        <div className="he_fix_cus">
                            {
                                (foodLoading && foodData) ? (
                                    foodData.status === true ? (
                                        <div className="std-attence">
                                            <div className="attence-top overxy-auto">
                                                <div className="students-add-list">
                                                    <ul className="sp-head sp_sticky defult-date-header">
                                                        <li className="table_input_sty">
                                                            <input
                                                                type="checkbox"
                                                                id="check-all"
                                                                onChange={handleSelectAll}
                                                                checked={selectedStaff.length === foodHeaderdata.length}
                                                            />
                                                            {foodData.staff}
                                                        </li>
                                                        {dataHeader && dataHeader.length > 0 ?
                                                            dataHeader.map((data, index) => (
                                                                <li key={index} className={`${formatDate(moment(data.date_data, 'ddd MMM DD YYYY').toDate()) === formattedCurrentDate && 'active-date-header'}`}>{data.date_data}</li>
                                                            )) : <div></div>}
                                                    </ul>
                                                    {foodHeaderdata.map((student, studentIndex) => (
                                                        <React.Fragment key={studentIndex}>
                                                            <ul>
                                                                <li className="table_input_sty truncate">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="sub_select"
                                                                        onChange={() => handleSelectStudent(student.id)}
                                                                        checked={selectedStaff.includes(student.id)}
                                                                    />
                                                                    <span className="ft-500 flexc ">
                                                                        <img src={student.profile_image || avatar1} width={40} alt="Avatar" />
                                                                        <span className="mr-lf10 ft-14 truncate name_bold">{student.name}</span>
                                                                    </span>
                                                                </li>
                                                                {student.attendance.map((attendanceData, attendanceIndex) => (
                                                                    <React.Fragment key={attendanceIndex}>
                                                                        <li
                                                                            className={`${formatDate(moment(attendanceData.date, 'ddd MMM DD YYYY').toDate()) === formattedCurrentDate ? 'active-date-data' :
                                                                                formatDate(moment(attendanceData.date, 'ddd MMM DD YYYY').toDate()) > formattedCurrentDate && 'next-date-data'} ${(studentIndex === (foodHeaderdata.length - 1) && formatDate(moment(attendanceData.date, 'ddd MMM DD YYYY').toDate()) === formattedCurrentDate) ? 'active-last-date-data' : ''}`}
                                                                        >
                                                                            {attendanceData.attendance_status === 1 ? (
                                                                                <span className="flexc ft-13">
                                                                                    <img src={tik1} alt="" style={{ width: '16px' }} />
                                                                                    {/* <span className="sp-lpdr icon-Tick " /> */}
                                                                                </span>
                                                                            ) : attendanceData.attendance_status === 2 ? (
                                                                                // <span className="notapp-ele" />
                                                                                <img src={leave1} alt="" style={{ width: '16px' }} />
                                                                            ) : attendanceData.attendance_status > 2 ? (
                                                                                // <span className="cross-ele" />
                                                                                <img src={cross1} alt="" style={{ width: '16px' }} />
                                                                            ) : null}
                                                                        </li>
                                                                    </React.Fragment>
                                                                ))}
                                                            </ul>
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        foodData.status === false && (
                                            <center><div className="sp-title flex-3">{foodData.message}</div></center>
                                        )
                                    )
                                ) : (
                                    <div className="data-loader extracss_load">
                                        {/* <Loader
                                        type="spinner-cub"
                                        bgColor="#4D6EE3"
                                        color="#6B6F8C"
                                        title="Loading..."
                                        size={35}
                                        /> */}
                                        <WindmillSpinner loading={"loading"} color="#4d6ee3d6" size={30} />
                                        <p>Loading...</p>
                                    </div>
                                )
                            }
                        </div>
                    }

                </div>
                {/* Create New Program */}
                {
                    newProgramPopupVisible && (
                        <div className="popup-program-container">
                            <div className="popup-wrap">
                                <h2 className="ft-wt700">Create New Program</h2>
                                <div className="sp-pop-header prl_1">
                                    <div className="form-section-default flexc justifySp fwrap">
                                        <div className="field-label full-field error_mes_cus">
                                            <label>Program Name</label>
                                            <div className="field-value-holder margin_top10p">
                                                <input
                                                    className="sp-field-input"
                                                    type="text"
                                                    placeholder="Enter Program name"
                                                    value={newProgramName}
                                                    onChange={(e) => setNewProgramName(e.target.value)}
                                                />
                                            </div>
                                            {programeNameError && <p className="error">{programeNameError}</p>}
                                        </div>
                                        <div className="field-label half-field error_mes_cus">
                                            <label>Program Teachers</label>
                                            <div className="field-value-holder margin_top10p dropbutton" >
                                                <Dropdown


                                                    label={
                                                        <span
                                                            className={`${selectedTeachers ? 'selected-item' : ''}`}
                                                            style={{
                                                                fontSize: "13px",
                                                                justifyContent: "flex-start",
                                                                color: selectedTeachers ? '#1B252F' : '#A9AFC3'
                                                            }}
                                                        >
                                                            {selectedTeachers ? selectedTeachers.first_name : "Select Teacher"}</span>}
                                                    style={{ width: '100%' }}
                                                >

                                                    {teachersData.map(item => (
                                                        <Dropdown.Item
                                                            // className={selectedTeachers && selectedTeachers.id === item.id ? "dd_select" : null}
                                                            className={`${selectedTeachers && selectedTeachers.id === item.id ? "selected-item dd_select" : ""
                                                                }`}
                                                            icon={selectedTeachers && selectedTeachers.id === item.id ? HiCheck : null}
                                                            onClick={() => setTeacher(item.id)}
                                                            key={item.id}
                                                        >
                                                            {item.first_name}
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown>

                                            </div>
                                            {programeTeacherError && <p className="error">{programeTeacherError}</p>}
                                        </div>
                                        <div className="field-label half-field error_mes_cus">
                                            <label>Class Timing</label>
                                            <div className="flexc justifySp">
                                                <div className="half-field">
                                                    <div className="field-value-holder margin_top10p dropbutton cus_drop_siz" >
                                                        <Dropdown
                                                            label={


                                                                <span
                                                                    className={`${selectedStartTime ? 'selected-item' : ''}`}
                                                                    style={{
                                                                        fontSize: "12px",
                                                                        justifyContent: "flex-start",
                                                                        color: selectedStartTime ? '#1B252F' : '#A9AFC3'
                                                                    }}
                                                                >

                                                                    {/* <span style={{ color: 'black', fontSize: '12px' }}> */}



                                                                    <i class="fa-regular fa-clock" style={{ marginRight: '6PX' }}></i>
                                                                    {selectedStartTime ? selectedStartTime.time_name : "Start Time"}</span>}
                                                            style={{
                                                                width: "100%"
                                                            }}
                                                        >
                                                            {timeData.map(item => (
                                                                <Dropdown.Item

                                                                    //className={selectedStartTime && selectedStartTime.id === item.id ? "dd_select" : null}

                                                                    className={`${selectedStartTime && selectedStartTime.id === item.id ? "selected-item dd_select" : ""
                                                                        }`}
                                                                    icon={selectedStartTime && selectedStartTime.id === item.id ? HiCheck : null}
                                                                    onClick={() => setProgramStartTime(item.id)}
                                                                    key={item.id}
                                                                >
                                                                    {item.time_name}
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                <div className="half-field">
                                                    <div className="field-value-holder margin_top10p dropbutton cus_drop_siz" >
                                                        <Dropdown
                                                            label={


                                                                //  <span style={{ color: 'black', fontSize: '12px' }}>

                                                                <span
                                                                    className={`${selectedEndTime ? 'selected-item' : ''}`}
                                                                    style={{


                                                                        fontSize: "13px",
                                                                        fontWeight: "400",
                                                                        lineHeight: "18.2px",
                                                                        textAlign: "left",

                                                                        justifyContent: "flex-start",
                                                                        color: selectedEndTime ? '#1B252F' : '#A9AFC3'
                                                                    }}
                                                                >



                                                                    <i class="fa-regular fa-clock" style={{ marginRight: '6PX' }}>
                                                                    </i>{selectedEndTime ? selectedEndTime.time_name : "End Time"}</span>}
                                                            style={{
                                                                width: "100%"
                                                            }}
                                                        >
                                                            {timeData.map(item => (
                                                                <Dropdown.Item
                                                                    // className={selectedEndTime && selectedEndTime.id === item.id ? "dd_select" : null}
                                                                    className={`${selectedEndTime && selectedEndTime.id === item.id ? "selected-item dd_select" : ""
                                                                        }`}

                                                                    icon={selectedEndTime && selectedEndTime.id === item.id ? HiCheck : null}
                                                                    onClick={() => setProgramEndTime(item.id)}
                                                                    key={item.id}
                                                                >
                                                                    {item.time_name}
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            {timeError && <p className="error">{timeError}</p>}
                                        </div>
                                    </div>

                                    <h3 className="ft-wt600 mt-30">Customise Features</h3>
                                    <div className="full-field mb-6">
                                        {Object.keys(features).map((feature) => (
                                            <div className="flex items-center mb-4 custom-checkbox dis_chek_cus mt-5" key={feature}>
                                                <input
                                                    type="checkbox"
                                                    className="w-4 h-4 text-blue-600 rounded"
                                                    checked={features[feature].status}

                                                    disabled={
                                                        (['qrCode', 'attendance', 'food', 'sleep', 'leaves'].includes(feature))
                                                        // features[feature].status=== true
                                                    }
                                                    onChange={() => handleFeatureChange(feature)}
                                                />
                                                <label className="ms-2 text-sm">{features[feature].name}</label>
                                                {['busTracking', 'cctv', 'fees'].includes(feature) && <img src={SplImg} alt="spl" style={{ width: '25px', height: '15px', marginLeft: "5px" }} />}
                                            </div>
                                        ))}
                                    </div>
                                    <h3 className="ft-wt600 mt-30">Additional Informations</h3>
                                    <div className="full-field">
                                        {Object.keys(additionalInfo).map((info) => (
                                            <div className="flex items-center mb-4 custom-checkbox mt-5" key={info}>
                                                <input
                                                    type="checkbox"
                                                    className="w-4 h-4 text-blue-600 rounded"
                                                    checked={additionalInfo[info].status}
                                                    onChange={() => handleAdditionalInfoChange(info)}
                                                />
                                                <label className="ms-2 text-sm">{additionalInfo[info].name}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="sp-bottom-sticky">
                                    <button className="sp-btn-create crt_div" onClick={() => handleCreateProgram()}>Create Program</button>
                                    <button className="sp-btn-cancel">Cancel</button>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>

        </section>
    );
}
