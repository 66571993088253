import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import avatar1 from "./images/avatar5.png";
import avatar5 from "./images/avatar5.png";
import profile from "./images/profile-photo.png";
import '../../common/components/js/common.js';
import { baseUrl } from "../../common/common";
import Loader from "react-js-loader";
import "../../common/components/css/style.css";
import { Dropdown } from "flowbite-react";
import { HiCheck } from "react-icons/hi";
import "../../index.css";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import imgDoc from "./images/doc.png";
import templateImg from "./images/template.png";
import imgSend from "./images/send.png";
import Snackbar from '@mui/material/Snackbar';
import ListItemIcon from '@mui/material/ListItemIcon';
import SuccessIcon from "./images/success.png";
import ErrorIcon from "./images/error.png";
import SplImg from "./images/spl.png";
import "../../common/components/css/style1.css";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import close from "./images/close.svg";
import docImg from "./images/document.svg";
import pickImg from "./images/Image-doc.svg";
import reminImg from "./images/remin.svg";
import imgClose from "./images/img-close.svg";
import imgPdf from "./images/pdf-img.svg";
import gift from "./images/gift.svg";
import upar from "./images/upar.svg";
import ques from "./images/ques.svg";
import { WindmillSpinner } from 'react-spinner-overlay'

export default function Activity() {

    const navigate = useNavigate();

    const [activityData, setActivityData] = useState([]);
    const [activitysLoading, setActivityLoading] = useState(false);
    const fetchActivity = () => {
        //setActivityLoading(false);
        try {
            axios({
                method: "post",
                url: baseUrl + "/api/get_post",
                data: {
                    school_id: localStorage.getItem('schoolId'),
                    // school_id:1,
                    admin: 1,
                    limit_value: 25,
                    limit_offset: 0,
                    // user_id:1
                    user_id: localStorage.getItem('loginUserId')
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                console.log(response);
                if (response['data']['status'] === true) {
                    setActivityData(response.data.data);
                    setActivityLoading(true);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    const [programsData, setProgramsData] = useState({});
    const [programsLoading, setProgramsLoading] = useState(false);

    const fetchProgramsData = async () => {
        setProgramsLoading(false);
        try {
            const postData = {
                school_id: localStorage.getItem('schoolId')
                // school_id:24
            }
            const { data } = await axios.post(baseUrl + "/api/get_programs", postData,
                {
                    headers: {
                        "Authorization": localStorage.getItem('access_token')
                    }
                });
            setProgramsData(data.data);
            setProgramsLoading(true);
        } catch (error) {
            console.log(error)
        }
    }

    const [fileError, setFileError] = useState('');
    const [selectedImage, setSelectedImage] = useState([])
    const [selectedDocument, setSelectedDocument] = useState([])
    //------------------- image upload -------------
    // const handleImageChange = (event) => {
    //     const files = event.target.files;
    //     const file = event.target.files[0];
    //     setSelectedImage(file);
    // };
    // ----------------------------------------------
    //------------------- document upload -----------
    // const handleDocumentChange = (event) => {
    //     const files = event.target.files;
    //     const file = event.target.files[0];
    //     setSelectedDocument(file);
    // };
    // ----------------------------------------------

    const [postTitle, setPostTitle] = useState('');
    // const handleImageChange = (event) => {
    //     const selectedFiles = Array.from(event.target.files);
    //     const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
    //     const maxSize = 5 * 1024 * 1024; // 5MB
    //     let invalidFiles = [];
    //     let validFiles = [];

    //     selectedFiles.forEach(file => {
    //         if (!validTypes.includes(file.type)) {
    //             invalidFiles.push(file);
    //         } else if (file.size > maxSize) {
    //             invalidFiles.push(file);
    //         } else {
    //             validFiles.push(file);
    //         }
    //     });

    //     if (invalidFiles.length > 0) {
    //         setFileError('Some files are invalid. Only JPG, PNG, and GIF images under 5MB are allowed.');
    //         setOpen(true);
    //     }

    //     if (validFiles.length > 0) {
    //         setSelectedImage(validFiles);
    //     }
    // };

    const handleImageChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
        const maxSize = 5 * 1024 * 1024; // 5MB
        let invalidFiles = [];
        let validFiles = [];

        selectedFiles.forEach(file => {
            if (!validTypes.includes(file.type)) {
                invalidFiles.push(file);
            } else if (file.size > maxSize) {
                invalidFiles.push(file);
            } else {
                validFiles.push(file);
            }
        });

        if (invalidFiles.length > 0) {
            setFileError('Some files are invalid. Only JPG, PNG, and GIF images under 5MB are allowed.');
            setOpen(true);
        }

        if (validFiles.length > 0) {
            setSelectedImage(validFiles);
        }
    };

    const handleRemoveImage = (index) => {
        const newSelectedImages = [...selectedImage];
        newSelectedImages.splice(index, 1);
        setSelectedImage(newSelectedImages);
    };

    const handleDocumentChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        const validTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
        const maxSize = 5 * 1024 * 1024; // 5MB
        let invalidFiles = [];
        let validFiles = [];

        selectedFiles.forEach(file => {
            if (!validTypes.includes(file.type)) {
                invalidFiles.push(file);
            } else if (file.size > maxSize) {
                invalidFiles.push(file);
            } else {
                validFiles.push(file);
            }
        });

        if (invalidFiles.length > 0) {
            setFileError('Some files are invalid. Only PDF and Word documents under 5MB are allowed.');
            setOpen(true);
        }

        if (validFiles.length > 0) {
            setSelectedDocument([...selectedDocument, ...validFiles]);
        }
    };
    const handleRemoveDocument = (index) => {
        const newSelectedDocuments = [...selectedDocument];
        newSelectedDocuments.splice(index, 1);
        setSelectedDocument(newSelectedDocuments);
    };
    // const handleDocumentChange = (event) => {
    //     const selectedFiles = Array.from(event.target.files);
    //     const validTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    //     const maxSize = 5 * 1024 * 1024; // 5MB
    //     let invalidFiles = [];
    //     let validFiles = [];

    //     selectedFiles.forEach(file => {
    //         if (!validTypes.includes(file.type)) {
    //             invalidFiles.push(file);
    //         } else if (file.size > maxSize) {
    //             invalidFiles.push(file);
    //         } else {
    //             validFiles.push(file);
    //         }
    //     });

    //     if (invalidFiles.length > 0) {
    //         setFileError('Some files are invalid. Only PDF and Word documents under 5MB are allowed.');
    //         setOpen(true);
    //     }

    //     if (validFiles.length > 0) {
    //         setSelectedDocument(validFiles);
    //     }
    // };

    const downloadDocument = async (documentLink, documentName) => {
        const response = await fetch(baseUrl + "/api/" + documentLink);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = documentName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    const [open, setOpen] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const convertRawContentToHTML = (rawContent) => {
        let html = '';

        rawContent.blocks.forEach((block) => {
            let blockHTML = '';
            switch (block.type) {
                case 'unstyled':
                    blockHTML += `<p>${block.text}</p>`;
                    break;
                case 'header-one':
                    blockHTML += `<h1>${block.text}</h1>`;
                    break;
                case 'header-two':
                    blockHTML += `<h2>${block.text}</h2>`;
                    break;
                case 'header-three':
                    blockHTML += `<h3>${block.text}</h3>`;
                    break;
                case 'header-four':
                    blockHTML += `<h4>${block.text}</h4>`;
                    break;
                case 'header-five':
                    blockHTML += `<h5>${block.text}</h5>`;
                    break;
                case 'header-six':
                    blockHTML += `<h6>${block.text}</h6>`;
                    break;
                case 'blockquote':
                    blockHTML += `<blockquote>${block.text}</blockquote>`;
                    break;
                case 'unordered-list-item':
                    blockHTML += `<li>${block.text}</li>`;
                    break;
                case 'ordered-list-item':
                    blockHTML += `<li>${block.text}</li>`;
                    break;
                default:
                    blockHTML += `<p>${block.text}</p>`;
                    break;
            }
            html += blockHTML;
        });

        return html;
    };

    const slideRef = useRef(null);

    const nextSlide = () => {
        slideRef.current.goNext();
    };

    const previousSlide = () => {
        slideRef.current.goBack();
    };

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [activity, setActivity] = useState({});
    const openModal = (data) => {
        console.log(data)
        setActivity(data)
        console.log(activity)
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
        $('.modal-lightbox').removeClass('visible');
    };

    const divStyle = {
        // Define your div styles here
        height: '400px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    };

    const spanStyle = {
        // Define your span styles here
        position: 'absolute',
        bottom: '20px',
        left: '20px',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        padding: '10px',
    };

    const onEditorStateChange = (newState) => {
        setEditorState(newState);
        const contentState = newState.getCurrentContent();
        const rawContentState = convertToRaw(contentState);
        const html = convertRawContentToHTML(rawContentState);
        console.log(html);
        setPostContent(html)
    };

    const getHTMLValue = () => {
        const contentState = editorState.getCurrentContent();
        const rawContentState = convertToRaw(contentState);
        const html = convertRawContentToHTML(rawContentState);
        console.log(html);
        setPostContent(html)

    };

    const [postContent, setPostContent] = useState("");

    const addPost = () => {
        if (selectedImage.length === 0 && selectedDocument.length === 0 && postTitle === '') {
            setSnackbarMessage("At least one data is required");
            setMessageType('error');
            handleSnackbarOpen();
        }
        // else if (selectedDocument.length === 0) {
        //     setFileError("Please Choose Document")
        //     setOpen(true);
        // }
        // else if (postContent === '') {
        //     setFileError("Please Enter Post Content")
        //     setOpen(true);
        // } 
        // else if (template === '') {
        //     setFileError("Please Select Teplate")
        //     setOpen(true);
        // } 
        else {
            const postData = {
                staff_id: localStorage.getItem('loginUserId'),
                post_image: selectedImage,
                attachment_file: selectedDocument,
                template_id: 2,
                post_title: postTitle,
                post_content: postContent,
                reminder: "",
                admin: 1,
                school_id: localStorage.getItem('schoolId'),
                programs_id: programId,
                status: 1,
                created_by: localStorage.getItem('loginUserId')
            }
            console.log(postData)
            axios({
                method: "post",
                url: baseUrl + "/api/post_add_edit",
                data: postData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                console.log(response);
                if (response.data.status === true) {
                    handleModalClose();
                    // setSelectedImage([]);
                    // setSelectedDocument([]);
                    // setTemplate('');
                    // setPostContent('');
                    // setPostTitle('');
                    // setEditorState(EditorState.createEmpty());
                    // $('.announce-post-inr').slideUp();
                    setSnackbarMessage(response.data.message);
                    setMessageType('success');
                    handleSnackbarOpen();
                    fetchActivity();
                } else {
                    setSnackbarMessage(response.data.message);
                    setMessageType('error');
                    handleSnackbarOpen();
                }
            });
        }

    }

    const [programId, setProgramId] = useState(0);
    const [programs, setPrograms] = useState([]);
    const selectedProgram = programs.find((program) => program.id === programId);
    const fetchPrograms = async () => {
        try {
            const response = await axios.post(baseUrl + "/api/get_programs", { school_id: localStorage.getItem('schoolId') }, {
                headers: {
                    "Authorization": localStorage.getItem('access_token')
                }
            });
            setPrograms(response.data.data);
        } catch (error) {
        }
    };

    const [comment, setComment] = useState("");

    const handleSendComment = (postId) => {
        try {
            axios({
                method: "post",
                url: baseUrl + "/api/comments_add_edit",
                data: {
                    school_id: localStorage.getItem('schoolId'),
                    post_id: postId,
                    user_id: localStorage.getItem('loginUserId'),
                    user_name: localStorage.getItem('loginUserName'),
                    role_id: localStorage.getItem('roleId'),
                    comment: comment,
                    status: 1,
                    created_by: localStorage.getItem('loginUserId'),
                    updated_by: localStorage.getItem('loginUserId')
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                console.log(response);
                if (response.data.status === true) {
                    setComment('');
                    setIsModalVisible(false);
                    $('.modal-lightbox').removeClass('visible');
                    setSnackbarMessage(response.data.message);
                    setMessageType('success');
                    handleSnackbarOpen();
                    fetchActivity();
                } else {
                    setSnackbarMessage(response.data.message);
                    setMessageType('error');
                    handleSnackbarOpen();
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    const handleSendLike = (postId, status) => {
        try {
            axios({
                method: "post",
                url: baseUrl + "/api/" + (status === "add" ? "likes_add" : "likes_delete"),
                data: status === "add" ? {
                    school_id: localStorage.getItem('schoolId'),
                    post_id: postId,
                    user_id: localStorage.getItem('loginUserId'),
                    role_id: localStorage.getItem('roleId'),
                    status: 1,
                    created_by: localStorage.getItem('loginUserId'),
                    updated_by: localStorage.getItem('loginUserId'),
                } : {
                    post_id: postId,
                    user_id: localStorage.getItem('loginUserId'),
                    role_id: localStorage.getItem('roleId')
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                console.log(response);
                if (response.data.status === true) {
                    setSnackbarMessage(response.data.message);
                    setMessageType('success');
                    handleSnackbarOpen();
                    fetchActivity();
                } else {
                    setSnackbarMessage(response.data.message);
                    setMessageType('error');
                    handleSnackbarOpen();
                }
            }).catch((error) => {
                console.log(error);
            });
        } catch (error) {
            console.log(error);
        }
    };

    const extractTextFromHTML = (html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };

    const wordCount = (text) => {
        return text.split(/\s+/).filter(word => word.length > 0).length;
    };

    // ------11-06----------------
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [snackbarState, setSnackbarState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center'
    });
    const handleSnackbarClose = () => {
        setSnackbarState({ ...snackbarState, open: false });
    };
    const handleSnackbarOpen = () => {
        setSnackbarState({ ...snackbarState, open: true });
    };
    useEffect(() => { }, [snackbarMessage, messageType]);

    // ----------------14-06-----------------------
    const [template, setTemplate] = useState("");
    const [templateData, setTemplateData] = useState([]);
    // const selectedTemplate = templateData.find(data => data.id === template);
    const fetchTemplateData = async () => {
        axios({
            method: "post",
            url: baseUrl + "/api/get_template",
            data: {
                school_id: localStorage.getItem('schoolId')
            },
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setTemplateData(response.data.data);
                console.log(response);
            }
        });
    }

    // const changeTempltateData =(templateData) => {
    //     // const selectedTemplate = templateData.find(data => data.id === template);
    //     setTemplate(templateData.id);
    //     setEditorState(templateData.template_content);
    //     setPostContent(templateData.template_content);
    //     alert(templateData.id)
    // }

    // const changeTemplateData = (templateData) => {
    //     setTemplate(templateData.id);
    //     // const contentState = convertToRaw(templateData.template_content);
    //     const newEditorState = EditorState.createWithContent(templateData.template_content);        
    //     setEditorState(newEditorState);
    //     setPostContent(templateData.template_content);
    // }

    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const changeTemplateData = (template) => {
        try {
            setTemplate(template.id);
            setSelectedTemplate(template);
            if (template && typeof template.template_content === 'string') {
                const contentState = ContentState.createFromText(template.template_content);
                const newEditorState = EditorState.createWithContent(contentState);
                setEditorState(newEditorState);
            } else {
                throw new Error('Invalid content format');
            }
        } catch (error) {
            console.error('Error changing template data:', error);
            setEditorState(EditorState.createEmpty());
        }
    };

    const changeProgramsData = (programId) => {
        // handleModalOpen();
        setProgramId(programId);
        // $('.announce-post-inr').slideDown();
    };
    const [showFullContent, setShowFullContent] = useState(false);

    const contentRef = React.createRef();

    // Calculate styles based on content visibility
    const contentStyle = {
        maxHeight: showFullContent ? 'none' : contentRef.current && `calc(3 * ${parseFloat(window.getComputedStyle(contentRef.current).lineHeight)})`,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
    };

    // -------------------------19-06-------------------------

    const handleOpenNewProgramPopupVisible = () => {
        setProgramNameError(null);
        setProgramTeacherError(null);
        setTimeError(null);
        setTeacher("");
        setProgramStartTime("");
        setProgramEndTime("");
        setNewProgramPopupVisible(true);
    }


    const [newProgramPopupVisible, setNewProgramPopupVisible] = useState(false);
    const [programStartTime, setProgramStartTime] = useState("");
    const [programEndTime, setProgramEndTime] = useState("");
    const [programTeacher, setTeacher] = useState("");
    const [timeData, setTimeData] = useState([]);
    const [teachersData, setTeachersData] = useState([]);
    const selectedStartTime = timeData.find(data => data.id === programStartTime);
    const selectedEndTime = timeData.find(data => data.id === programEndTime);
    const selectedTeachers = teachersData.find(data => data.id === programTeacher);

    const fetchProgramTimeData = async () => {
        axios({
            method: "post",
            url: baseUrl + "/api/get_time",
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setTimeData(response.data.data);
                console.log(response);
            }
        });
    }

    const fetchTeachersData = async () => {
        axios({
            method: "post",
            url: baseUrl + "/api/teacher_list",
            data: {
                school_id: localStorage.getItem("schoolId")
            },
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setTeachersData(response.data.data);
                console.log(response);
            }
        });
    }
    const [newProgramName, setNewProgramName] = useState('');
    const [features, setFeatures] = useState({
        qrCode: { status: true, name: 'QR Code', value: 2 },
        attendance: { status: true, name: 'Attendance', value: 5 },
        food: { status: true, name: 'Food', value: 3 },
        sleep: { status: true, name: 'Sleep', value: 4 },
        leaves: { status: true, name: 'Leaves', value: 6 },
        busTracking: { status: false, name: 'Bus Tracking', value: 7 },
        cctv: { status: false, name: 'CCTV', value: 8 },
        fees: { status: false, name: 'Fees', value: 9 },
    });
    const [additionalInfo, setAdditionalInfo] = useState({
        inviteParents: {
            status: true,
            name: 'Automatically Invite Parents as soon as student profile created',
            value: 1
        },
        addParentChat: {
            status: true,
            name: 'Add Parent in chat as soon as student profile created',
            value: 2
        },
        createStudentID: {
            status: true,
            name: 'Automatically create Student ID as soon as profile created',
            value: 3
        },
    });


    const handleFeatureChange = (feature) => {
        setFeatures({
            ...features,
            [feature]: {
                ...features[feature],
                status: !features[feature].status,
            },
        });
    };
    const handleAdditionalInfoChange = (info) => {
        setAdditionalInfo({
            ...additionalInfo,
            [info]: {
                ...additionalInfo[info],
                status: !additionalInfo[info].status,
            },
        });
    };

    const [programeNameError, setProgramNameError] = useState(null);
    const [programeTeacherError, setProgramTeacherError] = useState(null);
    const [timeError, setTimeError] = useState(null);
    const validateProgramForm = () => {
        let valid = true;
        if (newProgramName === "") {
            setProgramNameError("Please enter program name");
            valid = false;
        } else {
            setProgramNameError(null);
        }
        if (programTeacher === "") {
            setProgramTeacherError("Please select program teacher");
            valid = false;
        } else {
            setProgramTeacherError(null);
        }
        // if (programStartTime === "" || programEndTime === "") {
        //   setTimeError("Please select start and end time");
        //   valid = false;
        // } else {
        //   setTimeError(null);
        // }
        return valid;
    };

    const handleCreateProgram = () => {

        const selectedFeatureValues = Object.keys(features).filter((key) => features[key].status).map((key) => features[key].value);
        const selectedAdditionalInfoValues = Object.keys(additionalInfo).filter((key) => additionalInfo[key].status).map((key) => additionalInfo[key].value);

        console.log({
            school_id: localStorage.getItem("schoolId"),
            program_name: newProgramName,
            teacher_id: programTeacher,
            from_time_id: programStartTime,
            to_time_id: programEndTime,
            features: selectedFeatureValues.join(","),
            additional_info: selectedAdditionalInfoValues.join(","),
            status: 1,
            created_by: localStorage.getItem("loginUserId")
        });
        if (validateProgramForm()) {
            try {
                axios({
                    method: "post",
                    url: baseUrl + "/api/programs_add_edit",
                    data: {
                        school_id: localStorage.getItem("schoolId"),
                        program_name: newProgramName,
                        teacher_id: programTeacher,
                        from_time_id: programStartTime,
                        to_time_id: programEndTime,
                        features: selectedFeatureValues.join(","),
                        additional_info: selectedAdditionalInfoValues.join(","),
                        status: 1,
                        created_by: localStorage.getItem("loginUserId")
                    },
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": localStorage.getItem('access_token')
                    },
                }).then((response) => {
                    console.log(response);
                    if (response.data.status === true) {
                        $('.overlay').removeClass('visible');
                        $('.popup-program-container').removeClass('visible');
                        setNewProgramPopupVisible(false);
                        fetchProgramsData();
                        setSnackbarMessage(response.data.message);
                        setMessageType('success');
                        handleSnackbarOpen();
                    } else {
                        setSnackbarMessage(response.data.message);
                        setMessageType('error');
                        handleSnackbarOpen();
                    }
                });
            } catch (error) {
                console.log(error)
            }
        }

    };

    // ----02-07------
    const style = {
        position: 'absolute',
        top: '50%',
        left: '57%',
        transform: 'translate(-50%, -50%)',
        width: 720,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 2,
        //  p: 4,
    };
    const [openActivityModal, setActivityOpenModal] = useState(false);
    const handleModalOpen = () => setActivityOpenModal(true);
    const handleModalClose = () => {
        setProgramId(0);
        setSelectedImage([]);
        setSelectedDocument([]);
        setTemplate('');
        setPostContent('');
        setPostTitle('');
        setEditorState(EditorState.createEmpty());
        setActivityOpenModal(false);
    };


    useEffect(() => {
        fetchProgramsData();
        fetchPrograms();
        fetchTemplateData();
        fetchActivity();
        fetchProgramTimeData();
        fetchTeachersData();
        // $(document).on('click', '.post-images', function () {
        //     $('.modal-lightbox').addClass('visible');
        // })
        // $(document).on('click', '.modal-lightbox .close', function () {
        //     $('.modal-lightbox').removeClass('visible');
        // });
        $("#search-input").keyup(function () {
            var searchTerm = $(this).val().toLowerCase();

            $(".search-items li").each(function () {
                var itemText = $(this).text().toLowerCase();

                if (itemText.indexOf(searchTerm) > -1) {

                    $(this).show();
                } else {
                    $(this).hide();
                }
            });
        });
        const $targetEl = document.getElementById('schoolswitch');

        // options with default values
        const options = {
            placement: 'bottom-right',
            backdrop: 'dynamic',
            backdropClasses: 'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
            closable: true,
            onHide: () => {
                console.log('modal is hidden');
            },
            onShow: () => {
                console.log('modal is shown');
            },
            onToggle: () => {
                console.log('modal has been toggled');
            }
        };
        $(document).on('click', '#new-template', function () {
            $('.overlay').addClass('visible');
            $('.popup-modal-container#create-template-popup').addClass('visible')
        });

        // $('.act-announce-inp input').on('click', function () {
        //     $('.announce-post-inr').slideDown();
        // });
    }, []);

    return (
        <section>
            <Snackbar
                anchorOrigin={{ vertical: snackbarState.vertical, horizontal: snackbarState.horizontal }}
                open={snackbarState.open}
                onClose={handleSnackbarClose}
                message={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ListItemIcon>
                            <img src={messageType === 'success' ? SuccessIcon : ErrorIcon} alt="Success" style={{ width: '24px', height: '24px', marginRight: '8px' }} />
                        </ListItemIcon>
                        <div className="success_cuc">
                            <p> {snackbarMessage} </p>
                        </div>
                    </div>
                }
                ContentProps={{
                    sx: {
                        border: "1px solid",
                        opacity: "1",
                        background: messageType === 'success' ? "#40BE61" : "#FD4954",
                        borderColor: messageType === 'success' ? "#40BE61" : "#FD4954",
                        width: '415px'
                    }
                }}
                autoHideDuration={3000}
            />
            <Modal
                open={openActivityModal}
                // onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >
                <Box sx={style}>
                    <div style={{ padding: "20px" }}>
                        <div className="flexc justifySp">
                            <div className="sp-tt ft-14 ft-wt600 head_cus_cal">Create Post</div>
                            <div
                                style={{
                                    position: "absolute",
                                    right: "1pc",
                                    float: "right",
                                    cursor: "pointer"
                                }}
                                onClick={() => handleModalClose()}
                            >
                                <img
                                    src={close}
                                    style={{ height: "30px", width: "30px", paddingTop: "2px" }}
                                    alt="close-icon"
                                />
                            </div>
                        </div>
                        <div style={{ marginTop: "15px" }}>
                            <div className="act-announce-inp flexc act_input">
                                <div className="annonce-pers-left flexc create-post">
                                    <img src={avatar1} alt="avatar" />
                                    <div className="annonce-pers-left-cont">
                                        {(selectedProgram || programId === 0) && (
                                            <p style={{ fontSize: "13px" }}>
                                                <div style={{ display: "flex" }}>
                                                    <span className="ft-wt700">{localStorage.getItem("loginUserName")}</span>
                                                    <span style={{ color: "#000000", fontWeight: "600", paddingRight: "3px", paddingLeft: "3px" }}>|</span>
                                                    <span className="ft-wt700">
                                                        {selectedProgram
                                                            ? selectedProgram.program_name
                                                            : programId === 0
                                                                ? "All Program"
                                                                : ""}
                                                    </span>
                                                </div>
                                            </p>
                                        )}
                                        <span className="post-re-tm ft-13 sp-post-bt">
                                            <div className="act_program por_div_cus seqlect_div_cus">
                                                <Dropdown
                                                    label={
                                                        <span style={{ color: "#707070", whiteSpace: "nowrap", fontSize: "0.8825em", padding: "0px" }} >
                                                            {selectedProgram
                                                                ? selectedProgram.program_name
                                                                : programId === 0
                                                                    ? "All Programs"
                                                                    : "Select Program"}
                                                        </span>
                                                    }
                                                    style={{ width: "100%" }}
                                                >
                                                    <Dropdown.Item
                                                        className={`${programId === 0 ? "selected-item dd_select" : ""
                                                            }`}
                                                        //    className={programId === 0 ? "dd_select" : null}
                                                        icon={programId === 0 ? HiCheck : null}
                                                        onClick={() => changeProgramsData(0)}
                                                        key={0}
                                                    >
                                                        All Program
                                                    </Dropdown.Item>
                                                    {programs.map((item) => (
                                                        <Dropdown.Item
                                                            className={`${selectedProgram && selectedProgram.id === item.id ? "selected-item dd_select" : ""
                                                                }`}
                                                            //  className={selectedProgram && selectedProgram.id === item.id ? "dd_select" : null}
                                                            icon={selectedProgram && selectedProgram.id === item.id ? HiCheck : null}
                                                            onClick={() => changeProgramsData(item.id)}
                                                            key={item.id}
                                                        >
                                                            {item.program_name}
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="act-announce-inp flexc act_input editorHeaderClassName ft-13 font_in_text activit_pa_in">
                                <input
                                    style={{ border: "0px", marginTop: "15px" }}
                                    type="text"
                                    placeholder="Enter Title"
                                    value={postTitle}
                                    onChange={(ev) => setPostTitle(ev.target.value)}
                                />
                            </div>
                            <Editor
                                editorState={editorState}
                                toolbarHidden
                                editorClassName="editorClassName"
                                onEditorStateChange={onEditorStateChange}
                                placeholder="Add Descriptions..."
                            />
                            <div className="sp-profile-rt flexc" style={{ marginTop: "15px" }}>
                                {selectedImage.length > 0 &&
                                    selectedImage.map((img, index) => (
                                        <div key={index} className=" flexc" style={{ position: 'relative', borderRadius: "10%" }}>
                                            <div className="sp-accbox1">
                                                <img
                                                    src={URL.createObjectURL(img)}
                                                    alt={`picked-image-${index}`}
                                                    style={{ borderRadius: "10%" }}
                                                    width='90px'
                                                    height='90px'
                                                />
                                            </div>
                                            <span
                                                onClick={() => handleRemoveImage(index)}
                                                style={{
                                                    position: 'absolute',
                                                    top: '0px',
                                                    right: '0px',
                                                    cursor: 'pointer',
                                                    borderRadius: '50%'
                                                }}
                                            >
                                                <div className="img_20 im_sm_siz">
                                                    <img src={imgClose} alt="remove-icon" />
                                                </div>
                                            </span>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="sp-profile-rt" style={{ marginTop: "15px" }}>
                                {selectedDocument.length > 0 &&
                                    selectedDocument.map((doc, index) => (
                                        <div key={index} className="announce-post-left" style={{ backgroundColor: "#F8F8F8", width: "200px", marginTop: "5px", padding: "5px", borderRadius: "4px" }}>
                                            <span className="flexc">
                                                {/* <i className="icon-Enquiries-Outline" /> */}
                                                <img src={imgPdf} alt="remove-icon" style={{ width: "18px", height: "14px" }} />
                                                <p style={{ fontSize: "10px" }}>{doc.name}</p>
                                            </span>
                                            {/* <span
                                            onClick={() => handleRemoveDocument(index)}
                                            style={{
                                                position: 'absolute',
                                                top: '0px',
                                                right: '0px',
                                                cursor: 'pointer',
                                                borderRadius: '50%'
                                            }}
                                        >
                                            <div className="img_20">
                                                <img src={imgClose} alt="remove-icon" />
                                            </div>
                                        </span> */}
                                        </div>
                                    ))
                                }
                            </div>

                        </div>
                    </div>

                    <div className="announce-post-cont flexc justifySp activity_foot_div">
                        <div className="annoce-poster-btm flexc" >
                            <span className="ft-13">
                                <div>
                                    <input
                                        type="file"
                                        id="imageInput"
                                        style={{ display: "none", width: "100%" }}
                                        accept=".jpg, .jpeg, .png, .gif"
                                        multiple
                                        onChange={handleImageChange}
                                    />
                                    <label htmlFor="imageInput">
                                        <img
                                            src={pickImg}
                                            style={{ height: "20px", width: "20px", paddingTop: "2px", cursor: "pointer" }}
                                            alt="avatar"
                                        />
                                    </label>
                                </div>
                            </span>
                            <span className="ft-13">
                                <div>
                                    <input
                                        type="file"
                                        id="documentInput"
                                        style={{ display: "none", width: "100%" }}
                                        accept=".pdf, .doc, .docx"
                                        multiple
                                        onChange={handleDocumentChange}
                                    />
                                    <label className="ft-wt500 flexc" htmlFor="documentInput">
                                        <img
                                            src={docImg}
                                            style={{ height: "20px", width: "20px", paddingTop: "2px", cursor: "pointer" }}
                                            alt="avatar"
                                        />
                                    </label>
                                </div>
                            </span>
                            <span className="ft-13">
                                <div>
                                    <label className="ft-wt500 flexc">
                                        <img
                                            src={reminImg}
                                            className="mr-rt10"
                                            style={{ height: "20px", width: "20px", paddingTop: "2px" }}
                                            alt="avatar"
                                        />
                                    </label>
                                </div>
                            </span>
                            <span className="flexc ft-13 sp-post-bt extra_mar_0">
                                <div className="act_program">
                                    <div className="activity_drop template_div_cus">
                                        <Dropdown
                                            label={
                                                <span style={{ color: "#707070", fontSize: "0.8825em" }}>
                                                    {selectedTemplate ? selectedTemplate.template_name : "Select Template"}
                                                </span>
                                            }
                                            style={{ width: "100%" }}
                                        >
                                            {templateData.map((item) => (
                                                <Dropdown.Item
                                                    className={`${selectedTemplate && selectedTemplate.id === item.id ? "selected-item dd_select" : ""
                                                        }`}
                                                    // className={selectedTemplate && selectedTemplate.id === item.id ? "dd_select" : null}
                                                    icon={selectedTemplate && selectedTemplate.id === item.id ? HiCheck : null}
                                                    onClick={() => changeTemplateData(item)}
                                                    key={item.id}
                                                >
                                                    {item.template_name}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown>
                                    </div>
                                </div>
                            </span>
                            <span className="flexc ft-13 sp-post-bt" style={{ cursor: "pointer", backgroundColor: "unset" }}></span>
                        </div>
                        <button className="flexc" onClick={() => addPost()}>
                            <img src={imgSend} alt="avatar" style={{ marginRight: "5px" }} />
                            Post
                        </button>
                    </div>

                </Box>
            </Modal>


            <div className="outer-container">
                <div className="overlay" />
                <div id="left-panel" className="sp-left-panel">
                    <div className="sp-top-band">
                        <a className="sp-logo">
                            Scool Panda<sup>Beta</sup>
                        </a>
                        {/* <div className="sp-search-top flexc">
                            <i className="icon-Search" />
                            <input
                                id="search"
                                className="sp-search-btn"
                                placeholder="Quick search..."
                            />
                        </div> */}
                    </div>
                    <div className="sp-middle-band">
                        <div className="sp-nav y-auto">
                            <div className="">
                                <h4 className="sp-title">Quick Actions</h4>
                                <ul>
                                    <li onClick={() => { navigate('/dashboard'); }}>
                                        <span>
                                            <i className="icon-Dashboard-Outline" />
                                            Dashboard
                                        </span>
                                    </li>
                                    <li className="selected">
                                        <span>
                                            <i className="icon-Activity-Outline" />
                                            Activity
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/calendar'); }}>
                                        <span>
                                            <i className="icon-Timetable-Outline" />
                                            Calendar
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/staff_list'); }}>
                                        <span>
                                            <i className="icon-Staff-Outline" />
                                            Staff
                                        </span>
                                    </li>
                                    {/* <li>
                    <span>
                      <i className="icon-Message-Outline" />
                      Messages
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Fees-Outline" />
                      Fees Management
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Enquiries-Outline" />
                      Enquiries
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Invites-Outline" />
                      Invites
                    </span>
                  </li> */}
                                </ul>
                                <h4 className="sp-title flexc justifySp" onClick={() => handleOpenNewProgramPopupVisible()}>
                                    Programs{" "}
                                    <span id="add-programs" className="sp-plus">
                                        +
                                    </span>
                                </h4>
                                <ul>
                                    {programsLoading && programsData && programsData.length > 0 ?
                                        programsData.map((data) => {
                                            return (
                                                <li onClick={() => {
                                                    localStorage.setItem("programId", data.id);
                                                    localStorage.setItem("programName", data.program_name);
                                                    navigate('/student_list');
                                                }}>
                                                    <span>
                                                        <i className="icon-Program-Outline-1" />
                                                        {data.program_name}
                                                    </span>
                                                </li>
                                            );
                                        }) :
                                        <div className="data-loader extracss_load">
                                            {/* <Loader
                                          type="spinner-cub"
                                          bgColor="#4D6EE3"
                                          color="#6B6F8C"
                                          title="Loading..."
                                          size={35}
                                        /> */}
                                            <WindmillSpinner loading={"loading"} color="#4d6ee3d6" size={30} />
                                            <p>Loading...</p>
                                        </div>
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="sp-bottom sp-nav">
                            <ul>
                                <li>
                                    <span>
                                        {/* <i className="icon-Import-Outline" /> */}
                                        <div className="noti_sdi">
                                            <img src={gift} alt="" style={{ height: "16px" }} />
                                            <p>Notifications</p>
                                        </div>

                                    </span>
                                </li>
                                <li>
                                    <span style={{ display: "block" }}>
                                        {/* <i className="icon-Invites-Outline" /> */}
                                        <div className="noti_tot">
                                            <div className="noti_sdi">
                                                <img src={upar} alt="" style={{ height: "18px", width: "18px" }} />
                                                <p>Upgrade Pro</p>
                                            </div>
                                            <img src={ques} alt="" style={{ height: "18px", width: "18px" }} />
                                        </div>
                                    </span>
                                </li>
                                <li onClick={() => navigate("/signout")}>
                                    <span>
                                        <i className="icon-Invites-Outline" />
                                        Log Out
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* my code */}
                <div className="sp-right-panel">
                    <div className="activity-container">
                        <div className="activity-inr">
                            <div className="act-announce-sh">
                                <div className="act-announce-inp flexc act_input">
                                    <div className="annonce-pers-left flexc ">
                                        <img
                                            src={avatar1}
                                            className="mr-rt10"
                                            width={38}
                                            height={38}
                                            alt="avatar"
                                        />
                                        <div className="annonce-pers-left-cont">
                                            <p onClick={handleModalOpen}>What's an announcement today?</p>
                                            {/* <input
                                                style={{ border: "0px" }}
                                                type="text"
                                                placeholder="Enter Title"
                                                onClick={handleModalOpen}

                                            /> */}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="announce-post-inr">
                                    <div className="act-announce-inp flexc act_input editorHeaderClassName">
                                        <input style={{ border: "0px" }}
                                            type="text"
                                            placeholder="Enter Title"
                                            value={postTitle}
                                            onChange={ev => setPostTitle(ev.target.value)}
                                        />
                                    </div>
                                    <Editor
                                        editorState={editorState}
                                        toolbarHidden
                                        editorClassName="editorClassName"
                                        onEditorStateChange={onEditorStateChange}
                                        placeholder="Add Descriptions..."
                                    />
                                    <div className="announce-post-cont flexc justifySp">
                                        <div className="announce-post-left flexc">
                                            <span className="ft-13" style={{ marginLeft: "10px", width: "100%" }}>
                                                <div>
                                                    <input
                                                        type="file"
                                                        id="imageInput"
                                                        style={{ display: "none", width: "100%" }}
                                                        accept=".jpg, .jpeg, .png, .gif"
                                                        multiple
                                                        onChange={handleImageChange}
                                                    />
                                                    <label
                                                        className="sp-post-bt ft-wt500 flexc"
                                                        htmlFor="imageInput"
                                                        style={{ paddingRight: "10px" }}
                                                    >
                                                        <img
                                                            src={imgDoc}
                                                            className="mr-rt10"
                                                            alt="avatar"
                                                        />
                                                        Photo
                                                    </label>
                                                    {selectedImage.length > 0 && <p style={{ fontSize: "10px" }}>{selectedImage.length} file{selectedImage.length > 1 && "s"} choosed</p>}

                                                </div>
                                            </span>
                                            <span className="ft-13" style={{ marginLeft: "10px", width: "100%", cursor: "pointer" }}>
                                                <div>
                                                    <input
                                                        type="file"
                                                        id="documentInput"
                                                        style={{ display: "none", width: "100%" }}
                                                        accept=".pdf, .doc, .docx"
                                                        multiple
                                                        onChange={handleDocumentChange}
                                                    />
                                                    <label
                                                        className="sp-post-bt ft-wt500 flexc"
                                                        htmlFor="documentInput"
                                                        style={{ paddingRight: "10px" }}
                                                    >
                                                        <img
                                                            src={imgDoc}
                                                            className="mr-rt10"
                                                            alt="avatar"
                                                        />
                                                        Document
                                                    </label>
                                                    {selectedDocument.length > 0 && <p style={{ fontSize: "10px" }}>{selectedDocument.length} file{selectedDocument.length > 1 && "s"} choosed</p>}

                                                </div>
                                            </span>
                                            <span className="flexc ft-13 sp-post-bt" style={{ marginLeft: "10px", cursor: "pointer" }}>
                                                <img
                                                    src={templateImg}
                                                    alt="template"
                                                />
                                                <div className="act_program" >
                                                    <div className="activity_drop">
                                                        <Dropdown
                                                            label={
                                                                <span style={{ color: "#707070", fontSize: "0.8825em" }}>
                                                                    {selectedTemplate ? selectedTemplate.template_name : "Select Template"}
                                                                </span>
                                                            }
                                                            style={{ width: "100%" }}
                                                        >
                                                            {templateData.map((item) => (
                                                                <Dropdown.Item
                                                                    className={selectedTemplate && selectedTemplate.id === item.id ? "dd_select" : null}
                                                                    icon={selectedTemplate && selectedTemplate.id === item.id ? HiCheck : null}
                                                                    onClick={() => changeTemplateData(item)}
                                                                    key={item.id}
                                                                >
                                                                    {item.template_name}
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </span>
                                            <span className="flexc ft-13 sp-post-bt" style={{ cursor: "pointer", backgroundColor: "unset" }}>

                                            </span>
                                        </div>
                                        <button className="flexc" onClick={() => { addPost(); }}>
                                          
                                            <img
                                                src={imgSend}
                                                alt="avatar"
                                                style={{ marginRight: "3px" }}
                                            />
                                            Post
                                        </button>
                                    </div>
                                </div> */}
                            </div>
                            <div className="sp-announcement">
                                {activitysLoading && activityData && activityData.length > 0 ? (
                                    activityData.map((data, index) => {
                                        const textContent = extractTextFromHTML(data.post_content);
                                        const isContentLong = wordCount(textContent) > 20;
                                        return (
                                            <div key={index} className="announcement-posts act-announce-sh mt-4">
                                                <div className="annonce-pers flexc justifySp">
                                                    <div className="annonce-pers-left flexc">
                                                        <img
                                                            src={avatar1}
                                                            className="mr-rt10"
                                                            width={38}
                                                            height={38}
                                                            alt="avatar"
                                                        />
                                                        <div className="annonce-pers-left-cont">
                                                            <p>
                                                                <span className="ft-wt700">{data.user}</span>
                                                                <span className="sp-crt-dot" />{data.program_name}
                                                            </p>
                                                            <span className="post-re-tm ft-13">{data.created_on}</span>
                                                        </div>
                                                    </div>
                                                    <span>
                                                        <i className="icon-More-Icon" />
                                                    </span>
                                                </div>
                                                {/* <div className="annoce-poster-inr">
                                                    <span className="ft-wt700">{data.post_title}</span><br />
                                                    <div className="mt-4">
                                                        <div dangerouslySetInnerHTML={{ __html: data.post_content }} />
                                                        {isContentLong && (
                                                            <div className="ft-13 mt-4 ft-wt600" style={{ cursor: "pointer" }} onClick={() => { openModal(data); }}>See more...</div>
                                                        )}
                                                    </div>
                                                </div> */}
                                                <div className="annoce-poster-inr">
                                                    <span className="ft-wt700">{data.post_title}</span><br />
                                                    <div className="mt-4">
                                                        <div
                                                            className="content"
                                                            dangerouslySetInnerHTML={{ __html: data.post_content }}
                                                            style={contentStyle}
                                                            ref={contentRef}
                                                        />
                                                        {isContentLong && (
                                                            <div className="ft-13 mt-4 ft-wt600" style={{ cursor: "pointer" }} onClick={() => { openModal(data); }}>See more...</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="post-images flex gap-1 flex-wrap cp mt-4" onClick={() => { openModal(data); }}>
                                                    {data.post_image && data.post_image.length > 0 ? (
                                                        <>
                                                            <div className="image-row flexc justifySp three-column">
                                                                {data.post_image.slice(0, 3).map((image, index) => (
                                                                    <img key={index} src={baseUrl + "/api/" + image} alt={`Image ${index + 1}`} />
                                                                ))}
                                                            </div>
                                                            {data.post_image.length > 3 && (
                                                                <div className="image-row flexc two-column justifySp relative">
                                                                    {data.post_image.slice(3, 4).map((image, index) => (
                                                                        <img key={index} src={baseUrl + "/api/" + image} alt={`Image ${index + 4}`} />
                                                                    ))}
                                                                    {data.post_image.length > 4 &&
                                                                        <div className="more-images absolute">
                                                                            <span className="more-text ft-17">+{data.post_image.length - 4}</span>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            )}
                                                        </>
                                                    ) : (<div></div>)}
                                                </div>
                                                <div>
                                                    {data.document && data.document.length > 0 ? (
                                                        data.document.map((documentData, docIndex) => (
                                                            <div key={docIndex} className="announce-post-left" style={{ marginTop: "5px" }}>
                                                                <span onClick={() => { downloadDocument(documentData.attachment_file, documentData.attachment_name); }} className="flexc ft-13 sp-post-bt" style={{ cursor: "pointer", width: "50%" }}>
                                                                    <i className="icon-Enquiries-Outline" />
                                                                    <p style={{ fontSize: "10px" }}>{documentData.attachment_name}</p>
                                                                </span>
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div></div>
                                                    )}
                                                </div>
                                                <div className="annoce-poster-btm flexc mt-4">
                                                    <span className="flexc" style={{ cursor: "pointer" }} onClick={() => { handleSendLike(data.id, (!data.like ? "add" : "delete")) }}>
                                                        <div className="act_like">
                                                            <i className={`${!data.like ? 'fa-regular fa-heart' : 'fa fa-heart clr_whi'}`}
                                                                aria-hidden="true"></i>
                                                        </div>
                                                        {data.likes || 0}
                                                    </span>
                                                    <span className="flexc" style={{ cursor: "pointer" }} onClick={() => { $(`#post-comments-inr-${index}`).slideToggle() }}>
                                                        <i className="icon-Message-Outline" />{data.comments || 0}
                                                    </span>
                                                </div>
                                                <div id={`post-comments-inr-${index}`} className="post-comments-inr" style={{ display: "none" }}>
                                                    <div className="comments-box" >
                                                        {
                                                            data.comments_data.length !== 0 &&
                                                            <div className="comments">
                                                                {data.comments_data.map((comment, commentIndex) => (
                                                                    <div className="mt-5 borderbt pb-2.5" key={commentIndex}>
                                                                        <div className="annonce-pers-left flexc">
                                                                            <img
                                                                                src={avatar5}
                                                                                className="mr-rt10"
                                                                                width={38}
                                                                                height={38}
                                                                                alt="avatar"
                                                                            />
                                                                            <div className="annonce-pers-left-cont">
                                                                                <p className="ft-13">
                                                                                    <span className="ft-wt600">{comment.user_name}</span>
                                                                                </p>
                                                                                <span className="post-re-tm ft-12">{new Date(comment.created_on).toLocaleString('en-US', {
                                                                                    year: 'numeric',
                                                                                    month: 'long',
                                                                                    day: 'numeric',
                                                                                    hour: '2-digit',
                                                                                    minute: '2-digit',
                                                                                    hour12: true
                                                                                })}</span>
                                                                            </div>
                                                                        </div>
                                                                        <p className="ft-13 leading-normal mt-4">
                                                                            {comment.comment}
                                                                        </p>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        }
                                                        <div className="sticky comment-type">
                                                            <div className="flexc flex-wrap">
                                                                <div className="profile-pic">
                                                                    <a>
                                                                        <img src={profile} className="rounded-full" />
                                                                    </a>
                                                                </div>
                                                                <div className="box-comment ml-2">
                                                                    <div className="input-container">
                                                                        <input
                                                                            type="text"
                                                                            value={comment}
                                                                            onChange={ev => setComment(ev.target.value)}
                                                                            placeholder="Write a comment"
                                                                            className="input-cmt ft-13"
                                                                        />
                                                                        <i
                                                                            className={`fa fa-paper-plane ${!comment.trim() ? 'disabled' : ''}`}
                                                                            aria-hidden="true"
                                                                            onClick={() => {
                                                                                if (comment.trim() !== "") {
                                                                                    handleSendComment(data.id);
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    (activitysLoading && activityData.length === 0) ? <center><div className="sp-title flex-3">No data found</div></center> :
                                        <div className="data-loader extracss_load">
                                            {/* <Loader
                                      type="spinner-cub"
                                      bgColor="#4D6EE3"
                                      color="#6B6F8C"
                                      title="Loading..."
                                      size={35}
                                    /> */}
                                            <WindmillSpinner loading={"loading"} color="#4d6ee3d6" size={30} />
                                            <p>Loading...</p>
                                        </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* light-box */}
            {isModalVisible && (
                <div className="modal-lightbox flex visible">
                    {
                        (activity.post_image.length > 1) ? (
                            <div className="img-container flexc alignC justify-center relative" >
                                <span className="left slide-arrow absolute left-10">
                                    <button className="active_col left-arr" onClick={previousSlide}>
                                        <span className="arrow"></span>
                                    </button>
                                </span>
                                <div className="slide-container" style={{ width: "80%", height: "80%" }}>
                                    <Slide ref={slideRef} arrows={false}>
                                        {activity.post_image.map((slideImage, index) => (
                                            <img
                                                key={index}
                                                src={baseUrl + "/api/" + slideImage}
                                                alt={`Image ${index + 1}`}
                                                style={{ width: "100%", height: "100%", objectFit: "fill" }}
                                            />
                                        ))}
                                    </Slide>
                                </div>
                                <span className="right slide-arrow absolute right-10">
                                    <button className="active_col right-arr" onClick={nextSlide}>
                                        <span className="arrow"></span>
                                    </button>
                                </span>
                            </div>
                        ) : (
                            <div className="img-container flexc alignC justify-center relative" >
                                <span className="left slide-arrow absolute left-10" style={{ backgroundColor: "unset" }}>
                                    <button className="active_col left-arr"></button>
                                </span>
                                <div className="images-slide-box flexc alignC">
                                    {activity.post_image.length === 1 &&
                                        <img
                                            src={baseUrl + "/api/" + activity.post_image[0]}
                                            alt="slide image"
                                            style={{ width: "100%", height: "100%", objectFit: "fill" }}
                                        />
                                    }

                                </div>
                                <span className="right slide-arrow absolute right-10" style={{ backgroundColor: "unset" }}>
                                    <button className="active_col right-arr"></button>
                                </span>
                            </div>
                        )
                    }
                    <div className="message-box p-6 overflow-y-auto h-screen py-0">
                        <span className="close icon-Dropdown_Close flexc cp ft-10" onClick={closeModal} />
                        <div className="annonce-pers-left flexc mt-7">
                            <img
                                src={avatar5}
                                className="mr-rt10"
                                width={38}
                                height={38}
                            />
                            <div className="annonce-pers-left-cont">
                                <p className="ft-13">
                                    <span className="ft-wt600">{activity.user}</span>
                                    <span className="sp-crt-dot" />{activity.program_name}</p>
                                <span className="post-re-tm ft-12">{activity.created_on}</span>
                            </div>
                        </div>
                        <div className="annoce-poster-inr">
                            <div dangerouslySetInnerHTML={{ __html: activity.post_content }} />
                        </div>
                        <div className="comments-box">
                            {activity.comments_data.map((comment, index) => (
                                <div className="mt-5 borderbt pb-2.5">
                                    <div className="annonce-pers-left flexc ">
                                        <img
                                            src={avatar5}
                                            className="mr-rt10"
                                            width={38}
                                            height={38}
                                        />
                                        <div className="annonce-pers-left-cont">
                                            <p className="ft-13">
                                                <span className="ft-wt600">{comment.user_name}</span>
                                            </p>
                                            <span className="post-re-tm ft-12">{new Date(comment.created_on).toLocaleString('en-US', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: true
                                            })}</span>
                                        </div>
                                    </div>
                                    <p className="ft-13 leading-normal mt-4">
                                        {comment.comment}
                                    </p>
                                </div>
                            ))}
                            <div className="sticky comment-type">
                                <div className="flexc flex-wrap">
                                    <div className="profile-pic">
                                        <a href="javascript:void(0)">
                                            <img src={profile} className="rounded-full" />
                                        </a>
                                    </div>
                                    <div className="box-comment ml-2">
                                        <div className="input-container">
                                            <input
                                                type="text"
                                                value={comment}
                                                onChange={ev => setComment(ev.target.value)}
                                                placeholder="Write a comment"
                                                className="input-cmt ft-13"
                                            />
                                            <i
                                                className={`fa fa-paper-plane ${!comment.trim() ? 'disabled' : ''}`}
                                                aria-hidden="true"
                                                onClick={() => {
                                                    if (comment.trim() !== "") {
                                                        handleSendComment(activity.id);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
            {/* light-box */}
            <div className="popup-modal-container" id="create-template-popup">
                <div className="popup-wrap activ-pop">
                    <h2 className="ft-wt700">Add New Template</h2>
                    <div className="sp-pop-header">
                        <div className="form-section-default flexc justifySp fwrap">
                            <div className="field-label halfplus-field">
                                <label>Template Name</label>
                                <div className="field-value-holder margin_top10p">
                                    <input className="sp-field-input" placeholder="Type name.." />
                                </div>
                            </div>
                            <div className="field-label halfmin-field">
                                <label>Template Type</label>
                                <div
                                    id="dropdownHelperButton"
                                    className="btn-dropdown margin_top10p"
                                    data-dropdown-toggle="dropdownHelper1"
                                    data-dropdown-offset-distance={1}
                                    data-dropdown-placement="bottom"
                                >
                                    <span className="flexc justifySp">
                                        Select Type
                                        <i className="icon-Down-Arrow" />
                                    </span>
                                </div>
                                <div className="btn-dropdown-fieldval hidden" id="dropdownHelper1">
                                    <ul className="activity-temp">
                                        <li className="selected">
                                            <span className="icon-rating sp-red-theme1" />
                                            Announcement
                                        </li>
                                        <li>
                                            <span className="icon-rating sp-green-theme1" />
                                            Event
                                        </li>
                                        <li>
                                            <span className="icon-rating sp-red-theme1" />
                                            Special Day
                                        </li>
                                        <li>
                                            <span className="icon-rating sp-orange-theme1" />
                                            Holiday
                                        </li>
                                        <li className="flexc new-doc mt-2">
                                            <i className="icon-Enquiries-Outline sp-violet-theme1" />
                                            Create New
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="field-label full-field">
                                <div className="field-value-holder margin_top10p">
                                    <div className="text-decrt">
                                        <button className="flexc ft-13 text-styl">
                                            Normal Text
                                            <span className="arrow up-arr" />
                                        </button>
                                    </div>
                                    <textarea
                                        className="sp-field-input"
                                        rows={10}
                                        defaultValue={""}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sp-bottom-sticky">
                        <button className="sp-btn-create" id="save-btn">
                            Save
                        </button>
                        <button className="sp-btn-cancel">Cancel</button>
                    </div>
                </div>
            </div>
            {/* Create New Program */}
            {
                newProgramPopupVisible && (
                    <div className="popup-program-container">
                        <div className="popup-wrap">
                            <h2 className="ft-wt700">Create New Program</h2>
                            <div className="sp-pop-header prl_1">
                                <div className="form-section-default flexc justifySp fwrap">
                                    <div className="field-label full-field error_mes_cus">
                                        <label>Program Name</label>
                                        <div className="field-value-holder margin_top10p">
                                            <input
                                                className="sp-field-input"
                                                type="text"
                                                placeholder="Enter Program name"
                                                value={newProgramName}
                                                onChange={(e) => setNewProgramName(e.target.value)}
                                            />
                                        </div>
                                        {programeNameError && <p className="error">{programeNameError}</p>}
                                    </div>
                                    <div className="field-label half-field error_mes_cus">
                                        <label>Program Teachers</label>
                                        <div className="field-value-holder margin_top10p dropbutton" >
                                            <Dropdown


                                                label={
                                                    <span
                                                        className={`${selectedTeachers ? 'selected-item' : ''}`}
                                                        style={{
                                                            fontSize: "13px",
                                                            justifyContent: "flex-start",
                                                            color: selectedTeachers ? '#1B252F' : '#A9AFC3'
                                                        }}
                                                    >
                                                        {selectedTeachers ? selectedTeachers.first_name : "Select Teacher"}</span>}
                                                style={{ width: '100%' }}
                                            >

                                                {teachersData.map(item => (
                                                    <Dropdown.Item
                                                        // className={selectedTeachers && selectedTeachers.id === item.id ? "dd_select" : null}
                                                        className={`${selectedTeachers && selectedTeachers.id === item.id ? "selected-item dd_select" : ""
                                                            }`}
                                                        icon={selectedTeachers && selectedTeachers.id === item.id ? HiCheck : null}
                                                        onClick={() => setTeacher(item.id)}
                                                        key={item.id}
                                                    >
                                                        {item.first_name}
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown>

                                        </div>
                                        {programeTeacherError && <p className="error">{programeTeacherError}</p>}
                                    </div>
                                    <div className="field-label half-field error_mes_cus">
                                        <label>Class Timing</label>
                                        <div className="flexc justifySp">
                                            <div className="half-field">
                                                <div className="field-value-holder margin_top10p dropbutton cus_drop_siz" >
                                                    <Dropdown
                                                        label={


                                                            <span
                                                                className={`${selectedStartTime ? 'selected-item' : ''}`}
                                                                style={{
                                                                    fontSize: "12px",
                                                                    justifyContent: "flex-start",
                                                                    color: selectedStartTime ? '#1B252F' : '#A9AFC3'
                                                                }}
                                                            >

                                                                {/* <span style={{ color: 'black', fontSize: '12px' }}> */}



                                                                <i class="fa-regular fa-clock" style={{ marginRight: '6PX' }}></i>
                                                                {selectedStartTime ? selectedStartTime.time_name : "Start Time"}</span>}
                                                        style={{
                                                            width: "100%"
                                                        }}
                                                    >
                                                        {timeData.map(item => (
                                                            <Dropdown.Item

                                                                //className={selectedStartTime && selectedStartTime.id === item.id ? "dd_select" : null}

                                                                className={`${selectedStartTime && selectedStartTime.id === item.id ? "selected-item dd_select" : ""
                                                                    }`}
                                                                icon={selectedStartTime && selectedStartTime.id === item.id ? HiCheck : null}
                                                                onClick={() => setProgramStartTime(item.id)}
                                                                key={item.id}
                                                            >
                                                                {item.time_name}
                                                            </Dropdown.Item>
                                                        ))}
                                                    </Dropdown>
                                                </div>
                                            </div>
                                            <div className="half-field">
                                                <div className="field-value-holder margin_top10p dropbutton cus_drop_siz" >
                                                    <Dropdown
                                                        label={


                                                            //  <span style={{ color: 'black', fontSize: '12px' }}>

                                                            <span
                                                                className={`${selectedEndTime ? 'selected-item' : ''}`}
                                                                style={{


                                                                    fontSize: "13px",
                                                                    fontWeight: "400",
                                                                    lineHeight: "18.2px",
                                                                    textAlign: "left",

                                                                    justifyContent: "flex-start",
                                                                    color: selectedEndTime ? '#1B252F' : '#A9AFC3'
                                                                }}
                                                            >



                                                                <i class="fa-regular fa-clock" style={{ marginRight: '6PX' }}>
                                                                </i>{selectedEndTime ? selectedEndTime.time_name : "End Time"}</span>}
                                                        style={{
                                                            width: "100%"
                                                        }}
                                                    >
                                                        {timeData.map(item => (
                                                            <Dropdown.Item
                                                                // className={selectedEndTime && selectedEndTime.id === item.id ? "dd_select" : null}
                                                                className={`${selectedEndTime && selectedEndTime.id === item.id ? "selected-item dd_select" : ""
                                                                    }`}

                                                                icon={selectedEndTime && selectedEndTime.id === item.id ? HiCheck : null}
                                                                onClick={() => setProgramEndTime(item.id)}
                                                                key={item.id}
                                                            >
                                                                {item.time_name}
                                                            </Dropdown.Item>
                                                        ))}
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </div>
                                        {timeError && <p className="error">{timeError}</p>}
                                    </div>
                                </div>

                                <h3 className="ft-wt600 mt-30">Customise Features</h3>
                                <div className="full-field mb-6">
                                    {Object.keys(features).map((feature) => (
                                        <div className="flex items-center mb-4 custom-checkbox dis_chek_cus mt-5" key={feature}>
                                            <input
                                                type="checkbox"
                                                className="w-4 h-4 text-blue-600 rounded"
                                                checked={features[feature].status}

                                                disabled={
                                                    (['qrCode', 'attendance', 'food', 'sleep', 'leaves'].includes(feature))
                                                    // features[feature].status=== true
                                                }
                                                onChange={() => handleFeatureChange(feature)}
                                            />
                                            <label className="ms-2 text-sm">{features[feature].name}</label>
                                            {['busTracking', 'cctv', 'fees'].includes(feature) && <img src={SplImg} alt="spl" style={{ width: '25px', height: '15px', marginLeft: "5px" }} />}
                                        </div>
                                    ))}
                                </div>
                                <h3 className="ft-wt600 mt-30">Additional Informations</h3>
                                <div className="full-field">
                                    {Object.keys(additionalInfo).map((info) => (
                                        <div className="flex items-center mb-4 custom-checkbox mt-5" key={info}>
                                            <input
                                                type="checkbox"
                                                className="w-4 h-4 text-blue-600 rounded"
                                                checked={additionalInfo[info].status}
                                                onChange={() => handleAdditionalInfoChange(info)}
                                            />
                                            <label className="ms-2 text-sm">{additionalInfo[info].name}</label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="sp-bottom-sticky">
                                <button className="sp-btn-create crt_div" onClick={() => handleCreateProgram()}>Create Program</button>
                                <button className="sp-btn-cancel">Cancel</button>
                            </div>
                        </div>
                    </div>
                )
            }
        </section>
    );
}





