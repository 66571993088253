import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Snackbar from '@mui/material/Snackbar';
import file from "./images/fileupload.svg";
import axios from "axios";
import { baseUrl } from "../../common/common";
import filec from "./images/fileupload-c.svg";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function Import({ importStudent, handleImportStatus, setProgress, setIsLoading }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [fileMessage, setFileMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });

  const handleSnackbarClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  const handleSnackbarOpen = () => {
    setSnackbarState({ ...snackbarState, open: true });
  };

  const handleModalOpen = () => setOpenModal(true);
  const handleModalClose = () => setOpenModal(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };

  useEffect(() => { }, [fileMessage, messageType]);

  const importStudents = async (event) => {
    const importFile = event.target.files[0];
    const formData = new FormData();
    formData.append("file", importFile);
    let response;
    setIsLoading(true);
    setProgress(0);

    if (importStudent === "Student") {
      formData.append("programs_id", localStorage.getItem("programId"));
      formData.append("school_id", localStorage.getItem("schoolId"));
      response = await axios.post(baseUrl + "/api/student_import", formData, {
        headers: { "Content-Type": "multipart/form-data","Authorization": localStorage.getItem('access_token')},
        onUploadProgress: (progressEvent) => {
          setProgress((progressEvent.loaded / progressEvent.total) * 100);
        }
      });
      //  response = {
      //   data: {
      //     status: false,
      //     message: "Students imported with errors",
      //     errors: [
      //       "Row 2 has empty fields. Skipping...",
      //       "Row 4 has empty fields. Skipping..."
      //     ]
      //   },
      //   onUploadProgress: (progressEvent) => {
      //     setProgress((progressEvent.loaded / progressEvent.total) * 100);
      //   }
      // };

      // response = {
      //   data: {
      //     status: true,
      //     message: "Students imported sucessfully",
      //     errors: []
      //   }
      // };
    } else if (importStudent === "staff") {
      console.log("staff");
      formData.append("school_id", localStorage.getItem("schoolId"));
      response = await axios.post(baseUrl + "/api/staff_import", formData, {
        headers: { "Content-Type": "multipart/form-data", "Authorization": localStorage.getItem('access_token')},
        onUploadProgress: (progressEvent) => {
          setProgress((progressEvent.loaded / progressEvent.total) * 100);
        }
      });
    }
    if (response.data.status === true) {
      handleImportStatus(true);
      setMessageType('success');
      setFileMessage(response.data.message);
      handleSnackbarOpen();
    } else {
      handleImportStatus(false);
      setMessageType('error');
      setFileMessage(response.data.message);
      setErrorMessage(response.data.errors);
      handleSnackbarOpen();
      handleModalOpen();
    }

    setIsLoading(false);
    setProgress(100);
  };

  return (
    <section className="import_cust">
      <Snackbar
        anchorOrigin={{ vertical: snackbarState.vertical, horizontal: snackbarState.horizontal }}
        open={snackbarState.open}
        onClose={handleSnackbarClose}
        message={fileMessage}
        ContentProps={{
          sx: {
            border: "1px solid",
            opacity: "1",
            background: messageType === 'success' ? "#40BE61" : "#FD4954",
            borderColor: messageType === 'success' ? "#40BE61" : "#FD4954"
          }
        }}
        autoHideDuration={3000}
      />
      <Modal
        open={openModal}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div style={{ fontWeight: "600" }}>
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ color: '#000' }}>
              Errors
            </Typography>
          </div>
          <Typography id="modal-modal-description" sx={{ mt: 2, color: '#000' }}>
            {errorMessage.length > 0 ? (
              <ul>
                {errorMessage.map((error, index) => (
                  <li key={index}>
                    <Typography>{index + 1}. {error}</Typography>
                  </li>
                ))}
              </ul>
            ) : (
              <Typography>No errors found.</Typography>
            )}
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: "20px", width: "100%" }}><Button onClick={handleModalClose}>Close</Button></div>
        </Box>
      </Modal>
      <div className="outer-container">
        <div className="overlay" />
        <div className="load-container">
          <div className="sp-table-import y-auto flexc justify-center">
            <div className="sp-import-container">
              <section className="sp-top-box" id="upload-dropbox-zone">
                <img src={file} width={58} height={60} className="file-img" />
                <div className="ft-13 clr-grey line-h-4 mt-4">
                  Drag and drop file here or
                  <span className="relative clr-theme1 ft-wt500" style={{ marginLeft: "3px" }}>
                    <input
                      type="file"
                      title=""
                      multiple=""
                      accept=".jpg, .jpeg, .png, .webp, .dmg"
                      aria-labelledby="upload-dropbox-zone"
                      onChange={importStudents}
                    />
                    Choose file
                  </span>
                  <span className="ft-12 block">support XLSX files only</span>
                </div>
                <div className="flexc justify-center mt-6">
                  <div className="file-input-container">
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      accept=".xlsx"
                      onChange={importStudents}
                    />
                    <label
                      className="createN ft-wt500 flexc"
                      htmlFor="fileInput"
                    >
                      <i className="icon-Import-Outline" />
                      Import
                    </label>
                  </div>
                  <button
                    className="sp-view-btn ft-wt500 mr-lf10"
                    id="newStudentCreate"
                  >
                    Add Manually
                  </button>
                </div>
              </section>
              <section className="sp-bt-box mt-5">
                <div className="flexc justifySp">
                  <div className="">
                    <h4 className="ft-13 ft-wt600 flexc">
                      <img
                        src={filec}
                        width={28}
                        height={60}
                        className="file-col mr-2"
                      />
                      Table Sample
                    </h4>
                    <p className="ft-13 mt-2 line-h-4">
                      You can download the attached example and use them as a
                      starting point of your own file.
                    </p>
                  </div>
                  {importStudent === "Student" ? (
                    <button
                      className="sp-view-btn ft-wt500 mr-lf10"
                      onClick={() =>
                        (window.location.href = "/sample_file.xlsx")
                      }
                    >
                      Download
                    </button>
                  ) : (
                    <button
                      className="sp-view-btn ft-wt500 mr-lf10"
                      onClick={() => (window.location.href = "/Staff.xlsx")}
                    >
                      Download
                    </button>
                  )}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
