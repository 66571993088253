import {useEffect} from "react";

export default function Signout() {

    useEffect(() => {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = "/";   
    })
    
    return(
        <div>...Loading</div>
    );

}