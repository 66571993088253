import React, { useState, useMemo, useEffect, useRef } from "react";
import { Link, Navigate, Outlet, useNavigate } from "react-router-dom";
import "../signin/css/style.css";
import panda from "./images/panda.png";
import spriteImage from './images/sprite-sp.svg';
import $ from "jquery";
import axios from 'axios';
import { baseUrl } from "../../common/common";
import Loader from "react-js-loader";
import validator from "validator";
import Snackbar from '@mui/material/Snackbar';
import ListItemIcon from '@mui/material/ListItemIcon';
import SuccessIcon from "./images/success.png";
import ErrorIcon from "./images/error.png";

export default function Signin() {

  const navigate = useNavigate();

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'center'
  });
  const handleSnackbarClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };
  const handleSnackbarOpen = () => {
    setSnackbarState({ ...snackbarState, open: true});
  };
  useEffect(() => { }, [snackbarMessage, messageType]);


  const [userName, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const passwordInputRef = useRef(null);

  const handleKeyDown = (event, targetRef) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submit
      targetRef.current && targetRef.current.focus();
    }
  };

  const login = () => {
    let valid = true;
    if (userName === "") {
      setEmailError("Email cannot be empty");
    } else {
      if (!validator.isEmail(userName)) {
        setEmailError("Please Enter Valid Email");
        valid = false;
      } else {
        setEmailError(null);
      }
    }
    if (userPassword === "") {
      setPasswordError("Password cannot be empty")
      valid = false;
    } else {
      setPasswordError(null);
    }
    if (valid) {
      axios({
        method: "post",
        url: baseUrl + "/api/schoollogin",
        data: {
          username: userName,
          password: userPassword
        },
        headers: {
          "Content-Type": "multipart/form-data"
        },
      }).then((response) => {
        console.log(response);
        if (response.data.status === true) {
          setSnackbarMessage(response.data.msg);
          setMessageType('success');
          handleSnackbarOpen();
          localStorage.setItem('schoolId', response.data.data[0].school_id);
          localStorage.setItem('loginUserId', response.data.data[0].id);
          localStorage.setItem('loginUserName', response.data.data[0].first_name);
          localStorage.setItem('roleId', response.data.data[0].role_id);
          localStorage.setItem('access_token', response.data.access_token);
          navigate('/student_list');
          console.log(response);
        } else {
          // setError(response.data.message);
          setEmailError(response['data']['emailerror']);
          setPasswordError(response['data']['passerror']);
        }
      });
    }

  }

  return (
    <section className="sign_in_cus">
      <Snackbar
        anchorOrigin={{ vertical: snackbarState.vertical, horizontal: snackbarState.horizontal }}
        open={snackbarState.open}
        onClose={handleSnackbarClose}
        message={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ListItemIcon>
              <img src={messageType === 'success' ?SuccessIcon:ErrorIcon} alt="Success" style={{ width: '24px', height: '24px', marginRight: '8px' }} />
            </ListItemIcon>
            <div className="success_cuc">
                            <p> {snackbarMessage} </p>
                        </div>
          </div>
        }
        ContentProps={{
          sx: {
            border: "1px solid",
            opacity: "1",
            background: messageType === 'success' ? "#40BE61" : "#FD4954",
            borderColor: messageType === 'success' ? "#40BE61" : "#FD4954",
            width: '415px'
          }
        }}
        autoHideDuration={3000}
      />
      <div className="whole-container sign_page">
        {/* Login Welcome back */}
        <div className="flex-prop form-wrap">
          <div className="form-container">
            <div className="mrg-cen text-align-c main_panda">
              <img src={panda} style={{ width: "80px", height: "100" }} width={80} height={100} alt="Scool Panda" />
              <h1>
                Welcome back!
                <span className="dis-block">
                  to transforming Learning Communication
                </span>
              </h1>
            </div>
            <div className="sign-form mt-40 add_style_input input_forsign">
              <div className="forms-fields-text error_mes_cus">

                <label htmlFor="name">Email Address</label>
                <div className="icon_input">
                  <input type="email"
                    value={userName}
                    onChange={ev => setUserName(ev.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, passwordInputRef)}
                    placeholder="johndoe@workemail.com"
                    style={emailError ? { border: '1px solid red' } : {}} />
                  <span className="sp-icon-mail" />
                </div>
                {emailError && <p className="error">{emailError}</p>}
              </div>
              <div className="forms-fields-text error_mes_cus">
                <label htmlFor="name">Password</label>
                <div className="icon_input">
                  <input type="password"
                    ref={passwordInputRef}
                    value={userPassword}
                    onChange={ev => setUserPassword(ev.target.value)}
                    onKeyDown={(e) => e.key === 'Enter' && login()}
                    placeholder="8+ Character required"
                    style={passwordError ? { border: '1px solid red' } : {}} />
                  <span className="sp-icon-pw" />
                </div>
                {passwordError && <p className="error">{passwordError}</p>}
              </div>
              <div className="flex-prop forms-field-btn differ_input">
                <div className="flex-prop toggleswitch">
                  <input type="checkbox" defaultValue="" id="toggle-s" />
                  <label
                    className="inline-flex items-center cursor-pointer"
                    htmlFor="toggle-s"
                  ></label>
                  <span className=" text-sm">Keep me login</span>
                </div>
                <div className="text-fwd">
                  <Link to="/forgot_password">Forgot password?</Link>
                </div>
              </div>
              <button onClick={login} className="verify-btn" id="sp-login">
                Login
              </button>
              <div className="form-bottom mt-30">
                <p>Or login using your Email</p>
                <div className="form-otherlogins">
                  <span className="sp-gmail-acc">Gmail</span>{" "}
                  <span className="sp-microsoft-acc">Microsoft</span>
                </div>
              </div>
              <div className="form-bottom mt-40 span_acount">
                <p>Don't have an account?<span > <Link to="/signup">Sign Up</Link> </span> </p>
              </div>
              {/* </form> */}
            </div>
          </div>
        </div>
        {/* Login Welcome back */}
      </div>
    </section>
  );
}

