import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import $ from "jquery";
import { useLocation, useNavigate } from "react-router-dom";
import { baseUrl } from "../../common/common";
import Loader from "react-js-loader";
import '../../common/components/css/style.css';
import avatar1 from "./images/avatar1.png";
import { Dropdown } from 'flowbite-react';
import "../../index.css";
import { HiCheck } from "react-icons/hi";
import Snackbar from '@mui/material/Snackbar';
import ListItemIcon from '@mui/material/ListItemIcon';
import SuccessIcon from "./images/success.png";
import ErrorIcon from "./images/error.png";
import SplImg from "./images/spl.png";
import ImportComponent from "../Import/Import";
import cross1 from "./images/cross1.svg";
import leave1 from "./images/leave1.svg";
import upar from "./images/upar.svg";
import ques from "./images/ques.svg";
import de from "./images/de.svg";
import gift from "./images/gift.svg";
import { WindmillSpinner } from 'react-spinner-overlay';
import moment from 'moment';


export default function Food() {

    const navigate = useNavigate();

    const [programsData, setProgramsData] = useState({});
    const [programsLoading, setProgramsLoading] = useState(false);

    const fetchProgramsData = async () => {
        setProgramsLoading(false);
        try {
            const postData = {
                school_id: localStorage.getItem('schoolId')
                // school_id:24
            }
            const { data } = await axios.post(baseUrl + "/api/get_programs", postData, {
                headers: {
                    "Authorization": localStorage.getItem('access_token')
                }
            });
            setProgramsData(data.data);
            setProgramsLoading(true);
        } catch (error) {
            console.log(error)
        }
    }

    const [foodData, setFoodData] = useState({});
    const [dataHeader, setDataHeader] = useState([]);
    const [foodHeaderdata, setFoodHeaderData] = useState([]);
    const [foodLoading, setFoodLoading] = useState(false);

    const [filterValue, setFilterValue] = useState('');
    const handleInputChange = async (event) => {
        setFilterValue(event.target.value);
        try {
            axios({
                method: "post",
                url: baseUrl + "/api/student_list_admin",
                data: {
                    school_id: localStorage.getItem('schoolId'),
                    programs_id: localStorage.getItem('programId'),
                    search: event.target.value.trim(),
                    list_status: 3,
                    previous_week: curruntWeek
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                console.log(response);
                if (response['data']['status'] === true) {
                    setFoodData(response.data);
                    setDataHeader(response.data.dateresult);
                    setFoodHeaderData(response.data.data);
                    setFoodLoading(true);
                } else {
                    setFoodData(response.data);
                    setFoodLoading(true);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    const [curruntWeek, setCurruntWeek] = useState(0);
    const fetchFoodData = (week) => {
        setFoodLoading(false);
        try {
            axios({
                method: "post",
                url: baseUrl + "/api/student_list_admin",
                data: {
                    school_id: localStorage.getItem('schoolId'),
                    programs_id: localStorage.getItem('programId'),
                    search: "",
                    list_status: 3,
                    previous_week: week
                },
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                console.log(response);
                if (response['data']['status'] === true) {
                    setFoodData(response.data);
                    setDataHeader(response.data.dateresult);
                    setFoodHeaderData(response.data.data);
                    setFoodLoading(true);
                } else {
                    setFoodData(response.data);
                    setStudentCount(false);
                    setFoodLoading(true);
                }
            });
        } catch (error) {
            console.log(error);
        }
    };


    const [addFoodPopupVisible, setAddFoodPopupVisible] = useState(false);
    const openAddFoodPopup = (foodData) => {
        setAddFoodPopupVisible(true);
        setStudentId(foodData.student_id);
        setFoodDate(foodData.formattedDate);
        setFoodId(null);
        setFoodTimeError(null);
        setQuantityError(null);
        setFoodTypeError(null);
        setCategoryError(null);
        setDescriptionError(null);
        setBorderColor(initialBorderColor);
        setFoodDetails({
            foodTime: '',
            quantity: '',
            foodType: '',
            category: '',
            description: ''
        });
    }

    const closeAddFoodPopup = () => {
        setAddFoodPopupVisible(false);
    }

    const [updateFoodPopupVisible, setUpdateFoodPopupVisible] = useState(false);
    const openUpdateFoodPopup = (foodData) => {
        setAddFoodPopupVisible(true)
        setFoodId(foodData.id)
        setStudentId(foodData.student_id)
        setFoodDate(foodData.formattedDate)
        console.log(foodData)
        setFoodDetails({
            foodTime: foodData.food_time_id,
            quantity: foodData.quantity_id,
            foodType: foodData.type_id,
            category: foodData.category_id,
            description: foodData.message
        })
        setFoodTimeError(null);
        setQuantityError(null);
        setFoodTypeError(null);
        setCategoryError(null);
        setDescriptionError(null);
        setBorderColor(initialBorderColor);
    }

    const [foodDetails, setFoodDetails] = useState({
        foodTime: '',
        quantity: '',
        foodType: '',
        category: '',
        description: 'You children finish the Lunch.'
    })

    const [foodId, setFoodId] = useState(null);
    const [studentId, setStudentId] = useState("");
    const [foodDate, setFoodDate] = useState("");
    // const [quantity, setQuantity] = useState("");
    // const [foodType, setFoodType] = useState("");    
    // const [category, setCategory] = useState("");    
    // const [description, setDescription] = useState("");

    const [foodTimeError, setFoodTimeError] = useState(null);
    const [quantityError, setQuantityError] = useState(null);
    const [foodTypeError, setFoodTypeError] = useState(null);
    const [categoryError, setCategoryError] = useState(null);
    const [descriptionError, setDescriptionError] = useState(null);

    const validateForm = () => {
        let valid = true;
        if (foodDetails.foodTime === "") {
            setFoodTimeError("Please Select Food Time");
            valid = false;
        } else {
            setFoodTimeError(null);
        }
        if (foodDetails.quantity === "") {
            setQuantityError("Please Select Quantity");
            valid = false;
        } else {
            setQuantityError(null);
        }
        if (foodDetails.foodType === "") {
            setFoodTypeError("Please Select Food Type");
            valid = false;
        } else {
            setFoodTypeError(null);
        }
        if (foodDetails.category === "") {
            setCategoryError("Please Select Category");
            valid = false;
        } else {
            setCategoryError(null);
        }
        // if (foodDetails.description === "") {
        //     setDescriptionError("Please Enter Description");
        //     valid = false;
        // } else {
        //     setDescriptionError(null);
        // }
        return valid;
    };

    const addFood = () => {
        if (validateForm()) {
            const postData = {
                school_id: localStorage.getItem('schoolId'),
                staff_id: 5,
                food_date: foodDate,
                programs_id: localStorage.getItem('programId'),
                student_ids: [studentId],
                food_time_id: foodDetails.foodTime,
                type_id: foodDetails.foodType,
                quantity_id: foodDetails.quantity,
                category_id: foodDetails.category,
                message: foodDetails.description,
                created_by: localStorage.getItem('schoolId')
            };
            console.log(postData);
            axios({
                method: "post",
                url: baseUrl + "/api/food_add",
                data: postData,
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": localStorage.getItem('access_token')
                },
            }).then((response) => {
                console.log(response);
                if (response.data.status === true) {
                    setSnackbarMessage(response.data.message);
                    setMessageType('success');
                    handleSnackbarOpen();
                    // setTimeout(function () {
                    //     window.location.reload();
                    // }, 500);
                    closeAddFoodPopup();
                    fetchFoodData(0);

                } else {
                    setSnackbarMessage(response.data.message);
                    setMessageType('error');
                    handleSnackbarOpen();
                }
            });
        }
    };

    const updateFood = () => {
        const postData = {
            id: foodId,
            school_id: localStorage.getItem('schoolId'),
            staff_id: 5,
            food_date: foodDate,
            programs_id: localStorage.getItem('programId'),
            student_id: studentId,
            food_time_id: foodDetails.foodTime,
            type_id: foodDetails.foodType,
            quantity_id: foodDetails.quantity,
            category_id: foodDetails.category,
            message: foodDetails.description,
            updated_by: localStorage.getItem('loginUserId')
        }
        console.log(postData)
        axios({
            method: "post",
            url: baseUrl + "/api/food_edit",
            data: postData,
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setSnackbarMessage(response.data.message);
                setMessageType('success');
                handleSnackbarOpen();
                // setTimeout(function () {
                //     window.location.reload();
                // }, 500);
                closeAddFoodPopup();
                fetchFoodData(0);

            } else {
                setSnackbarMessage(response.data.message);
                setMessageType('error');
                handleSnackbarOpen();
            }
        });

    }

    const [quantityLoading, setQuantityLoading] = useState(false);
    const [quantityData, setQuantityData] = useState([]);
    const fetchQuantityData = async () => {
        setQuantityLoading(false);
        axios({
            method: "post",
            url: baseUrl + "/api/get_food_quantity",
            // data:{},
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setQuantityData(response.data.data);
                console.log(response);
                setQuantityLoading(true);
            }
        });
    }

    const [foodTimeLoading, setFoodTimeLoading] = useState(false);
    const [foodTimeData, setFoodTimeData] = useState([]);
    const fetchFoodTimeData = async () => {
        setFoodTimeLoading(false);
        axios({
            method: "post",
            url: baseUrl + "/api/get_time",
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setFoodTimeData(response.data.data);
                console.log(response);
                setFoodTimeLoading(true);
            }
        });
    }

    const [foodTypeLoading, setFoodTypeLoading] = useState(false);
    const [foodTypeData, setFoodTypeData] = useState([]);
    const fetchFoodTypeData = async () => {
        setFoodTypeLoading(false);
        axios({
            method: "post",
            url: baseUrl + "/api/get_food_type",
            // data:{},
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setFoodTypeData(response.data.data);
                console.log(response);
                setFoodTypeLoading(true);
            }
        });
    }

    const [foodCategoryLoading, setFoodCategoryLoading] = useState(false);
    const [foodCategoryData, setFoodCategoryData] = useState([]);
    const fetchFoodCategoryData = async () => {
        setFoodCategoryLoading(false);
        axios({
            method: "post",
            url: baseUrl + "/api/get_food_category",
            // data:{},
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setFoodCategoryData(response.data.data);
                console.log(response);
                setFoodCategoryLoading(true);
            }
        });
    }

    const selectedQuantity = quantityData.find(data => data.id === foodDetails.quantity);
    const selectedFoodTime = foodTimeData.find(data => data.id === foodDetails.foodTime);
    const selectedFoodType = foodTypeData.find(data => data.id === foodDetails.foodType);
    const selectedCategory = foodCategoryData.find(data => data.id === foodDetails.category);

    // ------11-06----------------
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [snackbarState, setSnackbarState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center'
    });
    const handleSnackbarClose = () => {
        setSnackbarState({ ...snackbarState, open: false });
    };
    const handleSnackbarOpen = () => {
        setSnackbarState({ ...snackbarState, open: true });
    };
    useEffect(() => { }, [snackbarMessage, messageType]);

    // ---------13-06----------------
    const [studentCount, setStudentCount] = useState(true);

    // -------------------------19-06-------------------------

    const handleOpenNewProgramPopupVisible = () => {
        setProgramNameError(null);
        setProgramTeacherError(null);
        setTimeError(null);
        setTeacher("");
        setProgramStartTime("");
        setProgramEndTime("");
        setNewProgramPopupVisible(true);
    }

    const [newProgramPopupVisible, setNewProgramPopupVisible] = useState(false);
    const [programStartTime, setProgramStartTime] = useState("");
    const [programEndTime, setProgramEndTime] = useState("");
    const [programTeacher, setTeacher] = useState("");
    const [timeData, setTimeData] = useState([]);
    const [teachersData, setTeachersData] = useState([]);
    const selectedStartTime = timeData.find(data => data.id === programStartTime);
    const selectedEndTime = timeData.find(data => data.id === programEndTime);
    const selectedTeachers = teachersData.find(data => data.id === programTeacher);

    const fetchProgramTimeData = async () => {
        axios({
            method: "post",
            url: baseUrl + "/api/get_time",
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setTimeData(response.data.data);
                console.log(response);
            }
        });
    }

    const fetchTeachersData = async () => {
        axios({
            method: "post",
            url: baseUrl + "/api/teacher_list",
            data: {
                school_id: localStorage.getItem("schoolId")
            },
            headers: {
                "Content-Type": "multipart/form-data",
                "Authorization": localStorage.getItem('access_token')
            },
        }).then((response) => {
            console.log(response);
            if (response.data.status === true) {
                setTeachersData(response.data.data);
                console.log(response);
            }
        });
    }

    const [newProgramName, setNewProgramName] = useState('');
    const [features, setFeatures] = useState({
        qrCode: { status: true, name: 'QR Code', value: 2 },
        attendance: { status: true, name: 'Attendance', value: 5 },
        food: { status: true, name: 'Food', value: 3 },
        sleep: { status: true, name: 'Sleep', value: 4 },
        leaves: { status: true, name: 'Leaves', value: 6 },
        busTracking: { status: false, name: 'Bus Tracking', value: 7 },
        cctv: { status: false, name: 'CCTV', value: 8 },
        fees: { status: false, name: 'Fees', value: 9 },
    });
    const [additionalInfo, setAdditionalInfo] = useState({
        inviteParents: {
            status: true,
            name: 'Automatically Invite Parents as soon as student profile created',
            value: 1
        },
        addParentChat: {
            status: true,
            name: 'Add Parent in chat as soon as student profile created',
            value: 2
        },
        createStudentID: {
            status: true,
            name: 'Automatically create Student ID as soon as profile created',
            value: 3
        },
    });


    const handleFeatureChange = (feature) => {
        setFeatures({
            ...features,
            [feature]: {
                ...features[feature],
                status: !features[feature].status,
            },
        });
    };
    const handleAdditionalInfoChange = (info) => {
        setAdditionalInfo({
            ...additionalInfo,
            [info]: {
                ...additionalInfo[info],
                status: !additionalInfo[info].status,
            },
        });
    };

    const [programeNameError, setProgramNameError] = useState(null);
    const [programeTeacherError, setProgramTeacherError] = useState(null);
    const [timeError, setTimeError] = useState(null);
    const validateProgramForm = () => {
        let valid = true;
        if (newProgramName === "") {
            setProgramNameError("Please enter program name");
            valid = false;
        } else {
            setProgramNameError(null);
        }
        if (programTeacher === "") {
            setProgramTeacherError("Please select program teacher");
            valid = false;
        } else {
            setProgramTeacherError(null);
        }
        // if (programStartTime === "" || programEndTime === "") {
        //   setTimeError("Please select start and end time");
        //   valid = false;
        // } else {
        //   setTimeError(null);
        // }
        return valid;
    };

    const handleCreateProgram = () => {

        const selectedFeatureValues = Object.keys(features).filter((key) => features[key].status).map((key) => features[key].value);
        const selectedAdditionalInfoValues = Object.keys(additionalInfo).filter((key) => additionalInfo[key].status).map((key) => additionalInfo[key].value);

        console.log({
            school_id: localStorage.getItem("schoolId"),
            program_name: newProgramName,
            teacher_id: programTeacher,
            from_time_id: programStartTime,
            to_time_id: programEndTime,
            features: selectedFeatureValues.join(","),
            additional_info: selectedAdditionalInfoValues.join(","),
            status: 1,
            created_by: localStorage.getItem("loginUserId")
        });
        if (validateProgramForm()) {
            try {
                axios({
                    method: "post",
                    url: baseUrl + "/api/programs_add_edit",
                    data: {
                        school_id: localStorage.getItem("schoolId"),
                        program_name: newProgramName,
                        teacher_id: programTeacher,
                        from_time_id: programStartTime,
                        to_time_id: programEndTime,
                        features: selectedFeatureValues.join(","),
                        additional_info: selectedAdditionalInfoValues.join(","),
                        status: 1,
                        created_by: localStorage.getItem("loginUserId")
                    },
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": localStorage.getItem('access_token')
                    },
                }).then((response) => {
                    console.log(response);
                    if (response.data.status === true) {
                        $('.overlay').removeClass('visible');
                        $('.popup-program-container').removeClass('visible');
                        setNewProgramPopupVisible(false);
                        fetchProgramsData();
                        setSnackbarMessage(response.data.message);
                        setMessageType('success');
                        handleSnackbarOpen();
                    } else {
                        setSnackbarMessage(response.data.message);
                        setMessageType('error');
                        handleSnackbarOpen();
                    }
                });
            } catch (error) {
                console.log(error)
            }
        }

    };

    const initialBorderColor = {
        timeBorder: "",
        quantityBorder: "",
        typeBorder: "",
        categoryBorder: ""
    };

    const [borderColor, setBorderColor] = useState(initialBorderColor);

    const handleFocus = (borderColor) => {
        if (borderColor === "timeBorder") {
            setBorderColor({ ...borderColor, timeBorder: "#4D6EE3" });
        } else if (borderColor === "quantityBorder") {
            setBorderColor({ ...borderColor, quantityBorder: "#4D6EE3" });
        } else if (borderColor === "typeBorder") {
            setBorderColor({ ...borderColor, typeBorder: "#4D6EE3" });
        } else if (borderColor === "categoryBorder") {
            setBorderColor({ ...borderColor, categoryBorder: "#4D6EE3" });
        }
    };

    const handleBlur = () => {
        setBorderColor(initialBorderColor);
    };
    const [progress, setProgress] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [importstatus, setimportstatus] = useState(false);

    const importStudents1 = () => {
        setimportstatus(true);
    };

    const handleImportStatus = (status) => {
        if (status == true) {
            setimportstatus(false);
        }
    };
    const [isFocused, setIsFocused] = useState(false);
    // 
    const foodInputRef = useRef(null);
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside1);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside1);
        };
    }, []);
    const handleClickOutside1 = (event) => {
        if (foodInputRef.current && !foodInputRef.current.contains(event.target)) {
            setBorderColor(initialBorderColor);
        }


    };

    // ----- 04-07  active date----------
    const inputDateObj = moment("Thu Jul 04 2024", 'ddd MMM DD YYYY').toDate();
    const currentDateObj = new Date();
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };
    const formattedCurrentDate = formatDate(currentDateObj);

    useEffect(() => {
        fetchFoodTimeData();
        fetchFoodTypeData();
        fetchQuantityData();
        fetchFoodCategoryData();
        fetchProgramsData();
        fetchTeachersData();
        fetchProgramTimeData();
        fetchFoodData(0);
    }, []);

    return (
        <section className="staff_atten_cus food_cus">
            <Snackbar
                anchorOrigin={{ vertical: snackbarState.vertical, horizontal: snackbarState.horizontal }}
                open={snackbarState.open}
                onClose={handleSnackbarClose}
                message={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <ListItemIcon>
                            <img src={messageType === 'success' ? SuccessIcon : ErrorIcon} alt="Success" style={{ width: '24px', height: '24px', marginRight: '8px' }} />
                        </ListItemIcon>
                        <div className="success_cuc">
                            <p> {snackbarMessage} </p>
                        </div>
                    </div>
                }
                ContentProps={{
                    sx: {
                        border: "1px solid",
                        opacity: "1",
                        background: messageType === 'success' ? "#40BE61" : "#FD4954",
                        borderColor: messageType === 'success' ? "#40BE61" : "#FD4954",
                        width: '415px'
                    }
                }}
                autoHideDuration={3000}
            />
            <div className="outer-container">
                <div className="overlay" />
                <div id="left-panel" className="sp-left-panel">
                    <div className="sp-top-band">
                        <a className="sp-logo">
                            Scool Panda<sup>Beta</sup>
                        </a>
                        {/* <div className="sp-search-top flexc">
                            <i className="icon-Search" />
                            <input
                                id="search"
                                className="sp-search-btn"
                                placeholder="Quick search..."
                            />
                        </div> */}
                    </div>
                    <div className="sp-middle-band">
                        <div className="sp-nav y-auto">
                            <div className="">
                                <h4 className="sp-title">Quick Actions</h4>
                                <ul>
                                    <li onClick={() => { navigate('/dashboard'); }}>
                                        <span>
                                            <i className="icon-Dashboard-Outline" />
                                            Dashboard
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/activity'); }}>
                                        <span>
                                            <i className="icon-Activity-Outline" />
                                            Activity
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/calendar'); }}>
                                        <span>
                                            <i className="icon-Timetable-Outline" />
                                            Calendar
                                        </span>
                                    </li>
                                    <li onClick={() => { navigate('/staff_list'); }}>
                                        <span>
                                            <i className="icon-Staff-Outline" />
                                            Staff
                                        </span>
                                    </li>
                                    {/* <li>
                    <span>
                      <i className="icon-Message-Outline" />
                      Messages
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Fees-Outline" />
                      Fees Management
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Enquiries-Outline" />
                      Enquiries
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Invites-Outline" />
                      Invites
                    </span>
                  </li> */}
                                </ul>
                                <h4 className="sp-title flexc justifySp" onClick={() => handleOpenNewProgramPopupVisible()}>
                                    Programs{" "}
                                    <span id="add-programs" className="sp-plus">
                                        +
                                    </span>
                                </h4>
                                <ul>
                                    {programsLoading && programsData && programsData.length > 0 ?
                                        programsData.map((data, index) => {
                                            return (
                                                <li className={data.id === parseInt(localStorage.getItem('programId')) ? "selected" : ""} onClick={() => {
                                                    localStorage.setItem('programId', data.id);
                                                    localStorage.setItem('programName', data.program_name);
                                                    fetchFoodData(curruntWeek);
                                                    navigate('/food');
                                                }}>
                                                    <span>
                                                        <i className="icon-Program-Outline-1" />
                                                        {data.program_name}
                                                    </span>
                                                </li>
                                            );
                                        }) :
                                        <div className="data-loader extracss_load">
                                            {/* <Loader
                                                type="spinner-cub"
                                                bgColor="#4D6EE3"
                                                color="#6B6F8C"
                                                title="Loading..."
                                                size={35}
                                            /> */}
                                            <WindmillSpinner loading={"loading"} color="#4d6ee3d6" size={30} />
                                            <p>Loading...</p>
                                        </div>
                                    }

                                </ul>
                            </div>
                        </div>
                        <div className="sp-bottom sp-nav">
                            <ul>
                                <li>
                                    <span>
                                        {/* <i className="icon-Import-Outline" /> */}
                                        <div className="noti_sdi">
                                            <img src={gift} alt="" style={{ height: "16px" }} />
                                            <p>Notifications</p>
                                        </div>

                                    </span>
                                </li>
                                <li>
                                    <span style={{ display: "block" }}>
                                        {/* <i className="icon-Invites-Outline" /> */}
                                        <div className="noti_tot">
                                            <div className="noti_sdi">
                                                <img src={upar} alt="" style={{ height: "18px", width: "18px" }} />
                                                <p>Upgrade Pro</p>
                                            </div>
                                            <img src={ques} alt="" style={{ height: "18px", width: "18px" }} />
                                        </div>
                                    </span>
                                </li>
                                <li onClick={() => navigate("/signout")}>
                                    <span>
                                        <i className="icon-Invites-Outline" />
                                        Log Out
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="sp-right-panel">
                    <div className="sp-top-bar flexc justifySp exta_pad_10">
                        <div className="ft-wt600 flexc pad_ic_ri">
                            <span className="selected-icon">
                                <i className="icon-Program-Outline-1" />
                            </span>
                            {localStorage.getItem('programName')}
                            <i className="icon-More-Icon" />
                        </div>
                        <div className="flexc">
                            <button className="sp-view-btn ft-wt500 flexc"
                                onClick={() => {
                                    importStudents1();
                                }}>
                                <i className="icon-Import-Outline" />
                                Import
                            </button>
                            <button className="createN ft-wt500 mr-lf10 space_icon_pad"><i class="fa-solid fa-plus"></i> New Student</button>
                        </div>
                    </div>
                    <div className="sp-header">
                        <nav className="flexc">
                            <div className="sp-list-view flexc" onClick={() => { navigate('/student_list'); }}>
                                <i className="icon-Students-Outline" />
                                Student List
                            </div>
                            <div className="sp-list-view flexc" onClick={() => { navigate('/student_attendance'); }}>
                                <i className="icon-Attendance-Outline" />
                                Attendance
                            </div>
                            <div className="sp-list-view flexc selected">
                                <i className="icon-Food" />
                                Food
                            </div>
                            <div className="sp-list-view flexc" onClick={() => { navigate('/sleep'); }}>
                                <i className="icon-Sleep" />
                                Sleep
                            </div>
                            <div className="sp-list-view flexc" onClick={() => { navigate('/student_timetable'); }}>
                                <i className="icon-Calendar-Outline" />
                                Timetable
                            </div>
                        </nav>
                        {
                            (studentCount && importstatus) === false &&
                            <div className="sp-menu-bar flexc justifySp">
                                <div className="sp-menugroup">
                                    <div className="flexc">
                                        <div className={`btn-search-bar flexc ${isFocused ? 'focused' : ''}`}>
                                            <i className="icon-Search" />
                                            <input
                                                type="search"
                                                placeholder="Search Students"
                                                value={filterValue}
                                                onChange={handleInputChange}
                                                onFocus={() => setIsFocused(true)}
                                                onBlur={() => setIsFocused(false)}
                                                className="search-input"
                                            />
                                        </div>
                                        <div className="week-detail flexc">
                                            {
                                                <button className="set-bord left-arr" onClick={() => {
                                                    setCurruntWeek(curruntWeek + 7);
                                                    fetchFoodData(curruntWeek + 7);
                                                }}>
                                                    <span className="arrow" />
                                                </button>
                                            }
                                            <button className="sel-days set-bord">
                                                Week
                                            </button>
                                            <button className="set-bord right-arr" onClick={() => {
                                                const newWeek = Math.max(0, curruntWeek - 7);
                                                setCurruntWeek(newWeek);
                                                fetchFoodData(newWeek);
                                            }}
                                                disabled={
                                                    curruntWeek === 0
                                                }
                                            >
                                                <span className="arrow" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {importstatus ?
                        <ImportComponent
                            importStudent={"Student"}
                            handleImportStatus={handleImportStatus}
                            setProgress={setProgress} setIsLoading={setIsLoading}
                        />
                        : <div className="he_fix_cus">
                            {
                                (foodLoading && foodData) ? (
                                    foodData.status === true ? (
                                        <div className="std-attence">
                                            <div className="attence-top overxy-auto">
                                                <div className="students-add-list">
                                                    <ul className="sp-head sp_sticky defult-date-header">
                                                        <li>{foodData.student}</li>
                                                        {dataHeader && dataHeader.length > 0 ?
                                                            (dataHeader).map((data, index) => {
                                                                return (
                                                                    <li key={index} className={`${formatDate(moment(data.date_data, 'ddd MMM DD YYYY').toDate()) === formattedCurrentDate && 'active-date-header'}`}
                                                                    >{data.date_data}</li>
                                                                );
                                                            }) : <div></div>}
                                                    </ul>
                                                    {foodHeaderdata.map((student, studentIndex) => (
                                                        <React.Fragment key={studentIndex}>
                                                            <ul>
                                                                <li>
                                                                    <span className="ft-500 flexc truncate img_wid_cu">
                                                                        <img src={
                                                                            student.profile_image !== null
                                                                                ? baseUrl +
                                                                                "/api/" +
                                                                                student.profile_image
                                                                                : avatar1
                                                                        } width={40} alt="Avatar" />
                                                                        <span className="mr-lf10 ft-14 truncate name_bold">{student.name}</span>
                                                                    </span>
                                                                </li>
                                                                {student.food.map((foodData, foodIndex) => (
                                                                    <React.Fragment key={foodIndex}>
                                                                        {foodData.food_value.length > 0 ? (
                                                                            <li
                                                                                className={`${formatDate(moment(foodData.date, 'ddd MMM DD YYYY').toDate()) === formattedCurrentDate ? 'active-date-data' :
                                                                                    formatDate(moment(foodData.date, 'ddd MMM DD YYYY').toDate()) > formattedCurrentDate && 'next-date-data'} ${(studentIndex === (foodHeaderdata.length - 1) && formatDate(moment(foodData.date, 'ddd MMM DD YYYY').toDate()) === formattedCurrentDate) ? 'active-last-date-data' : ''}`}
                                                                            >
                                                                                <div className="" >
                                                                                    {foodData.food_value.map((food, timeIndex) => (
                                                                                        <div className="sp-tag-selected food_date mr-lf10 " key={timeIndex} style={{ cursor: "pointer" }} onClick={() => {
                                                                                            openUpdateFoodPopup(
                                                                                                {
                                                                                                    id: food.food_data.id,
                                                                                                    student_id: student.id,
                                                                                                    formattedDate: foodData.formattedDate,
                                                                                                    food_time_id: food.food_data.food_time_id,
                                                                                                    quantity_id: food.food_data.quantity_id,
                                                                                                    type_id: food.food_data.type_id,
                                                                                                    category_id: food.food_data.category_id,
                                                                                                    message: food.food_data.message
                                                                                                }
                                                                                                //    student,foodData.formattedDate
                                                                                            );
                                                                                        }}>
                                                                                            {food.food_value}
                                                                                        </div>
                                                                                    ))}

                                                                                </div>
                                                                                {foodData.attendance_status === 1 &&
                                                                                    <div className="sp-add-food cp" onClick={() => {
                                                                                        openAddFoodPopup(
                                                                                            {
                                                                                                student_id: student.id,
                                                                                                formattedDate: foodData.formattedDate
                                                                                            }
                                                                                        );
                                                                                    }}>
                                                                                        <i className="icon-plus-w" />
                                                                                    </div>
                                                                                }
                                                                            </li>
                                                                        ) : (
                                                                            <li
                                                                                className={`${formatDate(moment(foodData.date, 'ddd MMM DD YYYY').toDate()) === formattedCurrentDate ? 'active-date-data' :
                                                                                    formatDate(moment(foodData.date, 'ddd MMM DD YYYY').toDate()) > formattedCurrentDate && 'next-date-data'} ${(studentIndex === (foodHeaderdata.length - 1) && formatDate(moment(foodData.date, 'ddd MMM DD YYYY').toDate()) === formattedCurrentDate) ? 'active-last-date-data' : ''}`}
                                                                            >
                                                                                {foodData.attendance_status === 1 ? (
                                                                                    <div className="sp-add-food cp" onClick={() => {
                                                                                        //openAddFoodPopup(student,foodData.formattedDate);
                                                                                        openAddFoodPopup(
                                                                                            {
                                                                                                student_id: student.id,
                                                                                                formattedDate: foodData.formattedDate
                                                                                            }
                                                                                        );
                                                                                    }}>
                                                                                        <i className="icon-plus-w" />
                                                                                    </div>
                                                                                ) : foodData.attendance_status === 2 ? (
                                                                                    // <span className="notapp-ele" />
                                                                                    <img src={leave1} alt="" style={{ width: '16px' }} />
                                                                                ) : foodData.attendance_status > 2 ? (
                                                                                    // <span className="cross-ele" />
                                                                                    <img src={cross1} alt="" style={{ width: '16px' }} />
                                                                                ) : null}
                                                                            </li>
                                                                        )}
                                                                    </React.Fragment>
                                                                ))}
                                                            </ul>
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        foodData.status === false && (
                                            <center><div className="sp-title flex-3">{foodData.message}</div></center>
                                        )
                                    )
                                ) : (
                                    <div className="data-loader extracss_load">
                                        {/* <Loader
                                            type="spinner-cub"
                                            bgColor="#4D6EE3"
                                            color="#6B6F8C"
                                            title="Loading..."
                                            size={35}
                                            /> */}
                                        <WindmillSpinner loading={"loading"} color="#4d6ee3d6" size={30} />
                                        <p>Loading...</p>
                                    </div>
                                )
                            }
                        </div>
                    }
                </div>
                {/* my code */}
                {
                    addFoodPopupVisible && (
                        <div className="dialog-model absolute selected" style={{ display: "block", zIndex: "999" }}>
                            <div className="sp-tt ft-14 flexc ft-wt600">{foodId === null ? "Add" : "Update"} Food</div>
                            <div className="form-section-default flexc justifySp fwrap">
                                <div className="field-label half-field error_mes_cus">
                                    <label>Food Time</label>
                                    <div
                                        className="field-value-holder margin_top10p dropbutton"
                                        ref={foodInputRef}
                                        style={{
                                            width: "100%",
                                            borderColor: foodTimeError ? "red" : borderColor.timeBorder,
                                        }}
                                        onFocus={() => handleFocus("timeBorder")}
                                        onBlur={handleBlur}
                                    >
                                        <Dropdown
                                            // label={<span style={{ color: 'black' }}>{selectedFoodTime ? selectedFoodTime.time_name : "Select Time"}</span>}
                                            label={
                                                <span
                                                    style={{
                                                        fontSize: "0.8825em",
                                                        color: selectedFoodTime ? '#1B252F' : '#A9AFC3',
                                                        justifyContent: "flex-start"
                                                    }}
                                                >
                                                    {selectedFoodTime ? selectedFoodTime.time_name : "Select Time"}
                                                </span>
                                            }
                                            style={{ width: '100%' }}
                                        >
                                            {foodTimeData.map(item => (
                                                <Dropdown.Item
                                                    key={item.id}
                                                    className={`${selectedFoodTime && selectedFoodTime.id === item.id ? "selected-item dd_select" : ""
                                                        }`}
                                                    icon={selectedFoodTime && selectedFoodTime.id === item.id ? HiCheck : null}
                                                    onClick={() => setFoodDetails({ ...foodDetails, foodTime: item.id })}
                                                >
                                                    {item.time_name}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown>
                                    </div>
                                    {foodTimeError && <p className="error">{foodTimeError}</p>}
                                </div>
                                <div className="field-label half-field dropbutton error_mes_cus">
                                    <label>Quantity</label>
                                    <div
                                        className="field-value-holder margin_top10p"
                                        style={{
                                            width: "100%",
                                            borderColor: quantityError ? "red" : borderColor.quantityBorder,
                                        }}
                                        onFocus={() => handleFocus("quantityBorder")}
                                        onBlur={handleBlur}
                                    >

                                        <Dropdown
                                            label={<span
                                                style={{
                                                    fontSize: "0.8825em",
                                                    color: selectedQuantity ? '#1B252F' : '#A9AFC3',
                                                    justifyContent: "flex-start"
                                                }}
                                            >
                                                {selectedQuantity ? selectedQuantity.quantity_name : "Select Quantity"}
                                            </span>}
                                            style={{ width: '100%' }}
                                        >
                                            {quantityData.map(item => (
                                                <Dropdown.Item
                                                    className={`${selectedQuantity && selectedQuantity.id === item.id ? "selected-item dd_select" : ""
                                                        }`}
                                                    // className={selectedQuantity && selectedQuantity.id === item.id ? "dd_select" : null}
                                                    icon={selectedQuantity && selectedQuantity.id === item.id ? HiCheck : null}
                                                    onClick={() => setFoodDetails({ ...foodDetails, quantity: item.id })}
                                                    key={item.id}
                                                >
                                                    {item.quantity_name}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown>
                                    </div>
                                    {quantityError && <p className="error">{quantityError}</p>}
                                </div>
                                <div className="field-label half-field dropbutton error_mes_cus">
                                    <label>Type</label>
                                    <div
                                        className="field-value-holder margin_top10p"
                                        style={{
                                            width: "100%",
                                            borderColor: foodTypeError ? "red" : borderColor.typeBorder,
                                        }}
                                        onFocus={() => handleFocus("typeBorder")}
                                        onBlur={handleBlur}
                                    >
                                        <Dropdown
                                            label={
                                                <span
                                                    style={{
                                                        fontSize: "0.8825em",
                                                        color: selectedFoodType ? '#1B252F' : '#A9AFC3',
                                                        justifyContent: "flex-start"
                                                    }}>
                                                    {selectedFoodType ? selectedFoodType.type_name : "Select Type"}</span>}
                                            style={{ width: '100%' }}
                                        >
                                            {foodTypeData.map(item => (
                                                <Dropdown.Item
                                                    className={`${selectedFoodType && selectedFoodType.id === item.id ? "selected-item dd_select" : ""
                                                        }`}
                                                    // className={selectedFoodType && selectedFoodType.id === item.id ? "dd_select" : null}
                                                    icon={selectedFoodType && selectedFoodType.id === item.id ? HiCheck : null}
                                                    onClick={() => setFoodDetails({ ...foodDetails, foodType: item.id })}
                                                    key={item.id}
                                                >
                                                    {item.type_name}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown>
                                    </div>
                                    {foodTypeError && <p className="error">{foodTypeError}</p>}
                                </div>
                                <div className="field-label half-field dropbutton error_mes_cus">
                                    <label>Category</label>
                                    <div
                                        className="field-value-holder margin_top10p"
                                        style={{
                                            width: "100%",
                                            borderColor: categoryError ? "red" : borderColor.categoryBorder,
                                        }}
                                        onFocus={() => handleFocus("categoryBorder")}
                                        onBlur={handleBlur}

                                    >
                                        <Dropdown
                                            label={
                                                <span
                                                    style={{
                                                        fontSize: "0.8825em",
                                                        color: selectedCategory ? '#1B252F' : '#A9AFC3',
                                                        justifyContent: "flex-start"
                                                    }}
                                                >
                                                    {selectedCategory ? selectedCategory.category_name : "Select Category"}
                                                </span>}
                                            style={{ width: '100%' }}
                                        >
                                            {foodCategoryData.map(item => (
                                                <Dropdown.Item
                                                    className={`${selectedCategory && selectedCategory.id === item.id ? "selected-item dd_select" : ""
                                                        }`}
                                                    // className={selectedCategory && selectedCategory.id === item.id ? "dd_select" : null}
                                                    icon={selectedCategory && selectedCategory.id === item.id ? HiCheck : null}
                                                    onClick={() => setFoodDetails({ ...foodDetails, category: item.id })}
                                                    key={item.id}
                                                >
                                                    {item.category_name}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown>
                                    </div>
                                    {categoryError && <p className="error">{categoryError}</p>}
                                </div>
                                <div className="field-label full-field dropbutton error_mes_cus">
                                    <label>Description</label>
                                    <div className="field-value-holder margin_top10p">
                                        <textarea
                                            value={foodDetails.description}
                                            // type="text"
                                            onChange={ev => setFoodDetails({ ...foodDetails, description: ev.target.value })}
                                            className="sp-field-input"
                                            placeholder="Description"
                                        />
                                    </div>
                                    {/* {descriptionError && <p className="error">{descriptionError}</p>} */}
                                </div>
                                <div className="flexc mt-4">
                                    <div className="flexc">
                                        <button className="createN ft-wt500 mr-2" onClick={() => { foodId === null ? addFood() : updateFood() }}>{foodId === null ? "Save" : "Update"}</button>
                                        <button className="sp-view-btn ft-wt500 flexc cancel-btn" onClick={() => closeAddFoodPopup()}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

                {/* Create New Program */}
                {
                    newProgramPopupVisible && (
                        <div className="popup-program-container">
                            <div className="popup-wrap">
                                <h2 className="ft-wt700">Create New Program</h2>
                                <div className="sp-pop-header prl_1">
                                    <div className="form-section-default flexc justifySp fwrap">
                                        <div className="field-label full-field error_mes_cus">
                                            <label>Program Name</label>
                                            <div className="field-value-holder margin_top10p">
                                                <input
                                                    className="sp-field-input"
                                                    type="text"
                                                    placeholder="Enter Program name"
                                                    value={newProgramName}
                                                    onChange={(e) => setNewProgramName(e.target.value)}
                                                />
                                            </div>
                                            {programeNameError && <p className="error">{programeNameError}</p>}
                                        </div>
                                        <div className="field-label half-field error_mes_cus">
                                            <label>Program Teachers</label>
                                            <div className="field-value-holder margin_top10p dropbutton" >
                                                <Dropdown


                                                    label={
                                                        <span
                                                            className={`${selectedTeachers ? 'selected-item' : ''}`}
                                                            style={{
                                                                fontSize: "13px",
                                                                justifyContent: "flex-start",
                                                                color: selectedTeachers ? '#1B252F' : '#A9AFC3'
                                                            }}
                                                        >
                                                            {selectedTeachers ? selectedTeachers.first_name : "Select Teacher"}</span>}
                                                    style={{ width: '100%' }}
                                                >

                                                    {teachersData.map(item => (
                                                        <Dropdown.Item
                                                            // className={selectedTeachers && selectedTeachers.id === item.id ? "dd_select" : null}
                                                            className={`${selectedTeachers && selectedTeachers.id === item.id ? "selected-item dd_select" : ""
                                                                }`}
                                                            icon={selectedTeachers && selectedTeachers.id === item.id ? HiCheck : null}
                                                            onClick={() => setTeacher(item.id)}
                                                            key={item.id}
                                                        >
                                                            {item.first_name}
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown>

                                            </div>
                                            {programeTeacherError && <p className="error">{programeTeacherError}</p>}
                                        </div>
                                        <div className="field-label half-field error_mes_cus">
                                            <label>Class Timing</label>
                                            <div className="flexc justifySp">
                                                <div className="half-field">
                                                    <div className="field-value-holder margin_top10p dropbutton cus_drop_siz" >
                                                        <Dropdown
                                                            label={


                                                                <span
                                                                    className={`${selectedStartTime ? 'selected-item' : ''}`}
                                                                    style={{
                                                                        fontSize: "12px",
                                                                        justifyContent: "flex-start",
                                                                        color: selectedStartTime ? '#1B252F' : '#A9AFC3'
                                                                    }}
                                                                >

                                                                    {/* <span style={{ color: 'black', fontSize: '12px' }}> */}



                                                                    <i class="fa-regular fa-clock" style={{ marginRight: '6PX' }}></i>
                                                                    {selectedStartTime ? selectedStartTime.time_name : "Start Time"}</span>}
                                                            style={{
                                                                width: "100%"
                                                            }}
                                                        >
                                                            {timeData.map(item => (
                                                                <Dropdown.Item

                                                                    //className={selectedStartTime && selectedStartTime.id === item.id ? "dd_select" : null}

                                                                    className={`${selectedStartTime && selectedStartTime.id === item.id ? "selected-item dd_select" : ""
                                                                        }`}
                                                                    icon={selectedStartTime && selectedStartTime.id === item.id ? HiCheck : null}
                                                                    onClick={() => setProgramStartTime(item.id)}
                                                                    key={item.id}
                                                                >
                                                                    {item.time_name}
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                <div className="half-field">
                                                    <div className="field-value-holder margin_top10p dropbutton cus_drop_siz" >
                                                        <Dropdown
                                                            label={


                                                                //  <span style={{ color: 'black', fontSize: '12px' }}>

                                                                <span
                                                                    className={`${selectedEndTime ? 'selected-item' : ''}`}
                                                                    style={{


                                                                        fontSize: "13px",
                                                                        fontWeight: "400",
                                                                        lineHeight: "18.2px",
                                                                        textAlign: "left",

                                                                        justifyContent: "flex-start",
                                                                        color: selectedEndTime ? '#1B252F' : '#A9AFC3'
                                                                    }}
                                                                >



                                                                    <i class="fa-regular fa-clock" style={{ marginRight: '6PX' }}>
                                                                    </i>{selectedEndTime ? selectedEndTime.time_name : "End Time"}</span>}
                                                            style={{
                                                                width: "100%"
                                                            }}
                                                        >
                                                            {timeData.map(item => (
                                                                <Dropdown.Item
                                                                    // className={selectedEndTime && selectedEndTime.id === item.id ? "dd_select" : null}
                                                                    className={`${selectedEndTime && selectedEndTime.id === item.id ? "selected-item dd_select" : ""
                                                                        }`}

                                                                    icon={selectedEndTime && selectedEndTime.id === item.id ? HiCheck : null}
                                                                    onClick={() => setProgramEndTime(item.id)}
                                                                    key={item.id}
                                                                >
                                                                    {item.time_name}
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            {timeError && <p className="error">{timeError}</p>}
                                        </div>
                                    </div>

                                    <h3 className="ft-wt600 mt-30">Customise Features</h3>
                                    <div className="full-field mb-6">
                                        {Object.keys(features).map((feature) => (
                                            <div className="flex items-center mb-4 custom-checkbox dis_chek_cus mt-5" key={feature}>
                                                <input
                                                    type="checkbox"
                                                    className="w-4 h-4 text-blue-600 rounded"
                                                    checked={features[feature].status}

                                                    disabled={
                                                        (['qrCode', 'attendance', 'food', 'sleep', 'leaves'].includes(feature))
                                                        // features[feature].status=== true
                                                    }
                                                    onChange={() => handleFeatureChange(feature)}
                                                />
                                                <label className="ms-2 text-sm">{features[feature].name}</label>
                                                {['busTracking', 'cctv', 'fees'].includes(feature) && <img src={SplImg} alt="spl" style={{ width: '25px', height: '15px', marginLeft: "5px" }} />}
                                            </div>
                                        ))}
                                    </div>
                                    <h3 className="ft-wt600 mt-30">Additional Informations</h3>
                                    <div className="full-field">
                                        {Object.keys(additionalInfo).map((info) => (
                                            <div className="flex items-center mb-4 custom-checkbox mt-5" key={info}>
                                                <input
                                                    type="checkbox"
                                                    className="w-4 h-4 text-blue-600 rounded"
                                                    checked={additionalInfo[info].status}
                                                    onChange={() => handleAdditionalInfoChange(info)}
                                                />
                                                <label className="ms-2 text-sm">{additionalInfo[info].name}</label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="sp-bottom-sticky">
                                    <button className="sp-btn-create crt_div" onClick={() => handleCreateProgram()}>Create Program</button>
                                    <button className="sp-btn-cancel">Cancel</button>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </section>
    );

}
