import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import panda from "./images/panda.png";
import $ from "jquery";
import { baseUrl } from "../../common/common";
import Loader from "react-js-loader";
import "../../common/components/css/style.css";
import axios from "axios";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment-timezone";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Dropdown } from "flowbite-react";
import "../../index.css";
import { HiCheck } from "react-icons/hi";
import imgEdit from "./images/edit_icon.svg";
import imgDelete from "./images/delete_icon.svg";
import close from "./images/close_icon.svg";
import Snackbar from "@mui/material/Snackbar";
import ListItemIcon from "@mui/material/ListItemIcon";
import SuccessIcon from "./images/success.png";
import ErrorIcon from "./images/error.png";
import SplImg from "./images/spl.png";
import gift from "./images/gift.svg";
import upar from "./images/upar.svg";
import ques from "./images/ques.svg";
import CustomToolbar from "./ToolbarComponent";

export default function EventCalendar() {

  const navigate = useNavigate();

  const [programsData, setProgramsData] = useState([]);
  const [selectedProgramId, setSelectedProgramId] = useState(null);
  const [programsLoading, setProgramsLoading] = useState(false);

  const fetchProgramsData = async () => {
    setProgramsLoading(false);
    try {
      const postData = {
        school_id: localStorage.getItem("schoolId"),
      };
      const { data } = await axios.post(
        baseUrl + "/api/get_programs",
        postData,
        {
          headers: {
            Authorization: localStorage.getItem("access_token"),
          },
        }
      );
      setProgramsData(data.data);
      setProgramsLoading(true);
    } catch (error) {
      console.log(error);
    }
  };

  const localizer = momentLocalizer(moment);

  const [program, setProgram] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [category, setCategory] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const selectedCategory = categoryData.find((data) => data.id === category);
  const fetchCategoryData = async () => {
    axios({
      method: "post",
      url: baseUrl + "/api/get_event_category",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("access_token"),
      },
    }).then((response) => {
      console.log(response);
      if (response.data.status === true) {
        setCategoryData(response.data.data);
        console.log(response);
      }
    });
  };

  const [foodTimeData, setFoodTimeData] = useState([]);
  const selectedStartTime = foodTimeData.find((data) => data.id === startTime);
  const selectedEndTime = foodTimeData.find((data) => data.id === endTime);
  const fetchFoodTimeData = async () => {
    axios({
      method: "post",
      url: baseUrl + "/api/get_time",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("access_token"),
      },
    }).then((response) => {
      console.log(response);
      if (response.data.status === true) {
        setFoodTimeData(response.data.data);
        console.log(response);
      }
    });
  };

  const selectedProgram = programsData.find((data) => data.id === selectedProgramId);

  const [events, setEvents] = useState([]);
  const [showEventPopup, setShowEventPopup] = useState(false);
  const [showAddEventPopup, setShowAddEventPopup] = useState(false);
  const [newEvent, setNewEvent] = useState({
    id: "",
    title: "",
    start: "",
    end: "",
    startTime: "",
    endTime: "",
    program: "",
    category: "",
    notify: true,
    description: "",
  });
  const [currentView, setCurrentView] = useState("month");
  const [addUpdateStatus, setAddUpdateStatus] = useState("");

  const handleViewChange = (view) => {
    setCurrentView(view);
  };

  const handleSelectSlot = ({ start, end }) => {
    setEndTimeError(null);
    setStartTimeError(null);
    setShowEventPopup(false);
    setAddUpdateStatus("add");
    setCategory("");
    setEndTime("");
    setStartTime("");
    setProgram("");
    const formatDate = (date) => {
      return date
        .toLocaleDateString("en-GB", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .split("/")
        .reverse()
        .join("-");
    };
    const adjustedEnd = new Date(end);
    adjustedEnd.setDate(adjustedEnd.getDate() - 1);
    const newEvent = {
      id: "",
      title: "",
      start: formatDate(start),
      end: currentView === "month" ? formatDate(adjustedEnd) : formatDate(end),
      startTime: "",
      endTime: "",
      program: "",
      category: "",
      notify: true,
      description: "",
    };
    setNewEvent(newEvent);
    setShowAddEventPopup(true);
  };

  const [titleError, setTitleError] = useState(null);
  const [categoryError, setCategoryError] = useState(null);
  const [startTimeError, setStartTimeError] = useState(null);
  const [endTimeError, setEndTimeError] = useState(null);
  const [programError, setProgramError] = useState(null);

  const handleAddEvent = () => {
    let hasError = false;

    if (newEvent.title === "") {
      setTitleError("Please Enter Event Name");
      hasError = true;
    } else {
      setTitleError(null);
    }
    // if (!category) {
    //     setCategoryError("Please Select a Category");
    //     hasError = true;
    // } else {
    //     setCategoryError(null);
    // }
    if (startTime === "") {
      setStartTimeError("Please Select Start Time");
      hasError = true;
    } else {
      setStartTimeError(null);
    }
    if (endTime === "") {
      setEndTimeError("Please Select End Time");
      hasError = true;
    } else {
      setEndTimeError(null);
    }

    if (hasError) return;

    const formattedStartDate = `${newEvent.start} ${selectedStartTime ? selectedStartTime.time_name : "00:00"
      }`;
    const formattedEndDate = `${newEvent.end} ${selectedEndTime ? selectedEndTime.time_name : "00:00"
      }`;

    const postData = {
      school_id: localStorage.getItem("schoolId"),
      event_type: category,
      programs_id: program,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      description: newEvent.description,
      title: newEvent.title,
      from_time_id: startTime,
      to_time_id: endTime,
      calendar_status: 1,
      status: 1,
    };

    if (newEvent.id && newEvent.id !== "") {
      postData.id = newEvent.id;
      postData.updated_by = localStorage.getItem("loginUserId");
    } else {
      postData.created_by = localStorage.getItem("loginUserId");
    }
    setTitleError(null);
    setStartTimeError(null);
    setEndTimeError(null);
    console.log(postData);
    axios({
      method: "post",
      url: `${baseUrl}/api/timetable_add_edit`,
      data: postData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("access_token"),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.data.status === true) {
          setShowAddEventPopup(false);
          setSnackbarMessage(response.data.message);
          setMessageType("success");
          handleSnackbarOpen();
          fetchCalendarData();
        } else {
          setSnackbarMessage(response.data.message);
          setMessageType("error");
          handleSnackbarOpen();
        }
      })
      .catch((error) => {
        console.error("There was an error adding/updating the event!", error);
      });
  };

  const handleDeleteEvent = (id) => {
    axios({
      method: "post",
      url: `${baseUrl}/api/timetable_delete`,
      data: {
        id: id,
        updated_by: localStorage.getItem("loginUserId"),
      },
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("access_token"),
      },
    })
      .then((response) => {
        console.log(response);
        if (response.data.status === true) {
          setSnackbarMessage(response.data.message);
          setMessageType("success");
          handleSnackbarOpen();
          fetchCalendarData();
        } else {
          setSnackbarMessage(response.data.message);
          setMessageType("error");
          handleSnackbarOpen();
        }
      })
      .catch((error) => {
        console.error("There was an error adding/updating the event!", error);
      });
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setNewEvent((prevEvent) => ({
      ...prevEvent,
      [name]: newValue,
    }));
  };

  const handleSelectEvent = (event) => {
    setEndTimeError(null);
    setStartTimeError(null);
    setShowAddEventPopup(false);
    setAddUpdateStatus("update");
    setNewEvent({});
    console.log(event);
    // const {start, end } = event;
    setCategory(event.event_type);
    setEndTime(event.to_time_id);
    setStartTime(event.from_time_id);
    setProgram(event.program_id);
    const formatDate = (date) => {
      return date
        .toLocaleDateString("en-GB", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
        .split("/")
        .reverse()
        .join("-");
    };
    const newEvent = {
      id: event.id,
      title: event.title,
      start: formatDate(event.start),
      end: formatDate(event.end),
      startTime: event.from_time_id,
      endTime: event.to_time_id,
      program: event.program_id,
      category: event.event_type,
      notify: true,
      description: event.description,
      title: event.title,
    };
    setNewEvent(newEvent);
    setShowEventPopup(true);
    // setShowAddEventPopup(true);
  };

  const [calendarData, setCalendarData] = useState([]);
  const [calendarLoading, setCalendarLoading] = useState(false);

  const fetchCalendarData = async () => {
    setCalendarLoading(false);
    try {
      const postData = {
        school_id: localStorage.getItem("schoolId"),
      };
      const { data } = await axios.post(
        baseUrl + "/api/get_timetable",
        postData,
        {
          headers: {
            Authorization: localStorage.getItem("access_token"),
          },
        }
      );
      setCalendarData(data.data);
      const fetchedEvents = data.data.map((event) => ({
        id: event.id,
        title: event.title,
        event_type: event.event_type,
        program_id: event.programs_id,
        from_time_id: event.from_time_id,
        to_time_id: event.to_time_id,
        description: event.description,
        title: event.title,
        start: new Date(...event.start_date),
        end: new Date(...event.end_date),
      }));
      setEvents(fetchedEvents);
    } catch (error) {
      console.log(error);
    } finally {
      setCalendarLoading(true);
    }
  };

  // ------11-06----------------
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const handleSnackbarClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };
  const handleSnackbarOpen = () => {
    setSnackbarState({ ...snackbarState, open: true });
  };
  useEffect(() => { }, [snackbarMessage, messageType]);

  // ----17-06--------------------
  // const [checkedState, setCheckedState] = useState(
  //     new Array(programsData.length).fill(false)
  // );
  // const [checkedIds, setCheckedIds] = useState([]);
  // const [dropdownOpen, setDropdownOpen] = useState(false);

  // const handleCheckboxChange = (index) => {
  //     const updatedCheckedState = [...checkedState];
  //     updatedCheckedState[index] = !updatedCheckedState[index];
  //     setCheckedState(updatedCheckedState);

  //     const id = programsData[index].id;
  //     if (updatedCheckedState[index]) {
  //         setCheckedIds((prevCheckedIds) => [...prevCheckedIds, id]);
  //     } else {
  //         setCheckedIds((prevCheckedIds) =>
  //             prevCheckedIds.filter((checkedId) => checkedId !== id)
  //         );
  //     }
  // };

  // const toggleDropdown = () => {
  //     setDropdownOpen(!dropdownOpen);
  // };

  const capitalize = (str) => {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  };

  // const Event = ({ event }) => {
  //   const startTime = moment(event.start).format("h:mm A");
  //   const endTime = moment(event.end).format("h:mm A");
  //   const formattedTitle = capitalize(event.title);

  //   return (
  //     <span>
  //       <strong>{formattedTitle}</strong>
  //       <br />
  //       <br />
  //       {currentView === "month" ? `${startTime} - ${endTime}` : null}
  //     </span>
  //   );
  // };

  const CustomEvent = ({ event }) => {
    const startTime = moment(event.start).format("h:mm A");
    const endTime = moment(event.end).format("h:mm A");
    const formattedTitle = event.title.charAt(0).toUpperCase() + event.title.slice(1);

    return (
      <div className="custom-event">
        <div className="event-title">{formattedTitle}</div>
        <br />
        <div className="event-dates">{`${startTime} to ${endTime}`}</div>
      </div>
    );
  };

  // -------------------------19-06-------------------------

  const handleOpenNewProgramPopupVisible = () => {
    setProgramNameError(null);
    setProgramTeacherError(null);
    setTimeError(null);
    setTeacher("");
    setProgramStartTime("");
    setProgramEndTime("");
    setNewProgramPopupVisible(true);
  };

  const [newProgramPopupVisible, setNewProgramPopupVisible] = useState(false);
  const [programStartTime, setProgramStartTime] = useState("");
  const [programEndTime, setProgramEndTime] = useState("");
  const [programTeacher, setTeacher] = useState("");
  const [timeData, setTimeData] = useState([]);
  const [teachersData, setTeachersData] = useState([]);
  const selectedPStartTime = timeData.find(
    (data) => data.id === programStartTime
  );
  const selectedPEndTime = timeData.find((data) => data.id === programEndTime);
  const selectedTeachers = teachersData.find(
    (data) => data.id === programTeacher
  );

  const fetchProgramTimeData = async () => {
    axios({
      method: "post",
      url: baseUrl + "/api/get_time",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("access_token"),
      },
    }).then((response) => {
      console.log(response);
      if (response.data.status === true) {
        setTimeData(response.data.data);
        console.log(response);
      }
    });
  };

  const fetchTeachersData = async () => {
    axios({
      method: "post",
      url: baseUrl + "/api/teacher_list",
      data: {
        school_id: localStorage.getItem("schoolId"),
      },
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("access_token"),
      },
    }).then((response) => {
      console.log(response);
      if (response.data.status === true) {
        setTeachersData(response.data.data);
        console.log(response);
      }
    });
  };

  const [newProgramName, setNewProgramName] = useState("");
  const [features, setFeatures] = useState({
    qrCode: { status: true, name: "QR Code", value: 2 },
    attendance: { status: true, name: "Attendance", value: 5 },
    busTracking: { status: false, name: "Bus Tracking", value: 7 },
    cctv: { status: false, name: "CCTV", value: 8 },
    food: { status: false, name: "Food", value: 3 },
    sleep: { status: false, name: "Sleep", value: 4 },
    fees: { status: false, name: "Fees", value: 9 },
    leaves: { status: false, name: "Leaves", value: 6 },
  });
  const [additionalInfo, setAdditionalInfo] = useState({
    inviteParents: {
      status: true,
      name: "Automatically Invite Parents as soon as student profile created",
      value: 1,
    },
    addParentChat: {
      status: true,
      name: "Add Parent in chat as soon as student profile created",
      value: 2,
    },
    createStudentID: {
      status: true,
      name: "Automatically create Student ID as soon as profile created",
      value: 3,
    },
  });

  const handleFeatureChange = (feature) => {
    setFeatures({
      ...features,
      [feature]: {
        ...features[feature],
        status: !features[feature].status,
      },
    });
  };
  const handleAdditionalInfoChange = (info) => {
    setAdditionalInfo({
      ...additionalInfo,
      [info]: {
        ...additionalInfo[info],
        status: !additionalInfo[info].status,
      },
    });
  };

  const [programeNameError, setProgramNameError] = useState(null);
  const [programeTeacherError, setProgramTeacherError] = useState(null);
  const [timeError, setTimeError] = useState(null);
  const validateProgramForm = () => {
    let valid = true;
    if (newProgramName === "") {
      setProgramNameError("Please enter program name");
      valid = false;
    } else {
      setProgramNameError(null);
    }
    if (programTeacher === "") {
      setProgramTeacherError("Please select program teacher");
      valid = false;
    } else {
      setProgramTeacherError(null);
    }
    // if (programStartTime === "" || programEndTime === "") {
    //   setTimeError("Please select start and end time");
    //   valid = false;
    // } else {
    //   setTimeError(null);
    // }
    return valid;
  };

  const handleCreateProgram = () => {
    const selectedFeatureValues = Object.keys(features)
      .filter((key) => features[key].status)
      .map((key) => features[key].value);
    const selectedAdditionalInfoValues = Object.keys(additionalInfo)
      .filter((key) => additionalInfo[key].status)
      .map((key) => additionalInfo[key].value);

    console.log({
      school_id: localStorage.getItem("schoolId"),
      program_name: newProgramName,
      teacher_id: programTeacher,
      from_time_id: programStartTime,
      to_time_id: programEndTime,
      features: selectedFeatureValues.join(","),
      additional_info: selectedAdditionalInfoValues.join(","),
      status: 1,
      created_by: localStorage.getItem("loginUserId"),
    });
    if (validateProgramForm()) {
      try {
        axios({
          method: "post",
          url: baseUrl + "/api/programs_add_edit",
          data: {
            school_id: localStorage.getItem("schoolId"),
            program_name: newProgramName,
            teacher_id: programTeacher,
            from_time_id: programStartTime,
            to_time_id: programEndTime,
            features: selectedFeatureValues.join(","),
            additional_info: selectedAdditionalInfoValues.join(","),
            status: 1,
            created_by: localStorage.getItem("loginUserId"),
          },
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("access_token"),
          },
        }).then((response) => {
          console.log(response);
          if (response.data.status === true) {
            setNewProgramPopupVisible(false);
            fetchProgramsData();
            setSnackbarMessage(response.data.message);
            setMessageType("success");
            handleSnackbarOpen();
          } else {
            setSnackbarMessage(response.data.message);
            setMessageType("error");
            handleSnackbarOpen();
          }
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchCategoryData();
    fetchFoodTimeData();
    fetchProgramsData();
    fetchProgramTimeData();
    fetchTeachersData();
    fetchCalendarData();
  }, []);

  const customDayPropGetter = (date) => {
    // Get today's date
    const today = new Date();

    // Compare if the current date being rendered is today's date
    if (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    ) {
      return {
        style: {
          backgroundColor: "white", // Set your desired background color
          color: "black", // Set text color if needed
          // Add any other styles as needed
        },
      };
    }

    // Default styling for other dates
    return {};
  };

  const CustomTimeGutterHeader = () => {
    return (
      <div className="custom-time-gutter-header">
        <i class="fa-regular fa-clock"></i>
      </div>
    );
  };

  return (
    <section className="event_calc_cus_st staff_atten_cus eventvalc_cus">
      <Snackbar
        anchorOrigin={{
          vertical: snackbarState.vertical,
          horizontal: snackbarState.horizontal,
        }}
        open={snackbarState.open}
        onClose={handleSnackbarClose}
        message={
          <div style={{ display: "flex", alignItems: "center" }}>
            <ListItemIcon>
              <img
                src={messageType === "success" ? SuccessIcon : ErrorIcon}
                alt="Success"
                style={{ width: "24px", height: "24px", marginRight: "8px" }}
              />
            </ListItemIcon>
            <div className="success_cuc">
              <p> {snackbarMessage} </p>
            </div>
          </div>
        }
        ContentProps={{
          sx: {
            border: "1px solid",
            opacity: "1",
            background: messageType === "success" ? "#40BE61" : "#FD4954",
            borderColor: messageType === "success" ? "#40BE61" : "#FD4954",
            width: "415px",
          },
        }}
        autoHideDuration={3000}
      />
      <div className="outer-container">
        <div className="overlay" />
        <div id="left-panel" className="sp-left-panel">
          <div className="sp-top-band">
            <a className="sp-logo">
              Scool Panda<sup>Beta</sup>
            </a>
            {/* <div className="sp-search-top flexc">
              <i className="icon-Search" />
              <input
                id="search"
                className="sp-search-btn"
                placeholder="Quick search..."
              />
            </div> */}
          </div>
          <div className="sp-middle-band">
            <div className="sp-nav y-auto">
              <div className="">
                <h4 className="sp-title">Quick Actions</h4>
                <ul>
                  <li
                    onClick={() => {
                      navigate("/dashboard");
                    }}
                  >
                    <span>
                      <i className="icon-Dashboard-Outline" />
                      Dashboard
                    </span>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/activity");
                    }}
                  >
                    <span>
                      <i className="icon-Activity-Outline" />
                      Activity
                    </span>
                  </li>
                  <li className="selected">
                    <span>
                      <i className="icon-Timetable-Outline" />
                      Calendar
                    </span>
                  </li>
                  <li
                    onClick={() => {
                      navigate("/staff_list");
                    }}
                  >
                    <span>
                      <i className="icon-Staff-Outline" />
                      Staff
                    </span>
                  </li>
                  {/* <li>
                    <span>
                      <i className="icon-Message-Outline" />
                      Messages
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Fees-Outline" />
                      Fees Management
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Enquiries-Outline" />
                      Enquiries
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="icon-Invites-Outline" />
                      Invites
                    </span>
                  </li> */}
                </ul>
                <h4
                  className="sp-title flexc justifySp"
                  onClick={() => handleOpenNewProgramPopupVisible()}
                >
                  Programs{" "}
                  <span id="add-programs" className="sp-plus">
                    +
                  </span>
                </h4>
                <ul>
                  {programsLoading &&
                    programsData &&
                    programsData.length > 0 ? (
                    programsData.map((data, index) => {
                      return (
                        <li
                          onClick={() => {
                            localStorage.setItem("programId", data.id);
                            localStorage.setItem(
                              "programName",
                              data.program_name
                            );
                            navigate("/student_list");
                          }}
                        >
                          <span>
                            <i className="icon-Program-Outline-1" />
                            {data.program_name}
                          </span>
                        </li>
                      );
                    })
                  ) : (
                    <div className="data-loader">
                      <Loader
                        type="spinner-cub"
                        bgColor="#4D6EE3"
                        color="#6B6F8C"
                        title="Loading..."
                        size={35}
                      />
                    </div>
                  )}
                </ul>
              </div>
            </div>
            <div className="sp-bottom sp-nav">
              <ul>
                <li>
                  <span>
                    {/* <i className="icon-Import-Outline" /> */}
                    <div className="noti_sdi">
                      <img src={gift} alt="" style={{ height: "16px" }} />
                      <p>Notifications</p>
                    </div>

                  </span>
                </li>
                <li>
                  <span style={{ display: "block" }}>
                    {/* <i className="icon-Invites-Outline" /> */}
                    <div className="noti_tot">
                      <div className="noti_sdi">
                        <img src={upar} alt="" style={{ height: "18px", width: "18px" }} />
                        <p>Upgrade Pro</p>
                      </div>
                      <img src={ques} alt="" style={{ height: "18px", width: "18px" }} />
                    </div>
                  </span>
                </li>
                <li onClick={() => navigate("/signout")}>
                  <span>
                    <i className="icon-Invites-Outline" />
                    Log Out
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* my code */}
        <div className="sp-right-panel">
          <div className="sp-top-bar flexc justifySp borderbt">
            <div className="ft-wt600 flexc">
              <span className="selected-icon">
                <i className="icon-Timetable-Outline" />
              </span>
              Calendar
              <i className="icon-More-Icon" />
            </div>
            {/* <div className="flexc">
                            <button className="sp-view-btn ft-wt500 flexc">
                                <i className="icon-Import-Outline" />
                                Import
                            </button>
                        </div> */}
          </div>
          {calendarLoading ? (
            <div className="sp-timetab-inr">
              <Calendar
                localizer={localizer}
                events={events}
                onView={handleViewChange}
                startAccessor="start"
                endAccessor="end"
                selectable
                views={["month", "week"]}
                components={{
                  month: {
                    event: (props) => <CustomEvent {...props} currentView={currentView} />,
                  },
                  week: {
                    event: (props) => <CustomEvent {...props} currentView={currentView} />,
                    timeGutterHeader: CustomTimeGutterHeader,
                  },
                  toolbar: CustomToolbar,
                }}
                defaultView="week"
                scrollToTime={new Date(1970, 1, 1, 8)} // Scroll to 8:00 AM
                min={new Date(1970, 1, 1, 8)} // Start from 8:00 AM
                max={new Date(1970, 1, 1, 20)} // End at 6:00 PM or any preferred end time
                dayPropGetter={customDayPropGetter}
                onSelectSlot={handleSelectSlot}
                onSelectEvent={handleSelectEvent}
              />
            </div>
          ) : (
            <div className="data-loader">
              <Loader
                type="spinner-cub"
                bgColor="#4D6EE3"
                color="#6B6F8C"
                title="Loading..."
                size={35}
              />
            </div>
          )}
        </div>
        {showAddEventPopup && (
          <div
            className="dialog-model absolute create_cus_data"
            style={newEvent.start && { display: "block" }}
          >
            <div
              className="sp-tt ft-14 flexc ft-wt700"
              style={{ fontSize: "20px" }}
            >
              {addUpdateStatus === "add" ? "Create New" : "Update"} Event
            </div>
            <div className="form-section-default flexc justifySp fwrap">
              <div className="field-label fullsz-field error_mes_cus">
                <label>Event</label>
                <div className="field-value-holder margin_top10p">
                  <input
                    className="sp-field-input"
                    placeholder="Type event.."
                    type="text"
                    name="title"
                    value={newEvent.title}
                    onChange={handleChange}
                    style={{ height: "40px" }}
                  />
                </div>
                {titleError && <p className="error">{titleError}</p>}
              </div>
              <div className="field-label half-field">
                <label>Category</label>
                <div
                  className="field-value-holder margin_top10p dropbutton"
                  style={{ width: "100%" }}
                >
                  <Dropdown
                    label={
                      <span
                        className={`${selectedCategory ? "selected-item" : ""}`}
                        style={{
                          fontSize: "13px",
                          fontWeight: "500",
                          whiteSpace: "nowrap",

                          color: selectedCategory ? "#1B252F" : "#1B252F",
                        }}
                      >
                        {selectedCategory
                          ? selectedCategory.event_category_name
                          : "Select Category"}
                      </span>
                    }

                  //     label={<span style={{
                  //         color: '#1B252F',
                  //         whiteSpace: 'nowrap',
                  //         fontWeight: "500",
                  //         fontSize: "13px",
                  //     }}>{selectedCategory ? selectedCategory.event_category_name : "Select Category"}</span>}
                  //     style={{ width: '100%' }}
                  >
                    {categoryData.slice(0, 2).map((item) => (
                      <Dropdown.Item
                        className={`${selectedCategory && selectedCategory.id === item.id
                          ? "selected-item dd_select"
                          : ""
                          }`}
                        // className={selectedCategory && selectedCategory.id === item.id ? "dd_select" : null}
                        icon={
                          selectedCategory && selectedCategory.id === item.id
                            ? HiCheck
                            : null
                        }
                        onClick={() => setCategory(item.id)}
                        key={item.id}
                      >
                        {item.event_category_name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown>
                </div>
              </div>

              <div className="field-label half-field">
                <label>Programs</label>

                <div
                  className="field-value-holder margin_top10p dropbutton"
                  style={{ width: "100%" }}
                >
                  {/* <Dropdown
                    label={
                      <span
                        className={`${selectedProgram ? "selected-item" : ""}`}
                        style={{
                          fontSize: "13px",
                          fontWeight: "500",
                          whiteSpace: "nowrap",

                          color: selectedProgram ? "#1B252F" : "#A9AFC3",
                        }}
                      >
                        {selectedProgram
                          ? selectedProgram.program_name
                          : "Select Category"}
                      </span>
                    }

                  //   label={
                  //   <span style={{ color: '#1B252F', whiteSpace: 'nowrap' }}>
                  //     {selectedProgram ? selectedProgram.program_name
                  //      : program === 0 ? "All Programs" : "Select Program"}</span>}
                  //     style={{ width: '100%' }}
                  >
                    <Dropdown.Item
                      className={`${program === 0 ? "selected-item dd_select" : ""
                        }`}
                      // className={program === 0 ? "dd_select" : null}
                      icon={program === 0 ? HiCheck : null}
                      onClick={() => setProgram(0)}
                      key={0}
                    >
                      All Programs
                    </Dropdown.Item>
                    {programsData.map((item) => (
                      <Dropdown.Item
                        className={`${selectedProgram && selectedProgram.id === item.id
                          ? "selected-item dd_select"
                          : ""
                          }`}
                        // className={selectedProgram && selectedProgram.id === item.id ? "dd_select" : null}
                        icon={
                          selectedProgram && selectedProgram.id === item.id
                            ? HiCheck
                            : null
                        }
                        onClick={() => setProgram(item.id)}
                        key={item.id}
                      >
                        {item.program_name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown> */}
                  <Dropdown
                    label={
                      <span
                        className={`${selectedProgram ? "selected-item" : ""}`}
                        style={{
                          fontSize: "13px",
                          fontWeight: "500",
                          whiteSpace: "nowrap",
                          color: selectedProgramId !== null ? "#1B252F" : "#1B252F",
                        }}
                      >
                        {selectedProgram
                          ? selectedProgram.program_name
                          : selectedProgramId === 0
                            ? "All Programs"
                            : "Select Program"}
                      </span>
                    }
                  >
                    <Dropdown.Item
                      className={`${selectedProgramId === 0 ? "selected-item dd_select" : ""}`}
                      icon={selectedProgramId === 0 ? HiCheck : null}
                      onClick={() => {
                        setSelectedProgramId(0);
                        setProgram(0);
                      }}
                      key={0}
                    >
                      All Programs
                    </Dropdown.Item>
                    {programsData.map((item) => (
                      <Dropdown.Item
                        className={`${selectedProgram && selectedProgram.id === item.id ? "selected-item dd_select" : ""}`}
                        icon={selectedProgram && selectedProgram.id === item.id ? HiCheck : null}
                        onClick={() => {
                          setSelectedProgramId(item.id);
                          setProgram(item.id);
                        }}
                        key={item.id}
                      >
                        {item.program_name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown>
                </div>
              </div>

              <div className="field-label half-field">
                <label>Start Date</label>
                <div className="field-value-holder margin_top10p flexc pos_icon_date_st">
                  <input
                    type="text"
                    className="sp-field-input date"
                    placeholder="Start Date"
                    name="start"
                    value={moment(newEvent.start).format("YYYY-MM-DD")}
                    onChange={handleChange}
                    // readOnly
                    disabled
                    style={{ color: "grey", height: "40px" }}
                  />
                  <i class="fa-regular fa-clock" style={{ color: "grey" }}></i>
                </div>
              </div>
              <div className="field-label half-field error_mes_cus">
                <label>Start Time</label>
                <div className="field-value-holder margin_top10p flexc">
                  <Dropdown
                    label={
                      <span
                        className={`${selectedStartTime ? "selected-item" : ""
                          }`}
                        // A9AFC3
                        style={{
                          fontSize: "13px",
                          color: selectedStartTime ? "#1B252F" : "#1B252F",
                        }}
                      >
                        {selectedStartTime
                          ? selectedStartTime.time_name
                          : "Select a Time"}
                      </span>
                    }
                    // label={<span style={{ color: 'black' }}>{selectedStartTime ? selectedStartTime.time_name : "Select Time"}</span>}
                    style={{ width: "100%" }}
                  >
                    {foodTimeData.map((item) => (
                      <Dropdown.Item
                        className={`${selectedStartTime && selectedStartTime.id === item.id
                          ? "selected-item dd_select"
                          : ""
                          }`}
                        // className={selectedStartTime && selectedStartTime.id === item.id ? "dd_select" : null}
                        icon={
                          selectedStartTime && selectedStartTime.id === item.id
                            ? HiCheck
                            : null
                        }
                        onClick={() => setStartTime(item.id)}
                        key={item.id}
                      >
                        {item.time_name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown>
                </div>
                {startTimeError && <p className="error">{startTimeError}</p>}
              </div>
              <div className="field-label half-field">
                <label>End Date</label>
                <div className="field-value-holder margin_top10p flexc pos_icon_date_st">
                  <input
                    type="text"
                    className="sp-field-input date"
                    placeholder="End Date"
                    name="end"
                    value={moment(newEvent.end).format("YYYY-MM-DD")}
                    onChange={handleChange}
                    // readOnly
                    disabled
                    style={{ color: "grey", height: "40px" }}
                  />
                  <i class="fa-regular fa-clock" style={{ color: "grey" }}></i>
                </div>
              </div>
              <div className="field-label half-field error_mes_cus">
                <label>End Time</label>
                <div className="field-value-holder margin_top10p flexc">
                  <Dropdown
                    label={
                      <span
                        className={`${selectedEndTime ? "selected-item" : ""}`}
                        style={{
                          fontSize: "13px",
                          color: selectedEndTime ? "#1B252F" : "#1B252F",
                        }}
                      >
                        {selectedEndTime ? selectedEndTime.time_name : "Select a Time"}
                      </span>
                    }
                    style={{ width: "100%", height: "40px" }}
                  >
                    {foodTimeData.map((item) => (
                      <Dropdown.Item
                        className={`${selectedEndTime && selectedEndTime.id === item.id
                          ? "selected-item dd_select"
                          : ""
                          }`}
                        icon={
                          selectedEndTime && selectedEndTime.id === item.id ? (
                            <i className="fa-regular fa-clock"></i>
                          ) : null
                        }
                        onClick={() => setEndTime(item.id)}
                        key={item.id}
                      >
                        {item.time_name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown>
                </div>
                {endTimeError && <p className="error">{endTimeError}</p>}
              </div>


              <div className="notify-cont flexc">
                <label className="label-container">
                  <input
                    type="checkbox"
                    defaultChecked={newEvent.notify}
                    name="notify"
                    onChange={handleChange}
                    style={{ height: "40px" }}
                  />
                  <span className="tick-ele" />
                </label>
                {/* <input className="label-container" type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                <label for="vehicle1"> Notify Staff and Parents in selected programs</label> */}
                <span className="ft-13">
                  Notify Staff and Parents in selected programs
                </span>
              </div>
              <div className="field-label full-field">
                <label>Description</label>
                <div className="field-value-holder margin_top10p">
                  <textarea
                    className="sp-field-input"
                    placeholder="Description"
                    name="description"
                    value={newEvent.description}
                    onChange={handleChange}
                    rows={1}
                  />
                </div>
              </div>
              <div className="flexc mt-4">
                <div className="flexc">
                  <button
                    className="createN ft-wt500 mr-2"
                    onClick={handleAddEvent}
                  >
                    {addUpdateStatus === "add" ? "Create" : "Update"}
                  </button>
                  <button
                    className="sp-view-btn ft-wt500 flexc cancel-btn"
                    onClick={() => {
                      setTitleError(null);
                      setShowAddEventPopup(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {showEventPopup && (
          <div
            className="dialog-model absolute edit_cus_style"
            style={{ display: "block" }}
          >
            <div className="flexc justifySp">
              <div className="sp-tt ft-14 ft-wt600 head_cus_cal">
                Event Information
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  float: "right",
                  right: "0",
                }}
              >
                <span
                  className="selected-icon"
                  style={{ border: "unset", cursor: "pointer" }}
                  onClick={() => {
                    setShowEventPopup(false);
                    setShowAddEventPopup(true);
                  }}
                >
                  <img src={imgEdit} alt="avatar" />
                  {/* <i className="icon-Timetable-Outline" /> */}
                </span>
                <span
                  className="selected-icon"
                  style={{ border: "unset", cursor: "pointer" }}
                  onClick={() => {
                    setShowEventPopup(false);
                    handleDeleteEvent(newEvent.id);
                  }}
                >
                  <img src={imgDelete} alt="avatar" />
                  {/* <i className="icon-Timetable-Outline" /> */}
                </span>
                <span
                  className="selected-icon"
                  style={{ border: "unset", cursor: "pointer" }}
                  onClick={() => {
                    setShowEventPopup(false);
                  }}
                >
                  <img
                    src={close}
                    style={{ height: "45px", width: "45px", paddingTop: "2px" }}
                    alt="avatar"
                  />
                  {/* <i className="icon-Timetable-Outline" /> */}
                </span>
              </div>
            </div>
            <div className="form-section-default flexc justifySp fwrap para_cus_sty ">
              <div className="field-label full-field">
                <label>Event</label>
                <div className="sp-tt ft-14 flexc ft-wt600 mt-10 field_cus_cal_sty">
                  {newEvent.title}
                </div>
              </div>
              <div className="field-label half-field">
                <label>Category</label>
                <div className="sp-tt ft-14 flexc ft-wt600 mt-10 field_cus_cal_sty">
                  {
                    categoryData.find((data) => data.id === category)
                      .event_category_name
                  }
                </div>
              </div>
              <div className="field-label half-field">
                <label>Programs</label>
                <div className="sp-tt ft-14 flexc ft-wt600 mt-10 field_cus_cal_sty">
                  {/* {" "}
                  <p>
                    {" "} */}
                  {newEvent.program === 0
                    ? "All Programs"
                    : programsData.find(
                      (data) => data.id === newEvent.program
                    ).program_name}
                  {/* </p>{" "} */}
                </div>
              </div>
              <div className="field-label half-field">
                <label>Start Date</label>
                <div className="sp-tt ft-14 flexc ft-wt600 mt-10 field_cus_cal_sty">
                  {newEvent.start}
                </div>
              </div>
              <div className="field-label half-field">
                <label>Start Time</label>
                <div className="sp-tt ft-14 flexc ft-wt600 mt-10 field_cus_cal_sty">
                  {
                    foodTimeData.find((data) => data.id === newEvent.startTime)
                      .time_name
                  }
                </div>
              </div>
              <div className="field-label half-field">
                <label>End Date</label>
                <div className="sp-tt ft-14 flexc ft-wt600 mt-10 field_cus_cal_sty">
                  {newEvent.end}
                </div>
              </div>
              <div className="field-label half-field">
                <label>End Time</label>
                <div className="sp-tt ft-14 flexc ft-wt600 mt-10 field_cus_cal_sty">
                  {
                    foodTimeData.find((data) => data.id === newEvent.endTime)
                      .time_name
                  }
                </div>
              </div>
              <div className="field-label full-field">
                <label>Description</label>
                <div className="sp-tt ft-14 flexc ft-wt600 mt-10 field_cus_cal_sty">
                  {newEvent.description}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Create New Program */}
        {newProgramPopupVisible && (
          <div className="popup-program-container">
            <div className="popup-wrap">
              <h2 className="ft-wt700">Create New Program</h2>
              <div className="sp-pop-header px-8">
                <div className="form-section-default flexc justifySp fwrap">
                  <div className="field-label full-field error_mes_cus">
                    <label>Program Name</label>
                    <div className="field-value-holder margin_top10p">
                      <input
                        className="sp-field-input"
                        placeholder="Enter Program name"
                        value={newProgramName}
                        onChange={(e) => setNewProgramName(e.target.value)}
                      />
                    </div>
                    {programeNameError && (
                      <p className="error">{programeNameError}</p>
                    )}
                  </div>
                  <div className="field-label half-field error_mes_cus">
                    <label>Program Teachers</label>
                    <div className="field-value-holder margin_top10p dropbutton">
                      <Dropdown
                        label={
                          <span style={{ color: "black" }}>
                            {selectedTeachers
                              ? selectedTeachers.first_name
                              : "Select Teacher"}
                          </span>
                        }
                        style={{ width: "100%" }}
                      >
                        {teachersData.map((item) => (
                          <Dropdown.Item
                            className={
                              selectedTeachers &&
                                selectedTeachers.id === item.id
                                ? "dd_select"
                                : null
                            }
                            icon={
                              selectedTeachers &&
                                selectedTeachers.id === item.id
                                ? HiCheck
                                : null
                            }
                            onClick={() => setTeacher(item.id)}
                            key={item.id}
                          >
                            {item.first_name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown>
                    </div>
                    {programeTeacherError && (
                      <p className="error">{programeTeacherError}</p>
                    )}
                  </div>

                  <div className="field-label half-field error_mes_cus">
                    <label>Class Timing</label>
                    <div className="flexc justifySp">
                      <div className="half-field">
                        <div className="field-value-holder margin_top10p dropbutton">
                          <Dropdown
                            label={
                              <span
                                style={{ color: "black", fontSize: "12px" }}
                              >
                                <i
                                  class="fa-regular fa-clock"
                                  style={{ marginRight: "6PX" }}
                                ></i>
                                {selectedPStartTime
                                  ? selectedPStartTime.time_name
                                  : "Start Time"}
                              </span>
                            }
                            style={{ width: "100%" }}
                          >
                            {timeData.map((item) => (
                              <Dropdown.Item
                                className={
                                  selectedPStartTime &&
                                    selectedPStartTime.id === item.id
                                    ? "dd_select"
                                    : null
                                }
                                icon={
                                  selectedPStartTime &&
                                    selectedPStartTime.id === item.id
                                    ? HiCheck
                                    : null
                                }
                                onClick={() => setProgramStartTime(item.id)}
                                key={item.id}
                              >
                                {item.time_name}
                              </Dropdown.Item>
                            ))}
                          </Dropdown>
                        </div>
                      </div>
                      <div className="half-field">
                        <div className="field-value-holder margin_top10p dropbutton">
                          <Dropdown
                            label={
                              <span
                                style={{ color: "black", fontSize: "12px" }}
                              >
                                <i
                                  class="fa-regular fa-clock"
                                  style={{ marginRight: "6PX" }}
                                ></i>
                                {selectedPEndTime
                                  ? selectedPEndTime.time_name
                                  : "End Time"}
                              </span>
                            }
                            style={{ width: "100%" }}
                          >
                            {timeData.map((item) => (
                              <Dropdown.Item
                                className={
                                  selectedPEndTime &&
                                    selectedPEndTime.id === item.id
                                    ? "dd_select"
                                    : null
                                }
                                icon={
                                  selectedPEndTime &&
                                    selectedPEndTime.id === item.id
                                    ? HiCheck
                                    : null
                                }
                                onClick={() => setProgramEndTime(item.id)}
                                key={item.id}
                              >
                                {item.time_name}
                              </Dropdown.Item>
                            ))}
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                    {timeError && <p className="error">{timeError}</p>}
                  </div>
                </div>

                <h3 className="ft-wt600 mt-30">Customise Features</h3>
                <div className="full-field mb-6">
                  {Object.keys(features).map((feature) => (
                    <div
                      className="flex items-center mb-4 custom-checkbox mt-5"
                      key={feature}
                    >
                      <input
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 rounded"
                        checked={features[feature].status}
                        onChange={() => handleFeatureChange(feature)}
                      />
                      <label className="ms-2 text-sm">
                        {features[feature].name}
                      </label>
                      {["busTracking", "cctv", "fees"].includes(feature) && (
                        <img
                          src={SplImg}
                          alt="spl"
                          style={{
                            width: "25px",
                            height: "15px",
                            marginLeft: "5px",
                          }}
                        />
                      )}
                    </div>
                  ))}
                </div>
                <h3 className="ft-wt600 mt-30">Additional Informations</h3>
                <div className="full-field">
                  {Object.keys(additionalInfo).map((info) => (
                    <div
                      className="flex items-center mb-4 custom-checkbox mt-5"
                      key={info}
                    >
                      <input
                        type="checkbox"
                        className="w-4 h-4 text-blue-600 rounded"
                        checked={additionalInfo[info].status}
                        onChange={() => handleAdditionalInfoChange(info)}
                      />
                      <label className="ms-2 text-sm">
                        {additionalInfo[info].name}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="sp-bottom-sticky">
                <button
                  className="sp-btn-create"
                  onClick={() => handleCreateProgram()}
                >
                  Create Program
                </button>
                <button className="sp-btn-cancel">Cancel</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </section >
  );
}



