import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../signin/css/style.css";
import panda from "./images/panda.png";
import axios from 'axios';
import { baseUrl } from "../../common/common";
import validator from "validator";

export default function Forgotpassword() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);

  const handleKeyDown = (e, nextInputId) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const nextInput = document.getElementById(nextInputId);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };


  const forgotLink = () => {
    if (email === "") {
      setEmailError("Email cannot be empty");
    } else {
      if (!validator.isEmail(email)) {
        setEmailError("Please Enter Valid Email");
      } else {
        setEmailError(null);
        axios({
          method: "post",
          url: baseUrl + "/api/schoolforgotpassword",
          data: { email },
          headers: { "Content-Type": "multipart/form-data" },
        }).then((response) => {
          console.log(response);
          if (response.data.status === true) {
            setEmailError(null);
            navigate('/recovery');
          } else {
            setEmailError(response.data.message);
          }
        });
      }
    }
  };


  return (
    <section>
      <div className="whole-container sign_page">
        <div className="flex-prop form-wrap">
          <div className="form-container">
            <div className="mrg-cen text-align-c main_panda">
              <img src={panda} style={{ width: "80px" }} width={80} height={100} alt="School Panda" />
              <h1>Forgot your password?</h1>
            </div>
            <div className="sign-form mt-40">
              <div className="forms-fields-text add_style_input error_mes_cus">
                <label htmlFor="email">Email Address</label>

                <div className="icon_input">
                  <input
                    type="email"
                    value={email}
                    onKeyDown={(e) => handleKeyDown(e, "forgotButton")}
                    onChange={(ev) => {
                      setEmail(ev.target.value);
                    }}
                    placeholder="johndoe@workemail.com"
                    style={{ borderColor: emailError ? 'red' : '' }}
                  />
                  <span className="sp-icon-mail" />

                </div>
                {emailError && <p className="error" style={{ color: 'red' }}>{emailError}</p>}
              </div>
              <button id="forgotButton" onClick={forgotLink} className="verify-btn">Send me the link</button>
              <div className="form-bottom">
                <span><Link to="/signin" className="ms-clr cp">or sign in</Link></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

